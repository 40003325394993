import { useEffect, useState, useContext } from "react";
import { Button } from "@mui/material";
import "../categoriasFinanceiro/CategoriasFinanceiro.scss";
import { AuthContext } from "../../../shared/context/auth-context";
import { Popconfirm } from "antd";
import ErrorIcon from "@mui/icons-material/Error";
import { useLoading } from "../../../shared/context/LoadingContext";
import toast from "react-hot-toast";
import ModalDespesaRecorrente from "./components/ModalDespesaRecorrente";
import ModalEditarDespesaRecorrente from "./components/ModalEditarDespesaRecorrente";
import "./DespesasRecorrentes.scss";
function DespesasRecorrentes({ sendRequest, clinicaId }) {
  const { startLoading, stopLoading } = useLoading();

  const [modalData, setModalData] = useState({});
  const [despesasRecorrentes, setDespesasRecorrentes] = useState([]);
  const auth = useContext(AuthContext);

  useEffect(() => {
    const fetchDespesasRecorrentes = async () => {
      startLoading();
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/despesasrecorrentes/clinica/${clinicaId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setDespesasRecorrentes(responseData.despesasRecorrentes);
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    };

    fetchDespesasRecorrentes();
  }, [auth.token, clinicaId, sendRequest]);

  const updateDespesasRecorrentes = (novaDespesaRecorrente, operacao) => {
    if (operacao === "novo") {
      setDespesasRecorrentes((current) => [...current, novaDespesaRecorrente]);
    } else {
      setDespesasRecorrentes((current) =>
        current.map((c) =>
          c._id === novaDespesaRecorrente._id ? novaDespesaRecorrente : c
        )
      );
    }
  };

  const handleModalClose = () => {
    setModalData({});
  };

  const eliminarDespesaRecorrente = async (id) => {
    startLoading();
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/despesasrecorrentes/${id}`,
        "DELETE",
        {},
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      setDespesasRecorrentes((current) =>
        current.filter((des) => des.id !== id)
      );

      toast.success("Despesa recorrente eliminada com sucesso");
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  const handleEditarDespesaRecorrente = (idDespesaRecorrente) => {
    const despesaRecorrenteFiltrada = despesasRecorrentes.find(
      (d) => d.id === idDespesaRecorrente
    );
    setModalData({
      open: "edit",
      despesaRecorrenteFiltrada: despesaRecorrenteFiltrada,
    });
  };

  const handleDesativarAtivarDespesaRecorrente = async (id) => {
    startLoading();
    try {
      const formDataDesativar = new FormData();
      formDataDesativar.append("utilizador", auth.userId);
      const newState = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/despesasrecorrentes/toggleativo/${id}`,
        "PATCH",
        formDataDesativar,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      setDespesasRecorrentes((current) =>
        current.map((d) =>
          d._id === id
            ? { ...d, ativo: newState.newState === "ativada" ? true : false }
            : d
        )
      );

      toast.success(`Despesa recorrente ${newState.newState} com sucesso`);
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  return (
    <>
      <div className="categoriasGeral__container">
        {(auth.role === "Médico/Administrador" ||
          auth.role === "Administrador Não Médico") && (
          <div className="categoriasGeral__container__btn-adicionar">
            <Button
              variant="contained"
              color="success"
              onClick={(e) => setModalData({ open: "novo" })}
            >
              Nova Despesa Recorrente
            </Button>
          </div>
        )}

        {despesasRecorrentes &&
          despesasRecorrentes.map((despesaRecorrente, index) => (
            <div
              className={`categoriasFinanceiro__container__categoria ${
                !despesaRecorrente.ativo
                  ? "despesasRecorrentes__desativado"
                  : ""
              }`}
              key={index}
            >
              <div className="categoriasFinanceiro__container__categoria--1">
                {despesaRecorrente.descricao}
              </div>

              <div className="despesasRecorrentes__botoes">
                {(auth.role === "Médico/Administrador" ||
                  auth.role === "Administrador Não Médico") && (
                  <Popconfirm
                    title={`Eliminar despesa recorrente`}
                    description={`Pretende eliminar essa despesa recorrente?`}
                    icon={<ErrorIcon style={{ color: "red" }} />}
                    okText="Sim"
                    cancelText="Não"
                    onConfirm={eliminarDespesaRecorrente.bind(
                      null,
                      despesaRecorrente.id
                    )}
                    className="popConfirm--biggerPadding"
                  >
                    <span>Eliminar</span>
                  </Popconfirm>
                )}

                {(auth.role === "Médico/Administrador" ||
                  auth.role === "Administrador Não Médico") && (
                  <span
                    onClick={handleEditarDespesaRecorrente.bind(
                      null,
                      despesaRecorrente.id
                    )}
                  >
                    Editar
                  </span>
                )}

                {(auth.role === "Médico/Administrador" ||
                  auth.role === "Administrador Não Médico") && (
                  <span
                    onClick={handleDesativarAtivarDespesaRecorrente.bind(
                      null,
                      despesaRecorrente.id
                    )}
                  >
                    {despesaRecorrente.ativo ? "Desativar" : "Ativar"}
                  </span>
                )}
              </div>
            </div>
          ))}

        {modalData.open === "novo" && (
          <ModalDespesaRecorrente
            auth={auth}
            clinicaId={clinicaId}
            sendRequest={sendRequest}
            openModalDespesaRecorrente={modalData.open === "novo"}
            handleCloseModalDespesaRecorrente={handleModalClose}
            updateDespesasRecorrentes={updateDespesasRecorrentes}
          />
        )}
        {modalData.open === "edit" && (
          <ModalEditarDespesaRecorrente
            auth={auth}
            clinicaId={clinicaId}
            sendRequest={sendRequest}
            openModalDespesaRecorrente={modalData.open === "edit"}
            handleCloseModalDespesaRecorrente={handleModalClose}
            updateDespesasRecorrentes={updateDespesasRecorrentes}
            despesaRecorrenteFiltrada={modalData.despesaRecorrenteFiltrada}
          />
        )}
      </div>
    </>
  );
}

export default DespesasRecorrentes;
