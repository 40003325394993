import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import dataGridData from "./components/datagridData";
import moment from "moment";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import toast from "react-hot-toast";
import { generateExcelFile } from "../../../shared/components/excelUtils/exportExcel";
//Modais
import ModalDespesa from "./components/modalDespesa/ModalDespesa";
import ModalVerDespesa from "./components/modalDespesa/ModalVerDespesa";
import ModalPagarDespesa from "./components/modalDespesa/ModalPagarDespesa";
import ModalEditarDespesa from "./components/modalDespesa/ModalEditarDespesa";
//ICONS
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ErrorIcon from "@mui/icons-material/Error";
import CallMadeIcon from "@mui/icons-material/CallMade";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import EditIcon from "@mui/icons-material/Edit";
import { useLoading } from "../../../shared/context/LoadingContext";
//MUI
import Button from "@mui/material/Button";
//Antd
import { Popover } from "antd";
import { Popconfirm } from "antd";
import FiltrosDespesa from "./components/filtrosDespesa/FiltrosDespesa";

function Despesas({ clinicaId, auth }) {
  const { startLoading, stopLoading } = useLoading();

  const { sendRequest } = useHttpClient();
  const [rows, setRows] = useState([]);
  const [despesas, setDespesas] = useState();
  const [despesasParaTextSearch, setDespesasParaTextSearch] = useState();
  const [despesasAbsolut, setDespesasAbsolut] = useState();
  const [financialData, setFinancialData] = useState({});
  const [openModal, setOpenModal] = useState("");
  const [despesaId, setDespesaId] = useState();
  const [modalData, setModalData] = useState({});
  const [filtrosAplicados, setFiltrosAplicados] = useState([]);
  const [filtroCaixa, setFiltroCaixa] = useState();
  const [filtroCategoria, setFiltroCategoria] = useState();
  const [filtroMetodoPagamento, setFiltroMetodoPagamento] = useState();
  const [filtroTextoSearch, setFiltroTextoSearch] = useState("");

  const fetchDespesa = async (startDate, endDate) => {
    let responseDespesas = { despesas: [] };
    startLoading();
    try {
      const formData = new FormData();
      formData.append("startDate", startDate);
      formData.append("endDate", endDate);

      if (auth.perm.includes("r-des")) {
        responseDespesas = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/despesas/clinica/daterange/${clinicaId}`,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
      }
      setDespesas(responseDespesas.despesas);
      setDespesasAbsolut(responseDespesas.despesas);
      setDespesasParaTextSearch(responseDespesas.despesas);

      handleFiltrar(responseDespesas.despesas, [], null, null, null, "", {
        dataInicial: moment(startDate).format("DD/MM/YYYY"),
        dataFinal: moment(endDate).format("DD/MM/YYYY"),
      });
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  useEffect(() => {
    fetchDespesa(new Date().toISOString(), new Date().toISOString());
  }, []);

  useEffect(() => {
    let dataParaDataGrid = [];
    let aPagar = 0;
    let totalDespesas = 0;

    if (despesas && despesas.length > 0) {
      despesas.forEach((despesa) => {
        if (despesa.despesaPaga) totalDespesas += despesa.valor;
        else aPagar += despesa.valor;
        dataParaDataGrid.push({
          tipo: "despesa",
          id: despesa.id,
          dataLimitePagamento: despesa.dataLimitePagamento,
          dataPagamento: despesa.dataPagamento,
          valor: despesa.valor,
          metodoPagamento: despesa.metodoPagamento,
          despesaPaga: despesa.despesaPaga,
          descricao: despesa.descricao,
          comprovativo: despesa.comprovativo,
          estado: despesa.despesaPaga
            ? "Pago"
            : moment()
                .startOf("day")
                .isAfter(moment(despesa.dataLimitePagamento).startOf("day"))
            ? "Em atraso"
            : "",
          categoria: despesa.categoria?.categoria,
          caixa: despesa.caixa?.caixa,
        });
      });
    }

    dataParaDataGrid = dataParaDataGrid.sort(
      (a, b) =>
        new Date(a.dataPagamento ? a.dataPagamento : a.dataLimitePagamento) -
        new Date(b.dataPagamento ? b.dataPagamento : b.dataLimitePagamento)
    );

    setFinancialData({
      totalPago: totalDespesas.toLocaleString("pt-BR"),
      totalPorPagar: aPagar.toLocaleString("pt-BR"),
    });

    setRows(dataGridData(dataParaDataGrid));
  }, [despesas]);

  const updateDespesas = (despesaAdicionada, action) => {
    if (!action) {
      //Inserir
      const tempDespesas = [...despesas];
      const tempDespesasAbsolut = [...despesasAbsolut];
      const tempDespesasParaTextSearch = [...despesasParaTextSearch];

      tempDespesas.push(despesaAdicionada);
      tempDespesasAbsolut.push(despesaAdicionada);
      tempDespesasParaTextSearch.push(despesaAdicionada);

      setDespesasAbsolut([...tempDespesasAbsolut]);
      setDespesasParaTextSearch([...tempDespesasParaTextSearch]);
      setDespesas([...tempDespesas]);
    } else {
      //Atualizar
      //Se for a atualizacao de uma despesa, verificamos se ela pertence ao alcance de datas da mesma forma.
      //Se sim mantemo-la, se não, removemo-la
      let tempDespesasAbsolut;
      let tempDespesas;
      let tempDespesasParaTextSearch;

      tempDespesasAbsolut = despesasAbsolut.map((e) => {
        if (e._id === despesaAdicionada._id) {
          return despesaAdicionada;
        } else {
          return e;
        }
      });
      tempDespesasParaTextSearch = despesasParaTextSearch.map((e) => {
        if (e._id === despesaAdicionada._id) {
          return despesaAdicionada;
        } else {
          return e;
        }
      });
      tempDespesas = despesas.map((e) => {
        if (e._id === despesaAdicionada._id) {
          return despesaAdicionada;
        } else {
          return e;
        }
      });

      setDespesas([...tempDespesas]);
      setDespesasAbsolut([...tempDespesasAbsolut]);
      setDespesasParaTextSearch([...tempDespesasParaTextSearch]);
    }
  };

  const handleCloseModal = () => {
    setOpenModal("");
  };

  const handleOpenModalPagarDespesa = (id) => {
    setOpenModal("pagarDespesa");
    setModalData(despesas.filter((despesa) => despesa.id === id)[0]);
  };

  const handleVerDespesa = (id) => {
    const despesaFiltrada = despesas.filter((desp) => desp.id === id)[0];
    setModalData(despesaFiltrada);
    setOpenModal("verDespesa");
  };

  const handleCancelarPagamentoDespesa = async (id) => {
    startLoading();

    try {
      //SERA QUE NO TEM QUE REMOVE COMPROVATIVO????
      const formData = new FormData();
      formData.append("utilizador", auth.userId);
      const result = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/despesas/anularPagamento/${id}`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );
      if (result.success) {
        const tempDespesas = [...despesas];
        const despesaFiltrada = tempDespesas.find(
          (despesa) => despesa.id === id
        );
        delete despesaFiltrada.metodoPagamento;
        delete despesaFiltrada.pagaPor;
        delete despesaFiltrada.dataPagamento;
        despesaFiltrada.despesaPaga = false;
        despesaFiltrada.pagamentoCanceladoPor = {
          _id: auth.userId,
          name: auth.nome,
        };

        updateDespesas(despesaFiltrada, "atualizar");

        toast.success("Pagamento revertido com sucesso");
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  const editarDespesaHandler = (id) => {
    setDespesaId(id);
    setOpenModal("editarDespesa");
  };

  const apagarDespesaHandler = async (id) => {
    startLoading();
    try {
      const formData = new FormData();
      formData.append("utilizador", auth.userId);
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/despesas/eliminar/${id}`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      const tempDespesas = [...despesas];
      const tempDespesasAbsolut = [...despesasAbsolut];
      const tempDespesasParaTextSearch = [...despesasParaTextSearch];

      const indexDespesa = tempDespesas.findIndex(
        (despesa) => despesa.id === id
      );
      const indexDespesaAbsolut = tempDespesas.findIndex(
        (despesa) => despesa.id === id
      );
      const indexDespesaParaTextSearch = tempDespesasParaTextSearch.findIndex(
        (despesa) => despesa.id === id
      );

      tempDespesas.splice(indexDespesa, 1);
      tempDespesasAbsolut.splice(indexDespesaAbsolut, 1);
      tempDespesasParaTextSearch.splice(indexDespesaParaTextSearch, 1);

      setDespesas([...tempDespesas]);
      setDespesasAbsolut([...tempDespesasAbsolut]);
      setDespesasParaTextSearch([...tempDespesasParaTextSearch]);

      toast.success("Despesa eliminada com sucesso!");
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  const handleFiltrar = (
    newDespesas,
    filtros,
    filtroCai,
    filtroCat,
    filtroMet,
    textoSearch,
    data
  ) => {
    if (newDespesas) {
      setDespesas([...newDespesas]);
    }

    if (data === "modalFiltros") {
      setDespesasParaTextSearch([...newDespesas]);
    }

    if (data !== "textSearch") {
      setFiltrosAplicados(filtros);
      setFiltroCaixa(filtroCai);
      setFiltroCategoria(filtroCat);
      setFiltroMetodoPagamento(filtroMet);
    }
    setFiltroTextoSearch(textoSearch);
  };

  const handleExportar = () => {
    const sheetData = rows.map((r) => {
      return {
        descricao: r.descricao
          ? r.descricao
          : `${r.nome}${r.nrParcela ? " - Parcela " + r.nrParcela : ""}`,
        cliente: r.cliente,
        valor: r.valor,
        tipo: r.tipo,
        estado: r.estado,
        data_Pagamento: r.dataPagamento
          ? moment(r.dataPagamento).format("DD/MM/YYYY")
          : "-",
        dataLimitePagamento: r.dataLimitePagamento
          ? moment(r.dataLimitePagamento).format("DD/MM/YYYY")
          : "-",
        metodoPagamento: r.metodoPagamento ? r.metodoPagamento : "-",
        categoria: r.categoria ? r.categoria : "-",
        caixa: r.caixa ? r.caixa : "-",
      };
    });

    generateExcelFile(
      `HistoricoDespesas ${moment().format("DD/MM/YYYY")}.xlsx`,
      "Despesas",
      sheetData
    );
  };

  const actionColumn = [
    {
      field: "inOrOut",
      headerName: "",
      width: 30,
      renderCell: (params) => {
        return (
          <CallMadeIcon className="financeiro__container__cabecalho__totais--icon_div--despesa" />
        );
      },
    },
    {
      field: "dataLimitePagamento",
      headerName: "Dt Vencimento",
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            <div
              className={
                params.row.despesaPaga && params.row.dataLimitePagamento
                  ? "spanCellPago"
                  : !params.row.despesaPaga &&
                    moment().isAfter(
                      moment(params.row.dataLimitePagamento),
                      "day"
                    )
                  ? "spanCellAtraso"
                  : ""
              }
            >
              {params.row.despesaPaga && params.row.dataLimitePagamento
                ? moment(params.row.dataLimitePagamento).format("DD/MM/YYYY")
                : moment(params.row.dataLimitePagamento).format("DD/MM/YYYY")}
            </div>
          </div>
        );
      },
    },
    {
      field: "data",
      headerName: "Dt Pagamento",
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            <span
              className={
                params.row.despesaPaga && params.row.dataPagamento
                  ? "spanCellPago"
                  : !params.row.despesaPaga &&
                    moment(params.row.dataLimitePagamento) < moment()
                  ? "spanCellAtraso"
                  : ""
              }
            >
              {params.row.despesaPaga
                ? moment(params.row.dataPagamento).format("DD/MM/YYYY")
                : moment(params.row.dataLimitePagamento).format("DD/MM/YYYY")}
            </span>
          </div>
        );
      },
    },
    {
      field: "nome",
      headerName: "Nome",
      flex: 6,
      renderCell: (params) => {
        return (
          <>
            <div
              className={
                params.row.despesaPaga && params.row.dataPagamento
                  ? "spanCellPago"
                  : !params.row.despesaPaga &&
                    moment(params.row.dataLimitePagamento) < moment()
                  ? "spanCellAtraso"
                  : ""
              }
            >
              {params.row.descricao}
              <Tooltip title="Ver Despesa">
                <IconButton
                  onClick={handleVerDespesa.bind(null, params.row.id)}
                >
                  <ErrorIcon className="muiIconVerOrcamento" />
                </IconButton>
              </Tooltip>
            </div>
          </>
        );
      },
    },
    {
      field: "estado",
      headerName: "Pagamento",
      flex: 2,
      renderCell: (params) => {
        return (
          <div className="cellDebitoEstado">
            <span
              className={`debitos__container__estado ${
                params.row.estado === "Em atraso"
                  ? "debitos__container__estado--atraso"
                  : params.row.estado === "Pago"
                  ? "debitos__container__estado--pago"
                  : ""
              }`}
            >
              {params.row.metodoPagamento
                ? params.row.metodoPagamento
                : params.row.estado}
            </span>
          </div>
        );
      },
    },
    {
      field: "valor",
      headerName: "Valor",
      flex: 1,
      renderCell: (params) => {
        return (
          <span
            className={
              params.row.despesaPaga && params.row.dataPagamento
                ? "spanCellPago"
                : !params.row.despesaPaga &&
                  moment(params.row.dataLimitePagamento) < moment()
                ? "spanCellAtraso"
                : ""
            }
            style={{ marginLeft: "auto" }}
          >
            {params.row.valor}
          </span>
        );
      },
    },
    {
      field: "action",
      headerName: "Ações",
      flex: 1,
      renderCell: (params) => {
        let content;
        if (params.row.despesaPaga) {
          content = (
            <div className="dots__menu__popup">
              {/* Despesas Pagas */}
              {auth.perm.includes("u-des") && (
                <Popconfirm
                  title="Cancelar Pagamento"
                  description={`Pretende cancelar o pagamento da despesa ${params.row.descricao}?`}
                  icon={<ErrorIcon style={{ color: "red" }} />}
                  okText="Sim"
                  cancelText="Não"
                  onConfirm={handleCancelarPagamentoDespesa.bind(
                    null,
                    params.row.id
                  )}
                >
                  <div className="popOverMenu--option">
                    <span>Cancelar pagamento</span>
                    <HighlightOffIcon className="popOverMenu--option__icon" />
                  </div>
                </Popconfirm>
              )}

              {params.row.comprovativo &&
                params.row.comprovativo.ficheiro &&
                auth.perm.includes("r-des") && (
                  <a
                    href={`${process.env.REACT_APP_BACKEND_LINK}/${params.row.comprovativo.ficheiro}`}
                    download={
                      params.row.comprovativo.descricao || "comprovativo"
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="popOverMenu--option">
                      <span>Baixar Comprovativo</span>
                      <DownloadIcon className="popOverMenu--option__icon" />
                    </div>
                  </a>
                )}
            </div>
          );
        } else if (!params.row.despesaPaga) {
          content = (
            <div className="dots__menu__popup">
              {/* Despesas nao pagas */}
              {auth.perm.includes("u-des") && (
                <div
                  className="popOverMenu--option"
                  onClick={editarDespesaHandler.bind(null, params.row.id)}
                >
                  <span>Editar</span>
                  <EditIcon className="popOverMenu--option__icon" />
                </div>
              )}

              {params.row.comprovativo &&
                params.row.comprovativo.ficheiro &&
                auth.perm.includes("r-des") && (
                  <a
                    href={`${process.env.REACT_APP_BACKEND_LINK}/${params.row.comprovativo.ficheiro}`}
                    download
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="popOverMenu--option">
                      <span>Baixar Comprovativo</span>
                      <DownloadIcon className="popOverMenu--option__icon" />
                    </div>
                  </a>
                )}
              {auth.perm.includes("d-des") && (
                <Popconfirm
                  title="Eliminar Despesa"
                  description={`Pretende eliminar a despesa de ${params.row.descricao}?`}
                  icon={<ErrorIcon style={{ color: "red" }} />}
                  okText="Sim"
                  cancelText="Não"
                  onConfirm={apagarDespesaHandler.bind(null, params.row.id)}
                >
                  <div className="popOverMenu--option">
                    <span>Eliminar</span>
                    <DeleteIcon className="popOverMenu--option__icon" />
                  </div>
                </Popconfirm>
              )}
            </div>
          );
        }

        return (
          <>
            {/* Despesas nao pagas */}
            {params.row.estado !== "Pago" && auth.perm.includes("u-des") && (
              <span
                className={`debitos__container__receber ${
                  params.row.estado === "Em atraso" &&
                  "debitos__container__receber--atraso"
                }`}
                onClick={handleOpenModalPagarDespesa.bind(null, params.row.id)}
              >
                Pagar
              </span>
            )}

            {content && (
              <Popover content={content} trigger="click" placement="bottom">
                <div className="dots__menu">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              </Popover>
            )}
          </>
        );
      },
    },
  ];

  return (
    <div className="financeiro__container caixa">
      <div className="financeiro__adicionarEFiltros">
        <FiltrosDespesa
          despesasAbsolut={despesasAbsolut}
          despesasParaTextSearch={despesasParaTextSearch}
          handleFiltrar={handleFiltrar}
          handleExportar={handleExportar}
          filtrosAplicados={filtrosAplicados}
          filtroCaixa={filtroCaixa}
          filtroCategoria={filtroCategoria}
          filtroMetodoPagamento={filtroMetodoPagamento}
          filtroTextoSearch={filtroTextoSearch}
          clinicaId={clinicaId}
          auth={auth}
          fetchDespesa={fetchDespesa}
        />
        <div>
          {auth.perm.includes("c-des") && (
            <Button
              variant="contained"
              color="info"
              id="basic-button"
              onClick={(e) => setOpenModal("despesa")}
            >
              + Adicionar Despesa
            </Button>
          )}
        </div>
      </div>

      <div className="financeiro__container__cabecalho">
        <div className="financeiro__container__cabecalho__totais">
          <div className="financeiro__container__cabecalho__totais--por-receber">
            <div className="financeiro__container__cabecalho__totais__firstContainer financeiro__container__cabecalho__totais__firstContainer--por-receber">
              <div className="financeiro__container__cabecalho__totais--icon_div">
                <span className="financeiro__container__cabecalho__totais--titulos">
                  Total Pago
                </span>
              </div>
              <div className="financeiro__container__cabecalho__totais--texto">
                <span className="financeiro__container__cabecalho__totais--por-receber--valor_principal">
                  {financialData.totalPago}$00
                </span>
              </div>
            </div>
          </div>

          <div className="financeiro__container__cabecalho__totais--saldo">
            <div className="financeiro__container__cabecalho__totais__firstContainer financeiro__container__cabecalho__totais__firstContainer--saldo">
              <span className="financeiro__container__cabecalho__totais--titulos">
                Total a Pagar
              </span>
              <div className="financeiro__container__cabecalho__totais--texto">
                <span className="financeiro__container__cabecalho__totais--saldo--valor_principal">
                  {financialData.totalPorPagar}$00
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {rows && (
        <div style={{ height: 600, width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={actionColumn}
            pageSize={10}
            rowsPerPageOptions={[10]}
          />
        </div>
      )}

      {openModal === "verDespesa" && (
        <ModalVerDespesa
          openModalVerDespesa={openModal === "verDespesa"}
          handleCloseModalVerDespesa={handleCloseModal}
          dadosModalVerDespesa={modalData}
        />
      )}

      {openModal === "pagarDespesa" && (
        <ModalPagarDespesa
          OpenModalPagarDespesa={openModal === "pagarDespesa"}
          handleCloseModalPagarDespesa={handleCloseModal}
          dadosModalPagarDespesa={modalData}
          sendRequest={sendRequest}
          updateDespesas={updateDespesas}
          auth={auth}
          clinicaId={clinicaId}
        />
      )}

      {openModal === "editarDespesa" && (
        <ModalEditarDespesa
          openModalEditarDespesa={openModal === "editarDespesa"}
          handleCloseModalEditarDespesa={handleCloseModal}
          sendRequest={sendRequest}
          clinicaId={clinicaId}
          auth={auth}
          despesaId={despesaId}
          updateDespesas={updateDespesas}
        />
      )}

      {openModal === "despesa" && (
        <ModalDespesa
          openModalDespesa={openModal === "despesa"}
          sendRequest={sendRequest}
          handleCloseModalDespesa={handleCloseModal}
          clinicaId={clinicaId}
          auth={auth}
          updateDespesas={updateDespesas}
        />
      )}
    </div>
  );
}

export default Despesas;
