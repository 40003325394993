import React from "react";
import { useState, useEffect } from "react";
import moment from "moment/moment";
import { useContext } from "react";
import { AuthContext } from "../../../../../shared/context/auth-context";
import toast from "react-hot-toast";
// import ModalHistorico from "./components/ModalHistorico";
import { useLoading } from "../../../../../shared/context/LoadingContext";
import ListConsultas from "../Lists/ListConsultas/ListConsultas";
import ModalVerEditarConsulta from "./components/ModalEditarConsulta/ModalVerEditarConsulta";

function ConsultasComponent({ clienteId, sendRequest, token }) {
  const { startLoading, stopLoading } = useLoading();
  const [consultas, setConsultas] = useState([]);

  const auth = useContext(AuthContext);
  //Modal Code
  const [modalData, setModalData] = useState();

  const handleCloseModal = () => {
    setModalData();
  };

  const updateConsultas = (consultaModificada) => {
    setConsultas((current) =>
      current.map((c) =>
        c._id === consultaModificada._id ? consultaModificada : c
      )
    );
  };
  ///////////////////////////////////////

  useEffect(() => {
    const fetchConsultasByCliente = async () => {
      startLoading();
      try {
        let responseData;
        try {
          responseData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/consultas/cliente/${clienteId}`,
            "GET",
            null,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
        }

        const temp = responseData.consultas.sort((a, b) => {
          return new Date(b.data_inicio) - new Date(a.data_inicio);
        });

        temp.forEach((consulta) => {
          if (consulta.data_inicio) {
            consulta.data_inicio = moment(consulta.data_inicio).format(
              "DD-MM-YYYY HH:mm"
            );
          }
          if (consulta.data_fim) {
            consulta.data_fim = moment(consulta.data_fim).format(
              "DD-MM-YYYY HH:mm"
            );
          }
        });

        //Filtrando por medico conforme o usuario
        //DEPOIS VERIFICAR OUTROS USUARIOS
        let tempConsultas = [];

        // if (auth.role === "Médico/Administrador" || auth.role === "Dentista") {
        //   tempConsultas = temp.filter(
        //     (pl) => pl.medico_responsavel._id === auth.userId
        //   );
        // } else
        if (auth.role === "Secretária(o)" || auth.role === "Assistente") {
          temp.forEach((pl) => {
            auth.usuariosAssociados.forEach((medico) => {
              if (medico.id === pl.medico_responsavel._id)
                tempConsultas.push(pl);
            });
          });
        } else {
          tempConsultas = [...temp];
        }

        setConsultas(tempConsultas);
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    };
    fetchConsultasByCliente();
  }, [sendRequest, clienteId, auth]);

  const handleEliminar = async (id) => {
    startLoading();
    try {
      //Encontrar a contaCorrente associada ao orcamento que gerou esse plano
      const consultaFiltrada = consultas.find(
        (consulta) => consulta._id === id
      );

      if (!consultaFiltrada) {
        toast.error("Dados da consulta não encontrados!");
        return;
      }

      if (consultaFiltrada.entrada.entradaRecebida) {
        toast.error("A consulta não pode ser eliminada pois já foi recebida!");
        return;
      }

      const formData = new FormData();
      formData.append("utilizador", auth.userId);

      try {
        await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/consultas/eliminar/${id}`,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
      } catch (err) {
        console.error("err", err);
      }

      setConsultas((current) => current.filter((c) => c._id !== id));
      toast.success(
        "Eliminação da consulta e da entrada efetuados com sucesso"
      );
    } catch (err) {
    } finally {
      stopLoading();
    }
  };

  const handleOpenModal = (tipo, id) => {
    const consultaFiltrada = consultas.find((consulta) => consulta._id === id);
    if (!consultaFiltrada) {
      toast.error("Dados da consulta não encontrados!");
      return;
    }

    setModalData({ open: true, data: consultaFiltrada, tipo: tipo });
  };

  return (
    <>
      {consultas.length !== 0 && (
        <ListConsultas
          data={consultas}
          handleOpenModal={handleOpenModal}
          handleEliminar={handleEliminar}
          auth={auth}
        />
      )}

      {modalData?.open && (
        <ModalVerEditarConsulta
          sendRequest={sendRequest}
          auth={auth}
          dados={modalData.data}
          open={modalData.open}
          tipoModal={modalData.tipo}
          handleCloseModal={handleCloseModal}
          handleModalSubmitSuccess={updateConsultas}
        />
      )}

      {consultas.length === 0 && (
        <div className="no_data_div">Nenhuma consulta encontrada.</div>
      )}
    </>
  );
}

export default ConsultasComponent;
