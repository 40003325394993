import { useState, useEffect } from "react";

//MUI
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import Modal from "@mui/material/Modal";
import { useLoading } from "../../../../../shared/context/LoadingContext";
import "./ModalAtribuirPerfis.scss";

import toast from "react-hot-toast";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight: personName.includes(name)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}

function ModalAtribuirPerfis({
  modalData,
  handleCloseModal,
  sendRequest,
  auth,
  handleUpdatePerfis,
  allPerfis,
}) {
  const { startLoading, stopLoading } = useLoading();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedPerfis, setSelectedPerfis] = useState([]);

  useEffect(() => {
    if (modalData.user && modalData.user.perfis) {
      setSelectedPerfis(modalData.user.perfis.map((perfil) => perfil._id));
    }
  }, [modalData.user]);

  const submit = async () => {
    startLoading();
    setIsSubmitting(true);
    let userRetornado;
    try {
      if (!selectedPerfis || selectedPerfis.length === 0) {
        toast.error("Por favor introduza pelo menos 1 perfil.");
      } else {
        const formData = new FormData();
        formData.append("perfis", JSON.stringify(selectedPerfis));
        formData.append("utilizador", auth.userId);

        userRetornado = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/users/atribuirperfis/${modalData.user._id}`,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
      }
      toast.success("Perfis associados ao utilizador com sucesso!");
      handleUpdatePerfis(userRetornado.user);
      handleCloseModal();
    } catch (err) {
      console.error("err", err);
    } finally {
      setIsSubmitting(false);
      stopLoading();
    }
  };

  const theme = useTheme();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedPerfis(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <Modal
      open={modalData.open === "atribuirPerfis"}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        className="fluxo-atendimento__modal__box credencial__modal"
        id="modal__credencial"
      >
        <h1 className="fluxo-atendimento__modal__titulo">Adicionar Perfil</h1>

        <div className="modalAdicionarPerfil__container">
          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="demo-multiple-chip-label">Perfis</InputLabel>
            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              multiple
              value={selectedPerfis}
              onChange={handleChange}
              input={<OutlinedInput id="select-multiple-chip" label="Perfis" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => {
                    const perfil = allPerfis.find((p) => p._id === value);
                    return (
                      <Chip key={value} label={perfil ? perfil.nome : value} />
                    );
                  })}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {allPerfis.map((perfil) => (
                <MenuItem
                  key={perfil._id}
                  value={perfil._id}
                  style={getStyles(perfil.nome, selectedPerfis, theme)}
                >
                  {perfil.nome}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className="fluxo-atendimento__modal__bottom_btns">
          <span
            className="cancel-btn"
            onClick={handleCloseModal}
            style={{ display: "block" }}
          >
            Fechar
          </span>
          <span
            className="blue-button"
            disabled={isSubmitting}
            onClick={submit}
            style={{ display: "block" }}
          >
            {isSubmitting ? "Guardando..." : "Guardar"}
          </span>
        </div>
      </Box>
    </Modal>
  );
}

export default ModalAtribuirPerfis;
