import { useState, useEffect, useContext } from "react";
import ListAtestados from "../../Lists/ListAtestados/ListAtestados";
import moment from "moment/moment";
import "./AtestadosComponent.scss";
//MUI
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import InputNumber from "../../../../../../shared/components/inputs/InputNumber/InputNumber";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import { ClinicaContext } from "../../../../../../shared/context/clinica-context";
import { AuthContext } from "../../../../../../shared/context/auth-context";
import { useLoading } from "../../../../../../shared/context/LoadingContext";

import toast from "react-hot-toast";
function AtestadosComponent({
  clienteId,
  sendRequest,
  open,
  handleCloseModalAtestado,
  dadosAtestado,
}) {
  const { startLoading, stopLoading } = useLoading();

  const [atestados, setAtestados] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dataAtestado, setDataAtestado] = useState(moment());
  const [horaInicial, setHoraInicial] = useState(moment());
  const [horaFinal, setHoraFinal] = useState(moment());
  const [comparecimento, setComparecimento] = useState(false);
  const [dias, setDias] = useState(false);
  const [nrDias, setNrDias] = useState(1);
  const [tipoDependente, setTipoDependente] = useState("Do próprio");
  const [nomeDependente, setNomeDependente] = useState("");
  const [finalidade, setFinalidade] = useState("Laborais");
  const [finalidadeDescricao, setFinalidadeDescricao] = useState("");
  const [erro, setErro] = useState("");

  const clinica = useContext(ClinicaContext);
  const auth = useContext(AuthContext);

  useEffect(() => {
    const fetchAtestadosByCliente = async () => {
      startLoading();
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/atestados/cliente/${clienteId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        const temp = responseData.atestados.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });

        setAtestados(temp);
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    };
    fetchAtestadosByCliente();
  }, [sendRequest, clienteId, auth.token]);

  const handleApagar = async (id) => {
    startLoading();
    try {
      const formData = new FormData();
      formData.append("utilizador", auth.userId);
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/atestados/eliminar/${id}`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );
      setAtestados((currentAtestados) => {
        return currentAtestados.filter((el) => {
          return el.id !== id;
        });
      });

      toast.success("Eliminação da atestado efetuada com sucesso!");
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  const handleSubmit = async () => {
    startLoading();
    try {
      const formData = new FormData();
      formData.append("criadoPor", auth.userId);
      formData.append("cliente", clienteId);
      formData.append("clinica", clinica.clinica._id);
      formData.append("medico", auth.userId);

      formData.append(
        "finalidade",
        finalidade === "outro" ? finalidadeDescricao : finalidade
      );
      formData.append("data_atestado", dataAtestado);
      formData.append("tipo_dependente", tipoDependente);
      if (tipoDependente === "Do seu dependente") {
        formData.append("nome_dependente", nomeDependente);
      }

      if (dias && comparecimento) {
        formData.append("tipo", "Dias e Comparecimento");
      } else {
        if (dias) {
          formData.append("tipo", "Dias");
        }
        if (comparecimento) {
          formData.append("tipo", "Comparecimento");
        }
      }

      if (dias) {
        formData.append("dias", nrDias);
      }

      if (comparecimento) {
        formData.append("hora_inicial", horaInicial);
        formData.append("hora_final", horaFinal);
      }
      let respostaAtestado;
      try {
        respostaAtestado = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/atestados`,
          "POST",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
      } catch (err) {
        console.error("err", err);
      }

      const temp = [...atestados, respostaAtestado.atestado];

      setAtestados(
        temp.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        })
      );

      setIsSubmitting(false);
      handleCloseModalAtestado();
      toast.success("Atestado gerado com sucesso");
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const preSubmit = () => {
    let haErro = false;

    if (!comparecimento && !dias) {
      haErro = true;
      setErro("Escolha o(s) tipo(s) de atestado(s)");
    }

    if (dias && (!nrDias || nrDias === 0)) {
      haErro = true;
      setErro(
        `O atestado é de dias mas o campo "número de dias" contem um valor inválido!`
      );
    }

    if (
      (comparecimento &&
        (!horaFinal ||
          !horaInicial ||
          !moment(horaFinal, "HH:mm").isValid())) ||
      !moment(horaInicial, "HH:mm").isValid()
    ) {
      haErro = true;
      setErro(`Preencha os campos "Hora inicial" e "Hora Final"`);
    }

    if (tipoDependente === "Do seu dependente" && !nomeDependente) {
      haErro = true;
      setErro(`Introduza o nome do dependente`);
    }

    if (finalidade === "outro" && !finalidadeDescricao) {
      haErro = true;
      setErro(`Introduza a finalidade do atestado`);
    }

    if (!dataAtestado) {
      haErro = "true";
      setErro("Introduza a data do atestado");
    } else if (!moment.isMoment(dataAtestado) || !dataAtestado.isValid()) {
      haErro = "true";
      setErro("Introduza a data do atestado corretamente");
    }

    if (!haErro) {
      setErro("");
      setIsSubmitting(true);
      handleSubmit();
    }
  };

  const handleChangeRadio = (e) => {
    setTipoDependente(e.target.value);
  };

  const handleFinalidade = (e) => {
    if (e.target.value === "Laborais") {
      setFinalidadeDescricao("");
    }

    setFinalidade(e.target.value);
  };
  return (
    <>
      {atestados.length !== 0 && (
        <>
          <ListAtestados
            data={atestados}
            handleApagar={handleApagar}
            dadosAtestado={dadosAtestado}
          />
        </>
      )}
      {atestados.length === 0 && (
        <div className="no_data_div">Nenhum atestado encontrado.</div>
      )}

      {/* Modal Gerar Atestado */}
      {open && (
        <Modal
          open={open}
          onClose={handleCloseModalAtestado}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            className="fluxo-atendimento__modal__box aprovar-orcamento__modal"
            id="modal__cinquentaPorCento"
          >
            <h1 className="fluxo-atendimento__modal__titulo">Gerar Atestado</h1>

            <div className="atestado-modal__body-container">
              <div className="atestado-modal__body-container__row">
                <span className="atestado-modal__subtitulo">
                  Tipo de atestado
                </span>
                <div className="atestado-modal__body-container__row--horizontal">
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Atestado de dias"
                    checked={dias}
                    onChange={(e) => setDias(e.target.checked)}
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Atestado de comparecimento"
                    checked={comparecimento}
                    onChange={(e) => setComparecimento(e.target.checked)}
                  />
                </div>
              </div>
              <div className="atestado-modal__body-container__row">
                <div className="atestado-modal__body-container__row--horizontal">
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesktopDatePicker
                      className="dataAtestado__datePicker"
                      label="Data do atestado*"
                      inputFormat="DD-MM-YYYY"
                      value={dataAtestado}
                      onChange={(value) => setDataAtestado(value)}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>

                  {comparecimento && (
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <MobileTimePicker
                        value={horaInicial}
                        label="Hora inicial*"
                        onChange={(value) => setHoraInicial(value)}
                        renderInput={(params) => <TextField {...params} />}
                      />

                      <MobileTimePicker
                        value={horaFinal}
                        label="Hora final*"
                        onChange={(value) => setHoraFinal(value)}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  )}
                  {dias && (
                    <InputNumber
                      initialValue={nrDias}
                      min={1}
                      max={100}
                      handleChange={(valor) => setNrDias(valor)}
                      label="nr dias"
                      className="nrDiasAtestado__datePicker"
                    />
                  )}
                </div>
              </div>
              <div className="atestado-modal__body-container__row">
                <span className="atestado-modal__subtitulo">Justificação</span>
                <div className="atestado-modal__body-container__row--horizontal">
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={tipoDependente}
                    onChange={handleChangeRadio}
                  >
                    <FormControlLabel
                      value="Do próprio"
                      control={<Radio />}
                      label="Do próprio"
                    />

                    <FormControlLabel
                      value="Do seu dependente"
                      control={<Radio />}
                      label="Do seu dependente"
                    />
                  </RadioGroup>
                </div>
              </div>
              {tipoDependente === "Do seu dependente" && (
                <TextField
                  label="Nome do dependente"
                  variant="outlined"
                  onChange={(e) => setNomeDependente(e.target.value)}
                  value={nomeDependente}
                />
              )}

              <div className="atestado-modal__body-container__row form-input">
                <select className="input" onChange={handleFinalidade}>
                  <option value="Laborais">Laboral</option>
                  <option value="Escolar">Escolar</option>
                  <option value="outro">Outro</option>
                </select>
              </div>
              {finalidade === "outro" && (
                <div className="atestado-modal__body-container__row">
                  <TextField
                    label="Finalidade"
                    variant="outlined"
                    onChange={(e) => setFinalidadeDescricao(e.target.value)}
                    multiline
                    value={finalidadeDescricao}
                    rows={4}
                  />
                </div>
              )}
              {erro && <div className="stockUpdate__erro">{erro}</div>}
            </div>

            <div className="fluxo-atendimento__modal__bottom_btns">
              <span
                className="cancel-btn"
                onClick={handleCloseModalAtestado}
                style={{ display: "block" }}
              >
                Fechar
              </span>
              <span
                className="blue-button"
                disabled={isSubmitting}
                onClick={preSubmit}
                style={{ display: "block" }}
              >
                {isSubmitting ? "Gerando..." : "Gerar"}
              </span>
            </div>
          </Box>
        </Modal>
      )}
    </>
  );
}

export default AtestadosComponent;
