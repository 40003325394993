import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import "./ModalAdicionarEvolucao.scss";
import toast from "react-hot-toast";
import { useLoading } from "../../../../shared/context/LoadingContext";

function ModalExecutarConsulta({
  handleCloseModal,
  open,
  dados,
  auth,
  sendRequest,
  handleModalSubmitSuccess,
}) {
  const navigate = useNavigate();
  const { startLoading, stopLoading } = useLoading();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const relatorioRef = useRef(null);

  const submitHandler = async (cons) => {
    startLoading();
    try {
      const formData = new FormData();
      formData.append(
        "relatorio",
        relatorioRef.current.value
          ? relatorioRef.current.value
          : `Consulta executada com sucesso!`
      );
      formData.append("utilizador", auth.userId);

      try {
        await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/consultas/executar/${dados.id}`,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
      } catch (err) {
        console.error("err", err);
      }

      toast.success("Consulta executada.Relatório adicionado com sucesso");
      handleModalSubmitSuccess(dados.id);
      if (cons) {
        navigate(`/avaliacao/new/${dados.idCliente}`);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  const preSubmitHandler = (cons) => {
    setIsSubmitting(true);
    submitHandler(cons);
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="fluxo-atendimento__modal__box" id="dividas__modal">
        <h1 className="fluxo-atendimento__modal__titulo">
          Relatório da consulta - {dados.nome}
        </h1>

        <div className="adicionarEvolucaoContainer">
          <textarea
            className="executarProcedimento__container__textarea"
            placeholder="Escreva aqui o relatório da consulta."
            cols="30"
            rows="5"
            ref={relatorioRef}
          />
        </div>

        <div
          style={{
            gridColumn: "1/-1",
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "25px",
          }}
        >
          <span
            disabled={isSubmitting}
            className="blue-button"
            onClick={preSubmitHandler.bind(null, null)}
            style={{ marginRight: "10px" }}
          >
            {isSubmitting ? "Guardando..." : "Guardar"}
          </span>

          <span
            disabled={isSubmitting}
            className="blue-button"
            onClick={preSubmitHandler.bind(null, "consulta")}
            style={{ marginRight: "10px" }}
          >
            {isSubmitting ? "Guardando..." : "Guardar e criar avaliação"}
          </span>

          <span className="cancel-btn" onClick={handleCloseModal}>
            Fechar
          </span>
        </div>
      </Box>
    </Modal>
  );
}

export default ModalExecutarConsulta;
