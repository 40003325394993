import { useEffect, useState, useContext } from "react";
import { Button } from "@mui/material";
import InputText from "../../../shared/components/inputs/InputText/InputText";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "./CategoriasFinanceiro.scss";
import { AuthContext } from "../../../shared/context/auth-context";
import { Popconfirm } from "antd";
import ErrorIcon from "@mui/icons-material/Error";
import { useLoading } from "../../../shared/context/LoadingContext";
import toast from "react-hot-toast";
//MUI
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      color: "#0c53fb",
    },
  },
};

const tipos = ["despesa", "entrada"];

function CategoriasFinanceiro({ sendRequest, clinicaId }) {
  const { startLoading, stopLoading } = useLoading();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [nomeCategoria, setNomeCategoria] = useState("");
  const [tiposCategoria, setTiposCategoria] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [idCategoria, setIdCategoria] = useState(null);
  const [categorias, setCategorias] = useState([]);
  const [mensagemDeErro, setMensagemDeErro] = useState("");
  const auth = useContext(AuthContext);

  useEffect(() => {
    const fetchCategorias = async () => {
      startLoading();
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/categoriasfinanceiro/clinica/${clinicaId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setCategorias(responseData.categoriasFinanceiro);
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    };
    fetchCategorias();
  }, [auth.token, clinicaId, sendRequest]);

  const categoriaChangeHandler = (valor) => {
    setNomeCategoria(valor);
  };

  const guardarCategoria = async () => {
    startLoading();
    try {
      const formData = new FormData();
      formData.append("categoria", nomeCategoria);
      formData.append("tipo", JSON.stringify(tiposCategoria));
      formData.append("clinica", clinicaId);
      formData.append("criadoPor", auth.userId);

      const categoriaCriada = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/categoriasfinanceiro/`,
        "POST",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      setCategorias((current) => [
        ...current,
        categoriaCriada.categoriaFinanceiro,
      ]);

      setNomeCategoria("");
      setTiposCategoria([]);
      setIdCategoria(null);
      setOpenModal(false);

      toast.success("Categoria criada com sucesso");
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
    setIsSubmitting(false);
  };

  const editarCategoria = async (id) => {
    startLoading();
    try {
      const formData = new FormData();
      formData.append("categoria", nomeCategoria);
      formData.append("tipo", JSON.stringify(tiposCategoria));
      formData.append("clinica", clinicaId);
      formData.append("atualizadoPor", auth.userId);

      await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/categoriasfinanceiro/${id}`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      const categoriaCopy = [...categorias];

      const categoriasModificadas = categoriaCopy.map((cat) => {
        if (cat.id === id) {
          return {
            id: id,
            categoria: nomeCategoria,
            tipo: tiposCategoria,
          };
        } else {
          return cat;
        }
      });

      setCategorias([...categoriasModificadas]);

      setNomeCategoria("");
      setTiposCategoria([]);
      setIdCategoria(null);
      setOpenModal(false);

      toast.success("Categoria modificada com sucesso");
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
    setIsSubmitting(false);
  };

  const preEditarCategoria = async (id) => {
    setIdCategoria(id);
    const categoriaFiltrada = categorias.filter((cat) => cat.id === id)[0];
    setNomeCategoria(categoriaFiltrada.categoria);
    setTiposCategoria(categoriaFiltrada.tipo);
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setMensagemDeErro("");
    setIdCategoria(null);
    setNomeCategoria("");
    setTiposCategoria([]);
    setOpenModal(false);
  };

  const desativarCategoria = async (id) => {
    startLoading();
    try {
      const formDataDesativar = new FormData();
      formDataDesativar.append("utilizador", auth.userId);
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/categoriasfinanceiro/toggleativo/${id}`,
        "PATCH",
        formDataDesativar,
        {
          Authorization: "Bearer " + auth.token,
        }
      );
      setCategorias((current) => current.filter((cat) => cat.id !== id));

      toast.success("Categoria desativada com sucesso");
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  const beforeSubmit = (idCategoria) => {
    let filteredCategoria;
    let duplicidadeCategoria = false;

    if (idCategoria) {
      filteredCategoria = categorias.find((c) => c.id === idCategoria);

      duplicidadeCategoria = categorias.filter(
        (c) => c.categoria.toLowerCase() === nomeCategoria.trim().toLowerCase()
      );
    }

    //Se o nome permanecer igual, não modificamos nada
    if (
      idCategoria &&
      filteredCategoria?.categoria?.toLowerCase() ===
        nomeCategoria.trim().toLowerCase()
    ) {
      setNomeCategoria("");
      setIdCategoria(null);
      setOpenModal(false);
    } else {
      let haErro = false;
      if (duplicidadeCategoria.length > 0) {
        setMensagemDeErro("Já existe uma categoria com esse nome.");
        haErro = true;
      } else if (!nomeCategoria || nomeCategoria.trim() === "") {
        setMensagemDeErro("Introduza um nome para a categoria.");
        haErro = true;
      } else if (!tiposCategoria || tiposCategoria.length === 0) {
        haErro = true;
        setMensagemDeErro("Deve escolher pelo menos 1 tipo de categoria");
      } else {
        setMensagemDeErro("");
      }

      if (!isSubmitting && !haErro) {
        setIsSubmitting(true);
        if (idCategoria) {
          editarCategoria(idCategoria);
        } else {
          guardarCategoria();
        }
      }
    }
  };

  const tiposCategoriaChangeHandler = (event) => {
    const {
      target: { value },
    } = event;
    setTiposCategoria(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <>
      <div className="categoriasFinanceiro__container">
        {(auth.role === "Médico/Administrador" ||
          auth.role === "Administrador Não Médico") && (
          <div className="categoriasFinanceiro__container__btn-adicionar">
            <Button
              variant="contained"
              color="success"
              onClick={(e) => setOpenModal(true)}
            >
              Nova Categoria
            </Button>
          </div>
        )}

        <div className="categoriasFinanceiro__row">
          <span className="categoriasFinanceiro__titulo">Ambas</span>
          {categorias &&
            categorias.map(
              (categoria, index) =>
                categoria.tipo.length === 2 && (
                  <div
                    className="categoriasFinanceiro__container__categoria"
                    key={index}
                  >
                    <div className="categoriasFinanceiro__container__categoria--1">
                      {categoria.categoria}
                    </div>
                    <div className="categoriasFinanceiro__container__categoria--2">
                      {(auth.role === "Médico/Administrador" ||
                        auth.role === "Administrador Não Médico") &&
                        categoria.categoria !== "Avaliação" && (
                          <Popconfirm
                            title={`Desativar Categoria`}
                            description={`Pretende desativar essa categoria?`}
                            icon={<ErrorIcon style={{ color: "red" }} />}
                            okText="Sim"
                            cancelText="Não"
                            onConfirm={desativarCategoria.bind(
                              null,
                              categoria.id
                            )}
                            className="popConfirm--biggerPadding"
                          >
                            <span>Desativar</span>
                          </Popconfirm>
                        )}
                      {(auth.role === "Médico/Administrador" ||
                        auth.role === "Administrador Não Médico") &&
                        categoria.categoria !== "Avaliação" && (
                          <span
                            onClick={preEditarCategoria.bind(
                              null,
                              categoria.id
                            )}
                          >
                            Editar
                          </span>
                        )}
                    </div>
                  </div>
                )
            )}
        </div>

        <div className="categoriasFinanceiro__row">
          <span className="categoriasFinanceiro__titulo">Despesas</span>
          {categorias &&
            categorias.map(
              (categoria, index) =>
                categoria.tipo.length === 1 &&
                categoria.tipo[0] === "despesa" && (
                  <div
                    className="categoriasFinanceiro__container__categoria"
                    key={index}
                  >
                    <div className="categoriasFinanceiro__container__categoria--1">
                      {categoria.categoria}
                    </div>
                    <div className="categoriasFinanceiro__container__categoria--2">
                      {(auth.role === "Médico/Administrador" ||
                        auth.role === "Administrador Não Médico") && (
                        <Popconfirm
                          title={`Desativar Categoria`}
                          description={`Pretende desativar essa categoria?`}
                          icon={<ErrorIcon style={{ color: "red" }} />}
                          okText="Sim"
                          cancelText="Não"
                          onConfirm={desativarCategoria.bind(
                            null,
                            categoria.id
                          )}
                          className="popConfirm--biggerPadding"
                        >
                          <span>Desativar</span>
                        </Popconfirm>
                      )}
                      {(auth.role === "Médico/Administrador" ||
                        auth.role === "Administrador Não Médico") && (
                        <span
                          onClick={preEditarCategoria.bind(null, categoria.id)}
                        >
                          Editar
                        </span>
                      )}
                    </div>
                  </div>
                )
            )}
        </div>

        <div className="categoriasFinanceiro__row">
          <span className="categoriasFinanceiro__titulo">Entradas</span>
          {categorias &&
            categorias.map(
              (categoria, index) =>
                categoria.tipo.length === 1 &&
                categoria.tipo[0] === "entrada" && (
                  <div
                    className="categoriasFinanceiro__container__categoria"
                    key={index}
                  >
                    <div className="categoriasFinanceiro__container__categoria--1">
                      {categoria.categoria}
                    </div>
                    <div className="categoriasFinanceiro__container__categoria--2">
                      {(auth.role === "Médico/Administrador" ||
                        auth.role === "Administrador Não Médico") &&
                        categoria.categoria !== "Avaliação" && (
                          <Popconfirm
                            title={`Desativar Categoria`}
                            description={`Pretende desativar essa categoria?`}
                            icon={<ErrorIcon style={{ color: "red" }} />}
                            okText="Sim"
                            cancelText="Não"
                            onConfirm={desativarCategoria.bind(
                              null,
                              categoria.id
                            )}
                            className="popConfirm--biggerPadding"
                          >
                            <span>Desativar</span>
                          </Popconfirm>
                        )}
                      {(auth.role === "Médico/Administrador" ||
                        auth.role === "Administrador Não Médico") &&
                        categoria.categoria !== "Avaliação" && (
                          <span
                            onClick={preEditarCategoria.bind(
                              null,
                              categoria.id
                            )}
                          >
                            Editar
                          </span>
                        )}
                    </div>
                  </div>
                )
            )}
        </div>

        <Modal
          open={openModal}
          onClose={handleModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            className="fluxo-atendimento__modal__box adicionar-categoria__modal"
            id="modal__adicionar-categoria"
          >
            <h1 className="fluxo-atendimento__modal__titulo">Categoria</h1>
            <div className="adicionar-categoria__modal__container">
              <InputText
                className="adicionar-categoria__modal__container--span2"
                label="Nome da Categoria *"
                initialValue={nomeCategoria}
                handleChange={categoriaChangeHandler}
              />

              <FormControl
                sx={{ m: 1 }}
                style={{ width: "100%", margin: "25px 0 0 0 " }}
              >
                <InputLabel id="demo-multiple-checkbox-label">Tipos</InputLabel>
                <Select
                  id="demo-multiple-checkbox"
                  multiple
                  value={tiposCategoria}
                  onChange={tiposCategoriaChangeHandler}
                  input={<OutlinedInput label="Tipos" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                  style={{ color: "#0c53fb" }}
                >
                  {tipos.map((tipo) => (
                    <MenuItem key={tipo} value={tipo}>
                      <Checkbox checked={tiposCategoria.indexOf(tipo) > -1} />
                      <ListItemText primary={tipo} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            {mensagemDeErro && (
              <div className="erroContainer__categoria-caixa">
                {mensagemDeErro}
              </div>
            )}
            <div className="fluxo-atendimento__modal__bottom_btns">
              <span
                className="cancel-btn"
                onClick={handleModalClose}
                style={{ display: "block" }}
              >
                Fechar
              </span>

              <span
                className="blue-button"
                onClick={
                  idCategoria
                    ? beforeSubmit.bind(null, idCategoria)
                    : beforeSubmit.bind(null, null)
                }
              >
                {idCategoria
                  ? isSubmitting
                    ? "Editando"
                    : "Editar"
                  : isSubmitting
                  ? "Guardando"
                  : "Guardar"}
              </span>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
}

export default CategoriasFinanceiro;
