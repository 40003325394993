import { useContext } from "react";
import { AuthContext } from "../../../../../../shared/context/auth-context";
import { Link } from "react-router-dom";
import "../List.scss";

//mui
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete"; //Delete icon
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"; //ver
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined"; //gerar Pdf
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
//Antd
import { Popover } from "antd";
import { Popconfirm } from "antd";
import moment from "moment";
function List(props) {
  const auth = useContext(AuthContext);

  const verOrcamento = (id, tipo) => {
    const filteredOrcamento = props.data.filter((orc) => {
      return orc.id === id;
    })[0];

    const pdfServiceList = [];
    //Para orcamento particular
    if (tipo === "Particular") {
      if (filteredOrcamento.beneficio === "Particular") {
        //Se o beneficio for particular, todos os procedimentos terão preço particular
        filteredOrcamento.procedimentos.forEach((proc) => {
          pdfServiceList.push({
            tratamento: proc.tratamento,
            dente: proc.dente,
            quantidade: 1,
            faces: proc.faces.join(","),
            pr_total: proc.tratamento.preco_particular,
            medico: proc.medico,
          });
        });
      } else {
        //Se o beneficio for inps, apenas selecionamos os beneficios sem cobertura
        filteredOrcamento.procedimentos.forEach((proc) => {
          if (!proc.tratamento.coberturaInps) {
            pdfServiceList.push({
              tratamento: proc.tratamento,
              dente: proc.dente,
              quantidade: 1,
              faces: proc.faces.join(","),
              pr_total: proc.tratamento.preco_particular,
              medico: proc.medico,
            });
          }
        });
      }
    } else {
      //Para orcamento beneficiario
      filteredOrcamento.procedimentos.forEach((proc) => {
        if (proc.tratamento.coberturaInps) {
          pdfServiceList.push({
            tratamento: proc.tratamento,
            dente: proc.dente,
            quantidade: 1,
            faces: proc.faces.join(","),
            pr_total: proc.tratamento.preco_beneficiario,
            medico: proc.medico,
          });
        }
      });
    }
    props.orcamentoData(
      tipo,
      pdfServiceList,
      filteredOrcamento.cliente,
      filteredOrcamento.clinica,
      filteredOrcamento.medico_responsavel,
      filteredOrcamento.numero,
      filteredOrcamento.desconto,
      filteredOrcamento.tipoDesconto,
      filteredOrcamento.orcamentoMisto
    );
  };
  let content = [];
  if (props.data) {
    props.data.forEach((row) => {
      content.push(
        <div className="dots__menu__popup">
          {/* Ver Orcamento */}
          {auth.perm.includes("r-orc") && (
            <>
              {(row.beneficio === "Particular" ||
                (row.beneficio === "INPS" &&
                  row.procedimentos.filter((pr) => !pr.tratamento.coberturaInps)
                    .length > 0)) &&
                row.status !== "INTERROMPIDO" &&
                (((auth.role === "Médico/Administrador" ||
                  auth.role === "Dentista") &&
                  row.medico_responsavel._id === auth.userId) ||
                  ((auth.role === "Secretária(o)" ||
                    auth.role === "Assistente") &&
                    auth.usuariosAssociados.some(
                      (medico) => medico.id === row.medico_responsavel.id
                    ))) && (
                  <div
                    className="popOverMenu--option"
                    onClick={verOrcamento.bind(null, row.id, "Particular")}
                  >
                    <span>Gerar PDF Particular</span>
                    <PictureAsPdfOutlinedIcon className="popOverMenu--option__icon" />
                  </div>
                )}

              {row.beneficio === "INPS" &&
                row.status !== "INTERROMPIDO" &&
                (((auth.role === "Médico/Administrador" ||
                  auth.role === "Dentista") &&
                  row.medico_responsavel._id === auth.userId) ||
                  ((auth.role === "Secretária(o)" ||
                    auth.role === "Assistente") &&
                    auth.usuariosAssociados.some(
                      (medico) => medico.id === row.medico_responsavel.id
                    ))) && (
                  <div
                    className="popOverMenu--option"
                    onClick={verOrcamento.bind(null, row.id, "INPS")}
                  >
                    <span>Gerar PDF INPS</span>
                    <PictureAsPdfOutlinedIcon className="popOverMenu--option__icon" />
                  </div>
                )}
            </>
          )}

          {auth.perm.includes("r-orc") && (
            <div
              className="popOverMenu--option"
              onClick={props.handleOpenModal.bind(null, row.id, "orcamento")}
            >
              <span>Ver Orçamento</span>
              <RemoveRedEyeIcon className="popOverMenu--option__icon" />
            </div>
          )}

          {auth.perm.includes("u-orc") &&
            !row.nrCredencial &&
            row.beneficio === "INPS" &&
            row.status !== "INTERROMPIDO" &&
            row.transformado &&
            (((auth.role === "Médico/Administrador" ||
              auth.role === "Dentista") &&
              row.medico_responsavel._id === auth.userId) ||
              ((auth.role === "Secretária(o)" || auth.role === "Assistente") &&
                auth.usuariosAssociados.some(
                  (medico) => medico.id === row.medico_responsavel.id
                ))) && (
              <div
                className="popOverMenu--option"
                onClick={props.preAdicionarCredencialHandler.bind(null, row.id)}
              >
                <span>Inserir Credencial</span>
                <ControlPointIcon className="popOverMenu--option__icon" />
              </div>
            )}

          {auth.perm.includes("u-orc") &&
            row.nrCredencial &&
            row.beneficio === "INPS" &&
            row.status !== "INTERROMPIDO" &&
            (((auth.role === "Médico/Administrador" ||
              auth.role === "Dentista") &&
              row.medico_responsavel._id === auth.userId) ||
              ((auth.role === "Secretária(o)" || auth.role === "Assistente") &&
                auth.usuariosAssociados.some(
                  (medico) => medico.id === row.medico_responsavel.id
                ))) && (
              <div
                className="popOverMenu--option"
                onClick={props.preEditarCredencialHandler.bind(null, row.id)}
              >
                <span>Editar Credencial</span>
                <BorderColorIcon className="popOverMenu--option__icon" />
              </div>
            )}

          {auth.perm.includes("u-orc") &&
            !row.transformado &&
            !row.terminado &&
            row.status !== "INTERROMPIDO" &&
            (((auth.role === "Médico/Administrador" ||
              auth.role === "Dentista") &&
              row.medico_responsavel._id === auth.userId) ||
              ((auth.role === "Secretária(o)" || auth.role === "Assistente") &&
                auth.usuariosAssociados.some(
                  (medico) => medico.id === row.medico_responsavel.id
                ))) && (
              <div
                className="popOverMenu--option"
                onClick={props.handleOpenModal.bind(null, row.id, "orcamento")}
              >
                <Link
                  to={`../../../orcamentos/edit/${row.id}`}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Editar Orcamento
                </Link>
                <EditIcon className="popOverMenu--option__icon" />
              </div>
            )}

          {/* Desativar */}
          {auth.perm.includes("d-orc") &&
            !row.transformado &&
            !row.terminado &&
            row.status !== "INTERROMPIDO" &&
            (((auth.role === "Médico/Administrador" ||
              auth.role === "Dentista") &&
              row.medico_responsavel._id === auth.userId) ||
              ((auth.role === "Secretária(o)" || auth.role === "Assistente") &&
                auth.usuariosAssociados.some(
                  (medico) => medico.id === row.medico_responsavel.id
                ))) && (
              <Popconfirm
                title="Desativação de orçamento"
                description="Tem a certeza que pretende desativar o orçamento?"
                icon={<DeleteIcon style={{ color: "red" }} />}
                okText="Sim"
                cancelText="Não"
                onConfirm={props.handleDesativar.bind(null, row.id)}
              >
                <div className="popOverMenu--option">
                  <span>Desativar</span>
                  <DeleteIcon className="popOverMenu--option__icon" />
                </div>
              </Popconfirm>
            )}
        </div>
      );
    });
  }

  return (
    <>
      <TableContainer component={Paper} className="table">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="tableCell tableCell--heading tableCell--heading--start">
                Código
              </TableCell>
              <TableCell className="tableCell tableCell--heading tableCell tableCell--heading--start">
                Avaliação
              </TableCell>

              <TableCell className="tableCell tableCell--heading">
                Criado por
              </TableCell>

              <TableCell className="tableCell tableCell--heading">
                Médico Responsável
              </TableCell>

              <TableCell className="tableCell tableCell--heading">
                Tipo
              </TableCell>

              <TableCell className="tableCell tableCell--heading">
                Nº Credencial
              </TableCell>

              <TableCell className="tableCell tableCell--heading">
                Data Criação
              </TableCell>

              <TableCell className="tableCell tableCell--heading">
                Estado
              </TableCell>

              <TableCell className="tableCell tableCell--heading tableCell tableCell--heading--end">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>

          {props.data && (
            <TableBody>
              {props.data.map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell className="tableCell">
                    {"OR" +
                      row.codigo +
                      "_AV" +
                      row.avaliacao?.numero +
                      "_" +
                      moment(row.created_at).year()}
                  </TableCell>

                  <TableCell
                    className="tabelCell "
                    style={{ textAlign: "left" }}
                  >
                    {auth.perm.includes("r-aval") && (
                      <Tooltip title="Ver Avaliação">
                        <RemoveRedEyeIcon
                          className="icon__list"
                          onClick={props.handleOpenModal.bind(
                            null,
                            row.id,
                            "avaliacao"
                          )}
                        />
                      </Tooltip>
                    )}
                  </TableCell>

                  <TableCell className="tableCell">
                    {row.criadoPor?.name}
                  </TableCell>

                  <TableCell className="tableCell">
                    {row.medico_responsavel?.name}
                  </TableCell>

                  <TableCell className="tableCell">
                    {row.beneficio}
                    {row.orcamentoMisto ? "/Misto" : ""}
                  </TableCell>

                  <TableCell className="tableCell">
                    {row.nrCredencial ? row.nrCredencial : ""}
                  </TableCell>

                  <TableCell className="tableCell">
                    {row.data_orcamento}
                  </TableCell>

                  <TableCell className="tableCell">
                    <span
                      className={
                        row.status
                          ? "status " + row.status
                          : row.terminado
                          ? "status concluido"
                          : `${
                              row.transformado
                                ? "status transformado"
                                : "status pendente"
                            }`
                      }
                    >
                      {row.status === "INTERROMPIDO"
                        ? "Interrompido"
                        : row.terminado
                        ? "Concluido"
                        : `${row.transformado ? "Transformado" : "Pendente"}`}
                    </span>
                  </TableCell>

                  <TableCell className="tableCell">
                    <div className="tableCell__action">
                      {/* Aprovar Orcamento */}
                      {auth.perm.includes("c-plt") &&
                        !row.transformado &&
                        (((auth.role === "Médico/Administrador" ||
                          auth.role === "Dentista") &&
                          row.medico_responsavel._id === auth.userId) ||
                          ((auth.role === "Secretária(o)" ||
                            auth.role === "Assistente") &&
                            auth.usuariosAssociados.some(
                              (medico) =>
                                medico.id === row.medico_responsavel.id
                            ))) && (
                          <Tooltip title="Aprovar">
                            <CheckIcon
                              className="icon__list"
                              onClick={props.aprovarOrcamento.bind(
                                null,
                                row.id
                              )}
                            />
                          </Tooltip>
                        )}

                      {content[index] && (
                        <Popover
                          content={content[index]}
                          trigger="click"
                          placement="bottom"
                        >
                          <div className="dots__menu">
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                          </div>
                        </Popover>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
}

export default List;
