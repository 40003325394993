import React from "react";
import { Link } from "react-router-dom";
import "./Tooltip.scss";

const Tooltip = ({ event }) => {
  return (
    <div className="tooltip">
      {!event.extendedProps.isCompromisso && (
        <>
          <div className="tooltip__userNameProcedure">
            <svg
              className="tooltip__userNameProcedure__svg"
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="#000000"
              viewBox="0 0 256 256"
            >
              <path d="M230.92,212c-15.23-26.33-38.7-45.21-66.09-54.16a72,72,0,1,0-73.66,0C63.78,166.78,40.31,185.66,25.08,212a8,8,0,1,0,13.85,8c18.84-32.56,52.14-52,89.07-52s70.23,19.44,89.07,52a8,8,0,1,0,13.85-8ZM72,96a56,56,0,1,1,56,56A56.06,56.06,0,0,1,72,96Z"></path>
            </svg>
            <div className="tooltip__userNameProcedure__nameProcedureContainer">
              <span className="tooltip__userNameProcedure__name">
                <Link
                  to={`/clientes/${event.extendedProps.clientId}`}
                  className="tooltip__client-link"
                >
                  {event.title.split(" - ")[0]}
                </Link>
              </span>
              <span className="tooltip__userNameProcedure__procedure">
                #{" "}
                {event.extendedProps.isEvaluation
                  ? "Avaliação"
                  : event.extendedProps.inTransition
                  ? "Transição"
                  : event.extendedProps.isConsulta
                  ? "Consulta"
                  : !event.extendedProps.isCompromisso && "Procedimento"}
              </span>
            </div>
          </div>

          <div className="tooltip__precedureInfo">
            <span className="tooltip__precedureInfo__title">Procedimento</span>
            <span className="tooltip__precedureInfo__procedimentoName">
              {event.title.split(" - ")[1]}
            </span>
            <span className="tooltip__precedureInfo__title">Estado</span>
            <div className="tooltip__precedureInfo__procedimentoStatus">
              <span
                style={{
                  backgroundColor: event.extendedProps.isAttended
                    ? "#F2FAF5"
                    : event.extendedProps.isMissed
                    ? "#FFF3F6"
                    : "#fff7eb",
                  color: event.extendedProps.isAttended
                    ? "#39B93A"
                    : event.extendedProps.isMissed
                    ? "#D96C60"
                    : "#fdcb7f",
                }}
              >
                {event.extendedProps.isAttended
                  ? "Atendido"
                  : event.extendedProps.isMissed
                  ? "Faltou"
                  : "Pendente"}
              </span>
            </div>
          </div>
        </>
      )}

      {event.extendedProps.isCompromisso && (
        <>
          <span className="tooltip__title">Compromisso</span>
          <span className="tooltip__description">{event.title}</span>
        </>
      )}

      <div className="tooltip__dataAtendimento">
        <svg
          className="tooltip__dataAtendimento__svg"
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          fill="#000000"
          viewBox="0 0 256 256"
        >
          <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm64-88a8,8,0,0,1-8,8H128a8,8,0,0,1-8-8V72a8,8,0,0,1,16,0v48h48A8,8,0,0,1,192,128Z"></path>
        </svg>

        <span className="tooltip__dataAtendimento__data">
          {`${event.start.toLocaleDateString("pt-BR", {
            month: "long",
          })} ${event.start.getDate()}, ${event.start.getFullYear()}`}
        </span>
        <span className="tooltip__dataAtendimento__data">
          {event.start.toLocaleTimeString("pt-BR", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          })}{" "}
          -{" "}
          {event.end.toLocaleTimeString("pt-BR", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          })}
        </span>
      </div>

      <div className="tooltip__agendadoPor">
        <span className="tooltip__agendadoPor__title">Agendado por:</span>
        <span className="tooltip__agendadoPor__value">
          {event.extendedProps.agendadoPor}
        </span>
      </div>
    </div>
  );
};

export default Tooltip;
