import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../../../../shared/context/auth-context";
import { ClinicaContext } from "../../../../../../shared/context/clinica-context";
import "../List.scss";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { Popover, Popconfirm } from "antd";
import moment from "moment";

function ListAvaliacao(props) {
  const auth = useContext(AuthContext);
  const clinica = useContext(ClinicaContext);
  const navigate = useNavigate();

  const getMenuContent = (row) => {
    const menuItems = [];
    let hasContent = false;

    if (
      ((auth.role === "Médico/Administrador" || auth.role === "Dentista") &&
        row.medico_responsavel._id === auth.userId) ||
      ((auth.role === "Secretária(o)" || auth.role === "Assistente") &&
        auth.usuariosAssociados.some(
          (medico) => medico.id === row.medico_responsavel.id
        ))
    ) {
      if (
        !row.terminado &&
        !row.transformado &&
        row.status !== "INTERROMPIDO"
      ) {
        // Condition 1: Edit and Delete options
        hasContent = true;

        if (auth.perm.includes("u-aval")) {
          menuItems.push(
            <div
              key="edit"
              className="popOverMenu--option"
              onClick={() => navigate(`/avaliacao/edit/${row.id}`)}
            >
              <span>Editar</span>
              <ModeEditOutlineOutlinedIcon className="popOverMenu--option__icon" />
            </div>
          );
        }

        if (auth.perm.includes("d-aval")) {
          menuItems.push(
            <Popconfirm
              key="deactivate"
              title="Desativação de avaliação"
              description="Tem a certeza que pretende desativar a avaliação?"
              icon={<DeleteIcon style={{ color: "red" }} />}
              okText="Sim"
              cancelText="Não"
              onConfirm={() => props.handleDesativar(row.id)}
            >
              <div className="popOverMenu--option">
                <span>Desativar</span>
                <DeleteIcon className="popOverMenu--option__icon" />
              </div>
            </Popconfirm>
          );
        }
      }

      // Condition 2: Close option
      if (
        row.transformado &&
        !row.terminado &&
        !row.fechado &&
        row.status !== "INTERROMPIDO" &&
        auth.perm.includes("abrFech-aval")
      ) {
        hasContent = true;
        menuItems.push(
          <Popconfirm
            key="close"
            title="Fecho de avaliação"
            description="Tem a certeza que pretende fechar a avaliação?"
            icon={<VpnKeyIcon style={{ color: "red" }} />}
            okText="Sim"
            cancelText="Não"
            onConfirm={() => props.handleFecharAbrir(row.id)}
          >
            <div className="popOverMenu--option">
              <span>Fechar</span>
              <VpnKeyIcon className="popOverMenu--option__icon" />
            </div>
          </Popconfirm>
        );
      }

      // Condition 3: Open option
      if (
        row.fechado &&
        row.status !== "INTERROMPIDO" &&
        auth.perm.includes("abrFech-aval")
      ) {
        hasContent = true;
        menuItems.push(
          <Popconfirm
            key="open"
            title="Abertura de avaliação"
            description="Tem a certeza que pretende abrir a avaliação?"
            icon={<VpnKeyIcon style={{ color: "red" }} />}
            okText="Sim"
            cancelText="Não"
            onConfirm={() => props.handleFecharAbrir(row.id)}
          >
            <div className="popOverMenu--option">
              <span>Abrir</span>
              <VpnKeyIcon className="popOverMenu--option__icon" />
            </div>
          </Popconfirm>
        );
      }

      // Condition 4: Launch debit option
      if (
        !row.fechado &&
        row.status !== "INTERROMPIDO" &&
        !row.debitado &&
        clinica.clinica.configuracoes.configuracoes.avaliacao.ativo &&
        clinica.clinica.configuracoes.configuracoes.avaliacao.preco > 0 &&
        auth.perm.includes("deb-aval")
      ) {
        hasContent = true;
        menuItems.push(
          <Popconfirm
            key="debit"
            title="Lançar o valor da Avaliação em débito?"
            description="Tem a certeza que pretende lançar o valor da avaliação em débito?"
            icon={<AttachMoneyIcon style={{ color: "red" }} />}
            okText="Sim"
            cancelText="Não"
            onConfirm={() => props.handleLancarDebito(row.id)}
          >
            <div className="popOverMenu--option">
              <span>Lançar débito</span>
              <AttachMoneyIcon className="popOverMenu--option__icon" />
            </div>
          </Popconfirm>
        );
      }
    }

    return hasContent ? (
      <div className="dots__menu__popup">{menuItems}</div>
    ) : (
      "vazio"
    );
  };

  return (
    <TableContainer component={Paper} className="table">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className="tableCell tableCell--heading tableCell--heading--start">
              Código
            </TableCell>
            <TableCell className="tableCell tableCell--heading tableCell--heading--start">
              Criado por
            </TableCell>
            <TableCell className="tableCell tableCell--heading tableCell--heading--start">
              Médico Responsável
            </TableCell>
            <TableCell className="tableCell tableCell--heading">
              Data Avaliação
            </TableCell>
            <TableCell className="tableCell tableCell--heading">
              Estado
            </TableCell>
            <TableCell className="tableCell tableCell--heading tableCell--heading--end">
              Ações
            </TableCell>
          </TableRow>
        </TableHead>

        {props.data && (
          <TableBody>
            {props.data.map((row) => {
              const menuContent = getMenuContent(row);

              return (
                <TableRow key={row.id}>
                  <TableCell className="tableCell">
                    {"AV" + row.numero + "_" + moment(row.created_at).year()}
                  </TableCell>
                  <TableCell className="tableCell">
                    {row.criado_por?.name}
                  </TableCell>
                  <TableCell className="tableCell">
                    {row.medico_responsavel.name}
                  </TableCell>
                  <TableCell className="tableCell">
                    {row.data_avaliacao}
                  </TableCell>
                  <TableCell className="tableCell">
                    <span
                      className={`status ${
                        row.status
                          ? row.status
                          : row.terminado && !row.fechado
                          ? "concluido"
                          : row.transformado && !row.fechado
                          ? "transformado"
                          : !row.terminado && !row.transformado && !row.fechado
                          ? "pendente"
                          : row.fechado
                          ? "fechado"
                          : ""
                      }`}
                    >
                      {row.status === "INTERROMPIDO"
                        ? "Interrompido"
                        : row.terminado && !row.fechado
                        ? "Concluido"
                        : row.transformado && !row.fechado
                        ? "Transformado"
                        : !row.transformado && !row.terminado && !row.fechado
                        ? "Criado"
                        : row.fechado
                        ? "Fechado"
                        : ""}
                    </span>
                  </TableCell>
                  <TableCell className="tableCell">
                    <div className="tableCell__action">
                      {auth.perm.includes("c-orc") &&
                        !row.terminado &&
                        !row.fechado &&
                        (((auth.role === "Médico/Administrador" ||
                          auth.role === "Dentista") &&
                          row.medico_responsavel._id === auth.userId) ||
                          ((auth.role === "Secretária(o)" ||
                            auth.role === "Assistente") &&
                            auth.usuariosAssociados.some(
                              (medico) =>
                                medico.id === row.medico_responsavel.id
                            ))) && (
                          <Tooltip title="Gerar Orçamento">
                            <ArticleOutlinedIcon
                              className="icon__list"
                              onClick={() =>
                                navigate(`/orcamentos/new/${row.id}`)
                              }
                            />
                          </Tooltip>
                        )}
                      {(!auth.perm.includes("c-orc") || row.terminado) && (
                        <div>&#12644;&#12644;&#12644;</div>
                      )}
                      {auth.perm.includes("r-aval") && (
                        <Tooltip title="Ver Avaliação">
                          <RemoveRedEyeIcon
                            className="icon__list"
                            onClick={() =>
                              props.handleOpenModalAvaliacao(row.id)
                            }
                          />
                        </Tooltip>
                      )}

                      {row.debitado && (
                        <Tooltip
                          title={
                            row.entrada?.entradaRecebida
                              ? "Avaliação Debitada"
                              : "Débito Lançado"
                          }
                        >
                          <AttachMoneyIcon
                            className="icon__list"
                            style={{
                              color: row.entrada?.entradaRecebida
                                ? "12d453"
                                : "#fdcb7f",
                              cursor: "default",
                            }}
                          />
                        </Tooltip>
                      )}
                      {!auth.perm.includes("r-aval") && (
                        <div>&#12644;&#12644;&#12644;</div>
                      )}

                      {menuContent !== "vazio" && (
                        <Popover
                          content={menuContent}
                          trigger="click"
                          placement="bottom"
                        >
                          <div className="dots__menu">
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                          </div>
                        </Popover>
                      )}
                      {menuContent === "vazio" && (
                        <div>&#12644;&#12644;&#12644;</div>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
}

export default ListAvaliacao;
