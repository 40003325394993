import React, { useState, useEffect, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import ptLocale from "@fullcalendar/core/locales/pt";

import { AuthContext } from "../../../../shared/context/auth-context";
import { ClinicaContext } from "../../../../shared/context/clinica-context";
import { useHttpClient } from "../../../../shared/hooks/http-hook";

import { useLoading } from "../../../../shared/context/LoadingContext";
import TooltipViewOnly from "./componentsFullCallendar/TooltipViewOnly";

function DatatableViewOnly({ medicos }) {
  const { startLoading, stopLoading } = useLoading();

  const [events, setEvents] = useState([]);
  const [duracaoConsulta, setDuracaoConsulta] = useState(30);
  const [tooltipEvent, setTooltipEvent] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  const tooltipRef = useRef(null);

  const { sendRequest } = useHttpClient();

  const auth = useContext(AuthContext);
  const clinica = useContext(ClinicaContext);
  const medicoId = useParams().medicoId;

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (medicos) {
          let temp = medicos.filter((m) => m.id === medicoId);
          setDuracaoConsulta(temp[0].duracaoConsulta);
        } else {
          const response = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/users/${medicoId}`,
            "GET",
            null,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
          setDuracaoConsulta(response.user.duracaoConsulta);
        }

        startLoading();
        const formData = new FormData();
        formData.append("medicoId", medicoId);

        const [
          agendaResponse,
          compromissosResponse,
          agendamentosEmTransicaoResponse,
          consultasResponse,
        ] = await Promise.all([
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/agenda/${medicoId}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/compromissos/${medicoId}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/agendamentosemtransicao/${medicoId}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/consultas/agenda/${clinica.clinica._id}`,
            "PATCH",
            formData,
            { Authorization: "Bearer " + auth.token }
          ),
        ]);

        const agendaMarcacoes = agendaResponse.agenda.marcacoes || [];
        const compromissosMarcacoes =
          compromissosResponse.compromisso.marcacoes || [];
        const agendamentosEmTransicaoMarcacoes =
          agendamentosEmTransicaoResponse.agenda.marcacoes || [];
        const consultasMarcacoes = consultasResponse.consultas || [];

        const formattedEvents = [
          ...formatAppointments(agendaMarcacoes),
          ...formatCompromissos(compromissosMarcacoes),
          ...formatTransitionAppointments(agendamentosEmTransicaoMarcacoes),
          ...formatConsultas(consultasMarcacoes),
        ];

        setEvents(formattedEvents);
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    };

    fetchData();
  }, [sendRequest, auth.token, clinica.clinica._id, medicos, medicoId]);

  const formatAppointments = (appointments) => {
    return appointments.map((appointment) => ({
      id: appointment._id,
      title: ` ${appointment.cliente.nome.split(" ")[0]} ${
        appointment.cliente.nome.split(" ")[
          appointment.cliente.nome.split(" ").length - 1
        ]
      } - ${
        appointment.avaliacao
          ? "Avaliação"
          : appointment.categoriaTratamento?.categoria
      }`,
      start: new Date(appointment.data_inicio_execucao),
      end: new Date(appointment.data_fim_execucao),
      backgroundColor: appointment.avaliacao
        ? "#FFF8EE"
        : appointment.atendido
        ? "#F2FAF5"
        : appointment.faltou
        ? "#FFF3F6"
        : "#F4F7FF",
      borderColor: appointment.avaliacao
        ? "#FFCF2E"
        : appointment.atendido
        ? "#39B93A"
        : appointment.faltou
        ? "#D96C60"
        : "#0871EF",
      textColor: appointment.avaliacao
        ? "#FFCF2E"
        : appointment.atendido
        ? "#39B93A"
        : appointment.faltou
        ? "#D96C60"
        : "#0871EF",
      extendedProps: {
        clientId: appointment.cliente._id,
        procedureId: appointment.categoriaTratamento?._id,
        isEvaluation: appointment.avaliacao,
        isAttended: appointment.atendido,
        isMissed: appointment.faltou,
        inTransition: false,
        isCompromisso: false,
        planoId: appointment.planoTratamento,
        agendadoPor: appointment.agendadoPor?.name,
      },
    }));
  };

  const formatCompromissos = (compromissos) => {
    return compromissos.map((compromisso) => ({
      id: compromisso._id,
      title: " " + compromisso.compromisso,
      start: new Date(compromisso.data_inicio_execucao),
      end: new Date(compromisso.data_fim_execucao),
      backgroundColor: "#F3F3F3",
      borderColor: "#868A8F",
      textColor: "#868A8F",
      extendedProps: {
        isCompromisso: true,
        agendadoPor: compromisso.agendadoPor?.name,
      },
    }));
  };

  const formatTransitionAppointments = (appointments) => {
    return appointments.map((appointment) => ({
      id: appointment._id,
      title: ` ${appointment.cliente.nome.split(" ")[0]} ${
        appointment.cliente.nome.split(" ")[
          appointment.cliente.nome.split(" ").length - 1
        ]
      } - ${appointment.categoriaTratamento.categoria}`,
      start: new Date(appointment.data_inicio_execucao),
      end: new Date(appointment.data_fim_execucao),
      backgroundColor: "#F4EEFE",
      borderColor: "#724CB4",
      textColor: "#724CB4",
      extendedProps: {
        clientId: appointment.cliente._id,
        procedureId: appointment.categoriaTratamento._id,
        inTransition: true,
        agendadoPor: appointment.agendadoPor?.name,
      },
    }));
  };

  const formatConsultas = (conslts) => {
    return conslts.map((consulta) => ({
      id: consulta._id,
      title: ` ${consulta.cliente.nome.split(" ")[0]} ${
        consulta.cliente.nome.split(" ")[
          consulta.cliente.nome.split(" ").length - 1
        ]
      } - Consulta`,
      start: new Date(consulta.data_inicio),
      end: new Date(consulta.data_fim),
      backgroundColor: consulta.executada
        ? "#F2FAF5"
        : consulta.faltou
        ? "#FFF3F6"
        : "#D7CCC8",
      borderColor: consulta.executada
        ? "#39B93A"
        : consulta.faltou
        ? "#D96C60"
        : "#5D4037",
      textColor: consulta.executada
        ? "#39B93A"
        : consulta.faltou
        ? "#D96C60"
        : "#5D4037",
      extendedProps: {
        isConsulta: true,
        clientId: consulta.cliente._id,
        isAttended: consulta.executada,
        isMissed: consulta.faltou,
        agendadoPor: consulta.agendadoPor?.name,
      },
    }));
  };

  const handleEventClick = (info) => {
    const rect = info.el.getBoundingClientRect();
    setTooltipPosition({
      x: rect.left + window.scrollX,
      y: rect.bottom + window.scrollY,
    });
    setTooltipEvent(info.event);
  };

  const handleCloseTooltip = () => {
    setTooltipEvent(null);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        handleCloseTooltip();
      }
    };

    if (tooltipEvent) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [tooltipEvent]);

  return (
    <div className="datatable-container" style={{ backgroundColor: "white" }}>
      {events && (
        <FullCalendar
          plugins={[timeGridPlugin, interactionPlugin]}
          initialView="timeGridWeek"
          locale={ptLocale}
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "timeGridWeek,timeGridDay",
          }}
          buttonText={{
            today: "Esta Semana",
            week: "Semana",
            day: "Dia",
          }}
          height="auto"
          slotDuration={`00:${duracaoConsulta}:00`}
          slotLabelInterval={`00:${duracaoConsulta}`}
          slotLabelFormat={{
            hour: "numeric",
            minute: "2-digit",
            omitZeroMinute: false,
            meridiem: "short",
          }}
          slotMinTime="08:00:00"
          slotMaxTime="20:00:00"
          allDaySlot={false}
          events={events}
          eventContent={renderEventContent}
          eventClick={handleEventClick}
          hiddenDays={[0]}
          firstDay={1}
          editable={false}
        />
      )}
      {tooltipEvent && (
        <div
          ref={tooltipRef}
          style={{
            position: "absolute",
            left: `${tooltipPosition.x}px`,
            top: `${tooltipPosition.y}px`,
          }}
        >
          <TooltipViewOnly
            event={tooltipEvent}
            onClose={handleCloseTooltip}
            viewOnly={true}
          />
        </div>
      )}
    </div>
  );
}

function renderEventContent(eventInfo) {
  return (
    <>
      <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i>
    </>
  );
}

export default DatatableViewOnly;
