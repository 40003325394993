import React, { useContext } from "react";
import { AuthContext } from "../../context/auth-context";
import { useHttpClient } from "../../hooks/http-hook";
import { useLoading } from "../../context/LoadingContext";
import toast from "react-hot-toast";

import "./RoleSelector.scss";
const RoleSelector = () => {
  const auth = useContext(AuthContext);
  const { sendRequest } = useHttpClient();
  const { startLoading } = useLoading();

  const currentRoleId =
    auth.listaRoles?.find((r) => r.role === auth.role)?._id || "";

  const handleRoleChange = async (event) => {
    const newRoleId = event.target.value;
    startLoading();

    try {
      const formData = new FormData();
      formData.append("roleId", newRoleId);
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/users/updaterole/${auth.userId}`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );
      //   console.log("responseData", responseData);

      const roleSelecionado = auth.listaRoles.find(
        (r) => r._id === newRoleId
      ).role;
      //   console.log("roleSelecionado", roleSelecionado);

      // Processar usuariosAssociados baseado na role
      let usuariosAssociados = null;
      let secretariasDoMedico = null;
      let assistentesDoMedico = null;

      if (
        roleSelecionado === "Secretária(o)" ||
        roleSelecionado === "Assistente"
      ) {
        usuariosAssociados = responseData.medicos.map((m) => {
          return {
            id: m._id,
            nome: m.name,
            cor: m.cor,
            duracaoConsulta: m.duracaoConsulta,
          };
        });
      }

      if (
        roleSelecionado === "Dentista" ||
        roleSelecionado === "Médico/Administrador"
      ) {
        usuariosAssociados = responseData.secretarias.map((s) => {
          return { id: s._id, nome: s.name };
        });
      }

      if (roleSelecionado === "Assistente") {
        secretariasDoMedico = responseData.medicos.map((m) => {
          return { medicoId: m._id, secretarias: m.secretarias };
        });
      }

      if (roleSelecionado === "Secretária(o)") {
        assistentesDoMedico = responseData.medicos.map((m) => {
          return { medicoId: m._id, assistentes: m.assistentes };
        });
      }

      //   console.log("usuariosAssociados", usuariosAssociados);
      //   console.log("secretariasDoMedico", secretariasDoMedico);
      //   console.log("assistentesDoMedico", assistentesDoMedico);
      // Atualizar localStorage com todos os dados necessários
      const storedData = JSON.parse(localStorage.getItem("userData"));
      const updatedData = {
        ...storedData,
        role: roleSelecionado,
        listaRoles: responseData.listaRoles,
        usuariosAssociados,
        secretariasDoMedico,
        assistentesDoMedico,
      };
      localStorage.setItem("userData", JSON.stringify(updatedData));

      window.location.reload();
    } catch (err) {
      toast.error("Erro ao alterar função");
      console.error(err);
    }
  };

  return (
    <div className="roleSelectorContainer">
      <label
        htmlFor="roleSelector-select"
        className="roleSelectorContainer__label"
      >
        Função:
      </label>
      <select
        id="roleSelector-select"
        value={currentRoleId}
        onChange={handleRoleChange}
        className="roleSelectorContainer__select"
      >
        {auth.listaRoles?.map((role) => (
          <option key={role._id} value={role._id}>
            {role.role}
          </option>
        ))}
      </select>
    </div>
  );
};

export default RoleSelector;
