import React, { useState, useEffect, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import "./Single.scss";
import Navbar from "../../../shared/components/navbar/Navbar";
import Sidebar from "../../../shared/components/sidebar/Sidebar";

import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from "../../../shared/context/auth-context";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";

import toast from "react-hot-toast";
import { useLoading } from "../../../shared/context/LoadingContext";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import TextField from "@mui/material/TextField";
import moment from "moment";

function SingleUser() {
  const [userData, setUserData] = useState();
  const antigaPalavraPassRef = useRef(null);
  const novaPalavraPassRef = useRef(null);

  const nomeRef = useRef(null);
  const emailRef = useRef(null);
  const duracaoAgendamentoRef = useRef(null);
  const [horarioAtendimento, setHorarioAtendimento] = useState({
    start: moment().startOf("day").add(8, "hours"),
    end: moment().startOf("day").add(20, "hours"),
  });

  const { sendRequest } = useHttpClient();
  const { startLoading, stopLoading } = useLoading();

  const userId = useParams().userId;
  const auth = useContext(AuthContext);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/users/${userId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        if (responseData.user.horarioAtendimento) {
          setHorarioAtendimento({
            start: moment(responseData.user.horarioAtendimento.start, "HH:mm"),
            end: moment(responseData.user.horarioAtendimento.end, "HH:mm"),
          });
        }
        setUserData(responseData.user);
      } catch (err) {}
    };
    fetchUser();
  }, [auth.token, sendRequest, userId]);

  const alterarPalavraPassHandler = async () => {
    startLoading();
    try {
      let mensagemErro = "";

      if (
        novaPalavraPassRef.current.value === antigaPalavraPassRef.current.value
      ) {
        mensagemErro = "A palavra passe antiga e a atual não podem ser iguais";
      } else if (
        novaPalavraPassRef.current.value.trim() === "" ||
        antigaPalavraPassRef.current.value.trim() === ""
      ) {
        mensagemErro = "Os campos não podem ser vazios";
      } else if (novaPalavraPassRef.current.value.length < 7) {
        mensagemErro = "A palavra passe tem que ter pelo menos 7 caracters";
      }

      if (!mensagemErro) {
        try {
          const formData = new FormData();
          formData.append("newPass", novaPalavraPassRef.current.value);
          formData.append("oldPass", antigaPalavraPassRef.current.value);

          const responseData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/users/atualizarpalavrapasse/${userId}`,
            "PATCH",
            formData,
            {
              Authorization: "Bearer " + auth.token,
            }
          );

          if (responseData.type === "erro") {
            toast.error(responseData.msg);
          } else {
            toast.success(responseData.msg);
          }
        } catch (err) {
          console.err("err", err);
        }
      } else {
        toast.error(mensagemErro);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  const guardarDadosUtilizadorHandler = async () => {
    startLoading();
    try {
      let mensagemErro = "";

      const reEmail = /^[\w.-]+@[\w.-]+\.[a-z]{2,4}$/;
      // const reNome = /^[a-zA-Z\s-']+$/;

      if (
        nomeRef.current.value.trim() === "" ||
        emailRef.current.value.trim() === ""
      ) {
        mensagemErro = "Os campos não podem estar vazios";
      } else if (!reEmail.test(emailRef.current.value)) {
        mensagemErro = "email com formato incorreto";
      }
      // else if (!reNome.test(nomeRef.current.value)) {
      //   mensagemErro = "nome com formato incorreto";
      // }

      if (!mensagemErro) {
        try {
          const formData = new FormData();
          formData.append("email", emailRef.current.value);
          formData.append("name", nomeRef.current.value);

          await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/users/${userId}`,
            "PATCH",
            formData,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
          toast.success("Dados atualizados com sucesso");
        } catch (err) {
          console.error("err", err);
        }
      } else {
        toast.error(mensagemErro);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  const alterarDuracaoAgendamentoHandler = async () => {
    const novaDuracao = duracaoAgendamentoRef.current.value;
    if (!novaDuracao || novaDuracao.trim() === "") {
      toast.error("Introduza um valor para a nova duração de agendamento.");
    } else {
      startLoading();
      try {
        const formData = new FormData();
        formData.append("duracao", novaDuracao);
        formData.append("utilizador", auth.userId);
        await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/users/atualizarduracaoagendamento/${userId}`,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        toast.success("Duração de agendamento atualizada com sucesso!");
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    }
  };

  const validateHorario = () => {
    let tempErrors = {};

    if (!horarioAtendimento.start.isValid()) {
      tempErrors.start = "Horário de início inválido";
    }

    if (!horarioAtendimento.end.isValid()) {
      tempErrors.end = "Horário de fim inválido";
    }

    if (
      horarioAtendimento.start.isValid() &&
      horarioAtendimento.end.isValid()
    ) {
      if (horarioAtendimento.start.isSameOrAfter(horarioAtendimento.end)) {
        tempErrors.end = "O horário de fim deve ser após o horário de início";
      }
    }
    return Object.keys(tempErrors).length === 0;
  };

  const alterarHorarioAtendimentoHandler = async () => {
    if (!validateHorario()) {
      toast.error("Introduza valores corretos para horários de atendimento.");
    } else {
      startLoading();
      try {
        const formData = new FormData();
        const formattedHorarioAtendimento = JSON.stringify({
          start: moment(horarioAtendimento.start).format("HH:mm:ss"),
          end: moment(horarioAtendimento.end).format("HH:mm:ss"),
        });

        formData.append("horarioAtendimento", formattedHorarioAtendimento);
        formData.append("utilizador", auth.userId);
        await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/users/atualizarhorarioatendimento/${userId}`,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        toast.success("Horário de atendimento atualizado com sucesso!");
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    }
  };

  const handleStartChange = (newValue) => {
    setHorarioAtendimento((prev) => ({
      ...prev,
      start: moment(newValue),
    }));
  };

  const handleEndChange = (newValue) => {
    setHorarioAtendimento((prev) => ({
      ...prev,
      end: moment(newValue),
    }));
  };

  return (
    <>
      {userData && (
        <div className="single">
          <Sidebar />
          <div className="singleContainer">
            <Navbar title={auth.nome} icon={PersonOutlineOutlinedIcon} />

            {/* Container info */}
            {userData && (
              <div className="single-user__container">
                <div className="single-user__container__info__container caixa">
                  <span className="single-user__container__title">
                    Configurações de perfil
                  </span>
                  <span className="single-user__container__subtitle">
                    Esses são detalhes pessoais, visiveis ao público
                  </span>

                  <div className="single-user__container__pass-reset__container__input-container">
                    <span>Nome Completo</span>
                    <input
                      type="text"
                      ref={nomeRef}
                      placeholder={userData.name}
                    />
                  </div>
                  <div className="single-user__container__pass-reset__container__input-container">
                    <span>Email</span>
                    <input
                      type="email"
                      ref={emailRef}
                      placeholder={userData.email}
                    />
                  </div>

                  <button
                    className="single-user__container__btn"
                    onClick={guardarDadosUtilizadorHandler}
                  >
                    Guardar
                  </button>
                </div>

                {/* Container Palavra pass */}
                <div className="single-user__container__pass-reset__container caixa">
                  <span className="single-user__container__title">
                    Atualizar Palavra Passe
                  </span>
                  <span className="single-user__container__subtitle">
                    Introduza a tua palavra passe atual para efetuar a
                    atualização
                  </span>
                  <div className="single-user__container__pass-reset__container__input-container">
                    <span>Palavra Passe Atual</span>
                    <input type="password" ref={antigaPalavraPassRef} />
                  </div>
                  <div className="single-user__container__pass-reset__container__input-container">
                    <span>Nova Palavra Passe</span>
                    <input type="password" ref={novaPalavraPassRef} />
                  </div>

                  <button
                    className="single-user__container__btn"
                    onClick={alterarPalavraPassHandler}
                  >
                    Atualizar Palavra Passe
                  </button>
                </div>

                {(auth.role === "Médico/Administrador" ||
                  auth.role === "Dentista") && (
                  <div className="caixa" style={{ flexColumn: "1/2" }}>
                    <div className="single-user__container__pass-reset__container__input-container">
                      <span>Duração de agendamento</span>
                      <input
                        type="number"
                        ref={duracaoAgendamentoRef}
                        defaultValue={30}
                        min={5}
                        max={480}
                      />
                    </div>
                    <button
                      className="single-user__container__btn"
                      onClick={alterarDuracaoAgendamentoHandler}
                    >
                      Atualizar Duração
                    </button>
                  </div>
                )}

                {(auth.role === "Médico/Administrador" ||
                  auth.role === "Dentista") && (
                  <div className="caixa" style={{ flexColumn: "1/2" }}>
                    <div className="single-user__container__pass-reset__container__input-container">
                      <span>Horário de Atendimento</span>

                      <div className="perfil__horarioAtendimento__container">
                        <span>Horário Incial</span>
                        <span>Horário Final</span>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DesktopTimePicker
                            value={horarioAtendimento.start.toDate()}
                            onChange={handleStartChange}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DesktopTimePicker
                            value={horarioAtendimento.end.toDate()}
                            onChange={handleEndChange}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                    <button
                      className="single-user__container__btn"
                      onClick={alterarHorarioAtendimentoHandler}
                    >
                      Atualizar Horário
                    </button>
                  </div>
                )}
              </div>
            )}

            <div className="alertaSingleUserContainer caixa">
              Estas alterações só terão efeito após o reinicio da sessão
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SingleUser;
