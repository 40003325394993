import React from "react";
import { useState, useEffect, useCallback } from "react";
import ListPlanoTratamento from "../Lists/ListPlanoTratamento/ListPlanoTratamento";
import moment from "moment/moment";
import { useContext } from "react";
import { AuthContext } from "../../../../../shared/context/auth-context";
import ModalExecutar from "./components/ModalExecutar";
import "./PlanosTratamentoComponent.scss";
import toast from "react-hot-toast";
// import ModalHistorico from "./components/ModalHistorico";
import { useLoading } from "../../../../../shared/context/LoadingContext";
import ModalVerPlanoTratamentoInterrompido from "./components/ModalVerPlanoTratamentoInterrompido";
import ModalVerPlano from "./components/ModalVerPlano";

function PlanosTratamentoComponent({ clienteId, sendRequest, token }) {
  const { startLoading, stopLoading } = useLoading();
  const [planosTratamento, setPlanosTratamento] = useState([]);
  const [planosTratamentoPermanente, setPlanosTratamentoPermanente] = useState(
    []
  );

  const auth = useContext(AuthContext);
  //Modal Code
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [isFinalizados, setIsFinalizados] = useState(false);
  const [openModalVerPlano, setOpenModalVerPlano] = useState("");

  const handleOpenModal = useCallback(
    (id) => {
      const planoTratamentoFiltrado = planosTratamento.filter(
        (pl) => pl.id === id
      )[0];
      setModalData(planoTratamentoFiltrado);
      setOpenModal(true);
    },
    [planosTratamento]
  );

  const handleCloseModal = () => {
    setOpenModal(false);
    setModalData();
    setOpenModalVerPlano("");
  };

  const updatePlanosTratamento = (PlanoModificado) => {
    setPlanosTratamento((current) =>
      current.map((pl) =>
        pl.id === PlanoModificado._id ? PlanoModificado : pl
      )
    );
  };
  ///////////////////////////////////////

  useEffect(() => {
    const fetchPlanosTratamentoByCliente = async () => {
      startLoading();
      try {
        let responseData;
        try {
          responseData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/planostratamento/cliente/${clienteId}`,
            "GET",
            null,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
        }

        const temp = responseData.planosTratamento.sort((a, b) => {
          return new Date(b.data_inicio) - new Date(a.data_inicio);
        });

        temp.forEach((plano) => {
          if (plano.data_inicio) {
            plano.data_inicio = moment(plano.data_inicio).format("DD-MM-YYYY");
          }
          if (plano.data_fim) {
            plano.data_fim = moment(plano.data_fim).format("DD-MM-YYYY");
          }
        });

        //Filtrando por medico conforme o usuario
        //DEPOIS VERIFICAR OUTROS USUARIOS
        let tempPlanosTratamento = [];

        // if (auth.role === "Médico/Administrador" || auth.role === "Dentista") {
        //   tempPlanosTratamento = responseData.planosTratamento.filter(
        //     (pl) => pl.medico_responsavel.id === auth.userId
        //   );
        // } else
        if (auth.role === "Secretária(o)" || auth.role === "Assistente") {
          responseData.planosTratamento.forEach((pl) => {
            auth.usuariosAssociados.forEach((medico) => {
              if (medico.id === pl.medico_responsavel.id)
                tempPlanosTratamento.push(pl);
            });
          });
        } else {
          tempPlanosTratamento = [...responseData.planosTratamento];
        }

        setPlanosTratamento(
          tempPlanosTratamento.filter((plano) => plano.plano_completo === false)
        );
        setPlanosTratamentoPermanente(tempPlanosTratamento);
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    };
    fetchPlanosTratamentoByCliente();
  }, [sendRequest, clienteId, auth]);

  const handleDesativar = async (id) => {
    startLoading();
    try {
      //Encontrar a contaCorrente associada ao orcamento que gerou esse plano
      const planoTratamentoFiltrado = planosTratamento.filter(
        (plano) => plano.id === id
      );

      let contaDoOrcamento;
      try {
        contaDoOrcamento = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/contascorrentes/cliente/${clienteId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
      } catch (err) {
        console.error("err", err);
      }

      const newContas = contaDoOrcamento.contaCorrente.contas.filter(
        (conta) => conta.orcamento === planoTratamentoFiltrado[0].orcamento._id
      );
      newContas[0].aprovado = false;

      const formData0 = new FormData();
      formData0.append(
        "contas",
        JSON.stringify(contaDoOrcamento.contaCorrente.contas)
      );

      try {
        await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/contascorrentes/${contaDoOrcamento.contaCorrente.id}`,
          "PATCH",
          formData0,
          {
            Authorization: "Bearer " + token,
          }
        );
      } catch (err) {
        console.error("err", err);
      }

      const formDataPlano = new FormData();
      formDataPlano.append("utilizador", auth.userId);
      try {
        await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/planostratamento/toggleativo/${id}`,
          "PATCH",
          formDataPlano,
          {
            Authorization: "Bearer " + token,
          }
        );
      } catch (err) {
        console.error("err", err);
      }

      const tempPlanosFilter = planosTratamento.filter((pl) => {
        return pl.plano_completo === false;
      });

      const formData2 = new FormData();
      formData2.append("transformado", false);
      formData2.append("utilizador", auth.userId);
      try {
        await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/orcamentos/transformar/${tempPlanosFilter[0].orcamento._id}`,
          "PATCH",
          formData2,
          {
            Authorization: "Bearer " + token,
          }
        );
      } catch (err) {
        console.error("err", err);
      }

      setPlanosTratamento((currentPlanosTratamento) => {
        return currentPlanosTratamento.filter((el) => {
          return el.id !== id;
        });
      });

      setPlanosTratamentoPermanente((currentPlanosTratamento) => {
        return currentPlanosTratamento.filter((el) => {
          return el.id !== id;
        });
      });

      toast.success("Desativação do plano de tratamento efetuada com sucesso");
    } catch (err) {
    } finally {
      stopLoading();
    }
  };

  const handleChangeFinalizados = (e) => {
    if (e.target.checked) {
      setPlanosTratamento(planosTratamentoPermanente);
    } else {
      setPlanosTratamento(
        planosTratamentoPermanente.filter(
          (plano) => plano.plano_completo === false
        )
      );
    }
    setIsFinalizados(e.target.checked);
  };

  const handleOpenModalVerPlano = (id, tipo) => {
    try {
      const planoTratamentoFiltrado = planosTratamento.filter(
        (plano) => plano.id === id
      );

      setModalData(planoTratamentoFiltrado[0]);
      setOpenModalVerPlano(tipo);
    } catch (err) {
      console.error("err", err);
    }
  };

  return (
    <>
      {planosTratamentoPermanente.length !== 0 && (
        <div
          className="checkbox-wrapper-51"
          id="planoTratamento__mostrar-terminados"
        >
          <input
            type="checkbox"
            id="mostrarFinalizados"
            onChange={handleChangeFinalizados}
            checked={isFinalizados}
          />
          <label htmlFor="mostrarFinalizados" className="toggle">
            <span>
              <svg width="10px" height="10px" viewBox="0 0 10 10">
                <path d="M5,1 L5,1 C2.790861,1 1,2.790861 1,5 L1,5 C1,7.209139 2.790861,9 5,9 L5,9 C7.209139,9 9,7.209139 9,5 L9,5 C9,2.790861 7.209139,1 5,1 L5,9 L5,1 Z"></path>
              </svg>
            </span>
          </label>

          <span className="checkbox-text">Mostrar Finalizados</span>
        </div>
      )}

      {planosTratamento.length !== 0 && (
        <ListPlanoTratamento
          data={planosTratamento}
          handleOpenModal={handleOpenModal}
          handleDesativar={handleDesativar}
          handleOpenModalVerPlano={handleOpenModalVerPlano}
          auth={auth}
        />
      )}

      {planosTratamento.length === 0 && (
        <div className="no_data_div">
          Nenhum Plano de Tratamento encontrado.
        </div>
      )}

      {openModal && (
        <ModalExecutar
          plano={modalData}
          openModal={true}
          handleCloseModal={handleCloseModal}
          sendRequest={sendRequest}
          auth={auth}
          updatePlanosTratamento={updatePlanosTratamento}
        />
      )}

      {openModalVerPlano === "interrompido" && (
        <ModalVerPlanoTratamentoInterrompido
          openModal={openModalVerPlano === "interrompido"}
          handleCloseModal={handleCloseModal}
          plano={modalData}
        />
      )}

      {openModalVerPlano === "normal" && (
        <ModalVerPlano
          openModal={openModalVerPlano === "normal"}
          handleCloseModal={handleCloseModal}
          plano={modalData}
        />
      )}
    </>
  );
}

export default PlanosTratamentoComponent;
