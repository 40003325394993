import { useEffect, useState, useContext } from "react";
import { Button } from "@mui/material";
import InputText from "../../../shared/components/inputs/InputText/InputText";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "../categoriasFinanceiro/CategoriasFinanceiro.scss";
import { AuthContext } from "../../../shared/context/auth-context";
import { Popconfirm } from "antd";
import ErrorIcon from "@mui/icons-material/Error";
import toast from "react-hot-toast";
import { useLoading } from "../../../shared/context/LoadingContext";

function CategoriasTratamento({ sendRequest, clinicaId }) {
  const { startLoading, stopLoading } = useLoading();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [nomeCategoria, setNomeCategoria] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [idCategoria, setIdCategoria] = useState(null);
  const [categorias, setCategorias] = useState([]);
  const [mensagemDeErro, setMensagemDeErro] = useState("");
  const auth = useContext(AuthContext);

  useEffect(() => {
    const fetchCategorias = async () => {
      startLoading();
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/categoriastratamento/clinica/${clinicaId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setCategorias(responseData.categoriasTratamento);
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    };
    fetchCategorias();
  }, [auth.token, clinicaId, sendRequest]);

  const categoriaChangeHandler = (valor) => {
    setNomeCategoria(valor);
  };

  const guardarCategoria = async () => {
    startLoading();
    try {
      const formData = new FormData();
      formData.append("categoria", nomeCategoria);
      formData.append("clinica", clinicaId);
      formData.append("criadoPor", auth.userId);

      const categoriaCriada = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/categoriastratamento/`,
        "POST",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      setCategorias((current) => [
        ...current,
        categoriaCriada.categoriaTratamento,
      ]);

      setNomeCategoria("");
      setIdCategoria(null);
      setOpenModal(false);

      toast.success("Categoria criada com sucesso");
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
    setIsSubmitting(false);
  };

  const editarCategoria = async (id) => {
    startLoading();
    try {
      const formData = new FormData();
      formData.append("categoria", nomeCategoria);
      formData.append("clinica", clinicaId);
      formData.append("atualizadoPor", auth.userId);

      await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/categoriastratamento/${id}`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );
      const categoriaCopy = [...categorias];

      const categoriasModificadas = categoriaCopy.map((cat) => {
        if (cat.id === id) {
          return {
            id: id,
            categoria: nomeCategoria,
          };
        } else {
          return cat;
        }
      });

      setCategorias([...categoriasModificadas]);

      setNomeCategoria("");
      setIdCategoria(null);
      setOpenModal(false);

      toast.success("Categoria modificada com sucesso");
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
    setIsSubmitting(false);
    setIdCategoria(null);
  };

  const preEditarCategoria = async (id) => {
    setIdCategoria(id);
    setNomeCategoria(categorias.filter((cat) => cat.id === id)[0].categoria);
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setMensagemDeErro("");
    setIdCategoria(null);
    setNomeCategoria("");
    setOpenModal(false);
  };

  const desativarCategoria = async (id) => {
    startLoading();
    try {
      const formDataDesativar = new FormData();
      formDataDesativar.append("utilizador", auth.userId);
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/categoriastratamento/toggleativo/${id}`,
        "PATCH",
        formDataDesativar,
        {
          Authorization: "Bearer " + auth.token,
        }
      );
      setCategorias((current) => current.filter((cat) => cat.id !== id));
      toast.success("Categoria desativada com sucesso");
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  const beforeSubmit = (idCategoria) => {
    let filteredCategoria;
    let duplicidadeCategoria = false;

    if (idCategoria) {
      filteredCategoria = categorias.find((c) => c.id === idCategoria);

      duplicidadeCategoria = categorias.filter(
        (c) => c.categoria.toLowerCase() === nomeCategoria.trim().toLowerCase()
      );
    }

    //Se o nome permanecer igual, não modificamos nada
    if (
      idCategoria &&
      filteredCategoria?.categoria?.toLowerCase() ===
        nomeCategoria.trim().toLowerCase()
    ) {
      setNomeCategoria("");
      setIdCategoria(null);
      setOpenModal(false);
    } else {
      let haErro = false;
      if (duplicidadeCategoria.length > 0) {
        setMensagemDeErro("Já existe uma categoria com esse nome.");
        haErro = true;
      } else if (!nomeCategoria || nomeCategoria.trim() === "") {
        setMensagemDeErro("Introduza um nome para a categoria.");
        haErro = true;
      } else {
        setMensagemDeErro("");
      }
      if (!isSubmitting && !haErro) {
        setIsSubmitting(true);
        if (idCategoria) {
          editarCategoria(idCategoria);
        } else {
          guardarCategoria();
        }
      }
    }
  };

  return (
    <>
      <div className="categoriasGeral__container">
        {(auth.role === "Médico/Administrador" ||
          auth.role === "Administrador Não Médico") && (
          <div className="categoriasGeral__container__btn-adicionar">
            <Button
              variant="contained"
              color="success"
              onClick={(e) => setOpenModal(true)}
            >
              Nova Categoria
            </Button>
          </div>
        )}

        {categorias &&
          categorias.map((categoria, index) => (
            <div
              className="categoriasFinanceiro__container__categoria"
              key={index}
            >
              <div className="categoriasFinanceiro__container__categoria--1">
                {categoria.categoria}
              </div>
              <div className="categoriasFinanceiro__container__categoria--2">
                {(auth.role === "Médico/Administrador" ||
                  auth.role === "Administrador Não Médico") &&
                  categoria.categoria !== "Outra" && (
                    <Popconfirm
                      title={`Desativar Categoria`}
                      description={`Pretende desativar essa categoria?`}
                      icon={<ErrorIcon style={{ color: "red" }} />}
                      okText="Sim"
                      cancelText="Não"
                      onConfirm={desativarCategoria.bind(null, categoria.id)}
                      className="popConfirm--biggerPadding"
                    >
                      <span>Desativar</span>
                    </Popconfirm>
                  )}
                {(auth.role === "Médico/Administrador" ||
                  auth.role === "Administrador Não Médico") &&
                  categoria.categoria !== "Outra" && (
                    <span onClick={preEditarCategoria.bind(null, categoria.id)}>
                      Editar
                    </span>
                  )}
              </div>
            </div>
          ))}

        <Modal
          open={openModal}
          onClose={handleModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            className="fluxo-atendimento__modal__box adicionar-categoria__modal"
            id="modal__adicionar-categoria"
          >
            <h1 className="fluxo-atendimento__modal__titulo">Categoria</h1>
            <div className="adicionar-categoria__modal__container">
              <InputText
                className="adicionar-categoria__modal__container--span2"
                label="Nome da Categoria *"
                initialValue={nomeCategoria}
                handleChange={categoriaChangeHandler}
              />
            </div>
            {mensagemDeErro && (
              <div className="erroContainer__categoria-caixa">
                {mensagemDeErro}
              </div>
            )}

            <div className="fluxo-atendimento__modal__bottom_btns">
              <span
                className="cancel-btn"
                onClick={handleModalClose}
                style={{ display: "block" }}
              >
                Fechar
              </span>
              <span
                className="blue-button"
                onClick={
                  idCategoria
                    ? beforeSubmit.bind(null, idCategoria)
                    : beforeSubmit.bind(null, null)
                }
              >
                {idCategoria
                  ? isSubmitting
                    ? "Editando"
                    : "Editar"
                  : isSubmitting
                  ? "Guardando"
                  : "Guardar"}
              </span>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
}

export default CategoriasTratamento;
