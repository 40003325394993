import { useState, useEffect } from "react";
import moment from "moment";
import InputNumber from "../../../../../shared/components/inputs/InputNumber/InputNumber";
import InputText from "../../../../../shared/components/inputs/InputText/InputText";
import InputSelect from "../../../../../shared/components/inputs/InputSelect/InputSelect";
import { Link } from "react-router-dom";
//MUI
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { useLoading } from "../../../../../shared/context/LoadingContext";
import toast from "react-hot-toast";
import "./ModalDespesa.scss";

function ModalEditarDespesa({
  openModalEditarDespesa,
  handleCloseModalEditarDespesa,
  sendRequest,
  clinicaId,
  auth,
  despesaId,
  updateDespesas,
}) {
  const { startLoading, stopLoading } = useLoading();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openModal, setOpenModal] = useState(openModalEditarDespesa);
  const [descricao, setDescricao] = useState("");
  const [valor, setValor] = useState(0);
  const [observacao, setObservacao] = useState("");
  const [categoria, setCategoria] = useState();
  const [categorias, setCategorias] = useState([]);
  const [categoriaAnteriroExtenso, setCategoriaAnteriroExtenso] = useState("");
  const [caixa, setCaixa] = useState();
  const [caixaAnteriroExtenso, setCaixaAnteriroExtenso] = useState("");
  const [caixas, setCaixas] = useState([]);
  const [despesa, setDespesa] = useState([]);
  const [dataLimitePagamento, setDataLimitePagamento] = useState(moment());
  const [comprovativo, setComprovativo] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const [despesaPaga, setDespesaPaga] = useState(false);
  const [dataPagamento, setDataPagamento] = useState(moment());

  const formasPagamento = [
    "Dinheiro",
    "Cheque",
    "Cheque Visado",
    "Transferência de crédito",
    "Internet banking",
    "Cartão do banco",
    "Débito direto da conta bancária",
    "Cartão de crédito",
    "Cartão de débito",
  ];

  const [metodoPagamento, setMetodoPagamento] = useState(formasPagamento[0]);

  useEffect(() => {
    const fetchData = async () => {
      startLoading();
      try {
        const [categoriasResponse, caixasResponse, despesaResponse] =
          await Promise.all([
            sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/categoriasfinanceiro/clinica/despesa/${clinicaId}`,
              "GET",
              null,
              { Authorization: "Bearer " + auth.token }
            ),
            sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/caixas/clinica/${clinicaId}`,
              "GET",
              null,
              { Authorization: "Bearer " + auth.token }
            ),
            sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/despesas/${despesaId}`,
              "GET",
              null,
              { Authorization: "Bearer " + auth.token }
            ),
          ]);

        // Process categorias
        const categoriasFormatted = categoriasResponse.categoriasFinanceiro.map(
          (cat) => ({
            id: cat.id,
            value: cat.categoria,
          })
        );
        setCategoriaAnteriroExtenso(despesaResponse.categoria.categoria);

        setCategorias(categoriasFormatted);
        // Process caixas
        const caixasFormatted = caixasResponse.caixas.map((cai) => ({
          id: cai.id,
          value: cai.caixa,
        }));

        setCaixaAnteriroExtenso(despesaResponse.caixa.caixa);
        setCaixas(caixasFormatted);

        // Process despesa
        const { despesa } = despesaResponse;
        setDespesa(despesa);
        setCategoria(despesa.categoria.id);
        setCaixa(despesa.caixa.id);
        setValor(despesa.valor);
        setDataLimitePagamento(moment(despesa.dataLimitePagamento));
        setDescricao(despesa.descricao);
        setObservacao(despesa.observacao || "");
        setDespesaPaga(despesa.despesaPaga);
        setDataPagamento(moment(despesa.dataPagamento));
        setMetodoPagamento(despesa.metodoPagamento);
        setComprovativo(despesa.comprovativo);
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    };

    fetchData();
  }, [clinicaId, despesaId, sendRequest, auth.token]);

  useEffect(() => {
    setOpenModal(openModalEditarDespesa);
  }, [openModalEditarDespesa]);

  const descricaoChangeHandler = (value) => {
    setDescricao(value);
  };

  const valorChangeHandler = (value) => {
    setValor(value);
  };

  const metodoPagamentoChangeHandler = (valor) => {
    setMetodoPagamento(valor);
  };

  const editarDespesaHandler = async () => {
    startLoading();
    try {
      const formData = new FormData();
      formData.append("observacoes", observacao);
      formData.append("descricao", descricao);
      formData.append("valor", valor);
      formData.append("dataLimitePagamento", dataLimitePagamento);
      formData.append("categoria", categoria);
      formData.append("caixa", caixa);
      formData.append(
        "categoriaExtenso",
        categorias.find((cat) => cat.id === categoria).value
      );
      formData.append(
        "caixaExtenso",
        caixas.find((cx) => cx.id === caixa).value
      );
      formData.append("categoriaAnteriroExtenso", categoriaAnteriroExtenso);
      formData.append("caixaAnteriroExtenso", caixaAnteriroExtenso);
      formData.append("atualizadoPor", auth.userId);

      if (comprovativo) {
        formData.append("comprovativo", comprovativo);
      }

      if (despesaPaga) {
        formData.append("dataPagamento", dataPagamento);
        formData.append("metodoPagamento", metodoPagamento);
        formData.append("despesaPaga", true);
      } else {
        formData.append("despesaPaga", false);
      }
      //LEMBRA DE ADICIONAR COMPROVATIVO MAIS TARDE

      const despesaAtualizada = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/despesas/${despesaId}`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      updateDespesas(despesaAtualizada.despesa, "atualizar");
      toast.success("Despesa atualizada com sucesso");
    } catch (err) {
      console.error("err", err);
    } finally {
      setIsSubmitting(false);
      stopLoading();
      handleCloseModalEditarDespesa();
    }
  };

  const preGuardarDespesaHandler = () => {
    if (valor === 0) {
      toast.error("O valor da despesa não pode ser 0");
    } else if (!descricao || descricao.trim() === "") {
      toast.error("Introduza a descrição");
    } else if (!dataLimitePagamento) {
      toast.error("Introduza a data de limite de pagamento");
    } else if (
      !moment.isMoment(dataLimitePagamento) ||
      !dataLimitePagamento.isValid()
    ) {
      toast.error("Introduza a data de limite de pagamento corretamente");
    } else if (despesaPaga && !dataPagamento) {
      toast.error("Introduza a data de pagamento");
    } else if (
      despesaPaga &&
      (!moment.isMoment(dataPagamento) || !dataPagamento.isValid())
    ) {
      toast.error("Introduza a data de pagamento corretamente");
    } else if (!isSubmitting) {
      setIsSubmitting(true);
      editarDespesaHandler();
    }
  };

  const handleFileChange = (event) => {
    // Check file size (7MB limit)
    const maxSizeInBytes = 7 * 1024 * 1024; // 3MB in bytes
    const file = event.target.files[0];
    // Check file extension
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf)$/i;
    if (!allowedExtensions.test(file.name)) {
      setErrorMessage(`O ficheiro "${file.name}" tem uma extensão inválida.`);
    } else if (file.size > maxSizeInBytes) {
      setErrorMessage(
        `O ficheiro "${file.name}" excede o tamanho máximo permitido (7MB).`
      );
    } else {
      setErrorMessage("");
      setComprovativo(file);
    }
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleCloseModalEditarDespesa}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="fluxo-atendimento__modal__box adicionar-despesa__modal"
          id="modal__adicionar-despesa"
        >
          <h1 className="fluxo-atendimento__modal__titulo">Editar despesa</h1>
          {despesa && (
            <div className="adicionar-despesa__modal__container">
              <InputText
                className="adicionar-despesa__modal__container--span2"
                label="Descrição *"
                initialValue={descricao}
                handleChange={descricaoChangeHandler}
              />
              <InputNumber
                className="adicionar-despesa__modal__container--span1"
                label="Valor *"
                initialValue={valor}
                handleChange={valorChangeHandler}
              />
              <LocalizationProvider
                dateAdapter={AdapterMoment}
                className="adicionar-despesa__modal__container--span1"
              >
                <DesktopDatePicker
                  className="valorDataCaixa__fields--field muiDatePicker"
                  label="Data de vencimento*"
                  inputFormat="DD-MM-YYYY"
                  value={dataLimitePagamento}
                  onChange={(value) => setDataLimitePagamento(value)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              {categorias && categoria && (
                <div className="customInputContainer adicionar-despesa__modal__container--span2">
                  <select
                    onChange={(e) => setCategoria(e.target.value)}
                    className="customInputSelect"
                    value={categoria}
                  >
                    {categorias &&
                      categoria &&
                      categorias.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.value}
                        </option>
                      ))}
                  </select>
                  <label className="customInputLabelSelect">Categoria</label>
                  <Link to={`../../configuracoes/categoriasFinanceiro`}>
                    <ControlPointIcon className="customInputPlusSign" />
                  </Link>
                </div>
              )}
              {caixas && caixa && (
                <div className="customInputContainer adicionar-despesa__modal__container--span2">
                  <select
                    onChange={(e) => setCaixa(e.target.value)}
                    className="customInputSelect"
                    value={caixa}
                  >
                    {caixas &&
                      caixa &&
                      caixas.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.value}
                        </option>
                      ))}
                  </select>
                  <label className="customInputLabelSelect">Caixa</label>
                  <Link to={`../../configuracoes/caixas`}>
                    <ControlPointIcon className="customInputPlusSign" />
                  </Link>
                </div>
              )}
              <textarea
                cols="30"
                rows="7"
                value={observacao}
                onChange={(e) => setObservacao(e.target.value)}
                className="adicionar-despesa__modal__container__textarea"
              ></textarea>
            </div>
          )}
          <div className="adicionar-despesa__modal__container__checkbox-pago__container">
            <input
              type="checkbox"
              name=""
              id="ja-foi-pago-checkbox"
              onChange={(e) => setDespesaPaga(e.target.checked)}
              checked={despesaPaga}
            />
            <label htmlFor="ja-foi-pago-checkbox">
              Essa despesa já foi paga
            </label>
          </div>

          {despesaPaga && (
            <>
              <div className="adicionar-despesa__modal__pagamento">
                <LocalizationProvider
                  dateAdapter={AdapterMoment}
                  className="adicionar-despesa__modal__container--span1"
                >
                  <DesktopDatePicker
                    className="valorDataCaixa__fields--field muiDatePicker"
                    label="Data do Pagamento*"
                    inputFormat="DD-MM-YYYY"
                    value={dataPagamento}
                    onChange={(value) => setDataPagamento(value)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>

                <InputSelect
                  label="Forma de pagamento"
                  initialValue={metodoPagamento}
                  handleChange={metodoPagamentoChangeHandler}
                  items={formasPagamento}
                />
              </div>

              {comprovativo && (
                <div className="despesas--comprovativo-selecionado">
                  Comprovativo Selecionado:{" "}
                  {comprovativo.name || comprovativo.descricao}
                </div>
              )}

              <div>
                <input
                  type="file"
                  style={{ display: "none" }}
                  id="fileInput"
                  onChange={handleFileChange}
                />
                <label htmlFor="fileInput" className="anexar-comprovativo">
                  {comprovativo
                    ? "Substituir comprovativo"
                    : "Anexar comprovativo"}
                </label>
              </div>

              <div className="despesas--regras-ficheiro">
                <span className="modalAdicionarFicheiros__container-adicionar--regras-ficheiro">
                  O Tamanho máximo do upload é 7MB
                </span>
                <span className="modalAdicionarFicheiros__container-adicionar--regras-ficheiro">
                  Formatos aceites: .pdf, .png, .jpg e .jpeg
                </span>
              </div>
            </>
          )}

          {errorMessage && (
            <div className="modal-mensagem-de-erro">
              <span>{errorMessage}</span>
            </div>
          )}

          <div className="fluxo-atendimento__modal__bottom_btns">
            <span
              className="cancel-btn"
              onClick={handleCloseModalEditarDespesa}
              style={{ display: "block" }}
            >
              Fechar
            </span>

            <span
              disabled={isSubmitting || errorMessage}
              className="blue-button"
              onClick={preGuardarDespesaHandler}
            >
              {isSubmitting ? "Guardando..." : "Guardar"}
            </span>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default ModalEditarDespesa;
