import { useRef, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import "./PdfFaturaStyle.scss";
import moment from "moment/moment";
import QrCode from "../../qrCode/QrCode";
function PdfFatura({ faturaData, showPreviousPage }) {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Fatura-${faturaData.cliente.nome}_${moment(
      new Date()
    ).format("DD-MM-YYYY")}`,
    onAfterPrint: () => {
      showPreviousPage();
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    handlePrint();
  }, [handlePrint]);

  return (
    <>
      <div ref={componentRef} className="pdf-fatura-wrapper">
        <div className="pdf-orcamento-cabecalho" id="pdf-fatura-cabecalho">
          <div className="pdf-orcamento-cabecalho-left">
            <img
              src={`${process.env.REACT_APP_BACKEND_LINK}/${faturaData.clinica.logotipo}`}
              alt=""
            />
          </div>

          <div className="pdf-orcamento-cabecalho-right pdf-fatura-cabecalho-right">
            <span>{faturaData.clinica.nome}</span>
            {faturaData.medico_responsavel && (
              <span>
                {faturaData.medico_responsavel.genero === "masculino"
                  ? "Dr. "
                  : "Dra. "}
                {faturaData.medico_responsavel.name}/
                {faturaData.medico_responsavel.profissao}
              </span>
            )}
            <span>Telef: {faturaData.clinica.contacto}</span>
            <span>{faturaData.clinica.endereco}</span>
            <span>NIF: {faturaData.clinica.nif}</span>
          </div>
        </div>

        <div className="pdf-fatura-cabecalho2">
          <div className="pdf-fatura__infoCliente">
            <div>
              <span>Cliente: </span>
              <span className="blackText">{faturaData.cliente.nome}</span>
            </div>

            <div>
              <span>Morada: </span>
              <span className="blackText">{faturaData.cliente.endereco}</span>
            </div>

            <div>
              <span>Contacto: </span>
              <span className="blackText">{faturaData.cliente.contacto}</span>
            </div>

            {faturaData.cliente.nif && (
              <div>
                <span>NIF: </span>
                <span className="blackText">{faturaData.cliente.nif}</span>
              </div>
            )}
          </div>
        </div>

        <div className="pdf-fatura-cabecalho3">
          <div className="pdf-fatura__reciboData">
            <div>
              <span>Documento: </span>
              <span className="blackText">
                {faturaData.tipoDocumento +
                  "/" +
                  moment(faturaData.dataDocumento).year() +
                  "/" +
                  faturaData.serie +
                  "/" +
                  faturaData.nrDocumento}
              </span>
            </div>
            <div>
              <span>Data: </span>
              <span className="blackText">
                {moment(faturaData.dataDocumento).format("DD/MM/YYYY")}
              </span>
            </div>
            <div>
              <span>Data Vencimento: </span>
              <span className="blackText">
                {moment(
                  faturaData.dataVencimento || faturaData.dataDocumento
                ).format("DD/MM/YYYY")}
              </span>
            </div>
          </div>
        </div>

        <div className="divFaturaTabela">
          <table>
            <tbody>
              <tr className="headingLineFatura">
                <th>Designação</th>
                <th className="right-td">Pr. Unit.</th>
                <th className="right-td">Qtd</th>
                <th className="right-td">IVA</th>
                <th className="right-td">Desconto</th>
                <th className="right-td">Valor</th>
              </tr>
              <tr className="normalLine primeiraLinha">
                <td>{faturaData.designacao}</td>

                <td className="right-td">
                  {faturaData.valor.toLocaleString("pt-br")}
                </td>
                <td className="right-td">1</td>
                <td className="right-td">0</td>
                <td className="right-td">0</td>
                <td className="right-td">
                  {faturaData.valor.toLocaleString("pt-br")}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="pdf-fatura-rodape">
          <hr />
          <div className="pdf-fatura-rodape__container">
            <div className="pdf-fatura-rodape__container1">
              <QrCode link={faturaData.entryName.replace(/\.xml$/, "")} />
            </div>
            <div className="pdf-fatura-rodape__container2">
              <div className="pdf-fatura-rodape__container2__line">
                <span>Sub-total</span>
                <span className="pdf-fatura-rodape__container2__line--dark">
                  {faturaData.valor.toLocaleString("pt-br")}$00
                </span>
              </div>

              <div className="pdf-fatura-rodape__container2__line">
                <span>Desconto</span>
                <span className="pdf-fatura-rodape__container2__line--dark">
                  0$00
                </span>
              </div>

              <div className="pdf-fatura-rodape__container2__line">
                <span>IVA</span>
                <span className="pdf-fatura-rodape__container2__line--dark">
                  0.00$00
                </span>
              </div>

              <div className="pdf-fatura-rodape__container2__line">
                <span>Total</span>
                <span className="pdf-fatura-rodape__container2__line--dark">
                  {faturaData.valor.toLocaleString("pt-br")}$00
                </span>
              </div>
            </div>
          </div>
          <div
            className={`pdf-fatura-rodape__elaboradoPor ${
              faturaData.usuarioEmissor
                ? ""
                : "pdf-fatura-rodape__elaboradoPor--semEmissor"
            }`}
          >
            <span>Elaborado Por</span>
            {faturaData.usuarioEmissor ? (
              <span className="pdf-fatura-rodape__elaboradoPor__emissor">
                {faturaData.usuarioEmissor +
                  " - " +
                  moment(faturaData.dataDocumento).format("DD/MM/YYYY")}
              </span>
            ) : (
              <hr />
            )}
          </div>
          <div className="pdf-fatura-rodape__poweredBy">
            Powered by: HealthTech CV
          </div>
        </div>
      </div>
    </>
  );
}

export default PdfFatura;
