// src/shared/context/websocket-context.js
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { AuthContext } from "./auth-context";

const WebSocketContext = createContext();

const MAX_RECONNECT_ATTEMPTS = 5;
const RECONNECT_INTERVAL = 1000;

export function WebSocketProvider({ children }) {
  const auth = useContext(AuthContext);
  const subscribersRef = useRef(new Map()); // Using Map to store subscriber IDs
  const socketRef = useRef(null);
  const reconnectAttemptsRef = useRef(0);
  const reconnectTimeoutRef = useRef(null);
  const [isConnecting, setIsConnecting] = useState(false);
  let subscriberIdCounter = 0;

  const connectWebSocket = () => {
    if (!auth.token) return;

    if (isConnecting || socketRef.current?.readyState === WebSocket.OPEN) {
      console.log("🟡 Conexão já existe ou está em andamento");
      return;
    }

    setIsConnecting(true);
    const wsUrl = `${process.env.REACT_APP_WEBSOCKET_ADDRESS}?token=${auth.token}`;

    console.log("🔌 Iniciando nova conexão WebSocket");
    socketRef.current = new WebSocket(wsUrl);

    socketRef.current.onopen = () => {
      console.log("🟢 WebSocket conectado com sucesso");
      setIsConnecting(false);
      reconnectAttemptsRef.current = 0;

      // Test message
      console.log("📊 Estado atual:", {
        isConnected: socketRef.current.readyState === WebSocket.OPEN,
        subscribers: Array.from(subscribersRef.current.keys()),
        readyState: socketRef.current.readyState,
      });
    };

    socketRef.current.onmessage = (event) => {
      try {
        const parsedData = JSON.parse(event.data);
        console.log("📩 Raw message received:", event.data);

        console.log(
          `📨 Mensagem recebida, notificando ${subscribersRef.current.size} subscribers:`,
          {
            messageType: parsedData.tipoDeOperacao,
            messageTable: parsedData.tabela,
            subscribers: Array.from(subscribersRef.current.keys()),
          }
        );

        // Notify all subscribers
        subscribersRef.current.forEach((callback, id) => {
          try {
            console.log(`🎯 Enviando mensagem para Subscriber-${id}`);
            callback(parsedData);
            console.log(
              `✅ Mensagem processada com sucesso pelo Subscriber-${id}`
            );
          } catch (error) {
            console.error(`❌ Erro no Subscriber-${id}:`, error);
          }
        });
      } catch (error) {
        console.error("❌ Erro ao processar mensagem:", error);
      }
    };

    socketRef.current.onclose = (event) => {
      console.log("🔴 WebSocket fechado:", {
        code: event.code,
        reason: event.reason,
      });

      socketRef.current = null;
      setIsConnecting(false);

      if (reconnectAttemptsRef.current < MAX_RECONNECT_ATTEMPTS) {
        const timeout =
          RECONNECT_INTERVAL * Math.pow(2, reconnectAttemptsRef.current);
        console.log(`⏳ Tentando reconexão em ${timeout / 1000} segundos...`);

        if (reconnectTimeoutRef.current) {
          clearTimeout(reconnectTimeoutRef.current);
        }

        reconnectTimeoutRef.current = setTimeout(() => {
          reconnectAttemptsRef.current++;
          if (subscribersRef.current.size > 0) {
            console.log(
              `🔄 Tentativa de reconexão ${reconnectAttemptsRef.current}/${MAX_RECONNECT_ATTEMPTS}`
            );
            connectWebSocket();
          }
        }, timeout);
      } else {
        console.log("❌ Número máximo de tentativas de reconexão atingido");
      }
    };

    socketRef.current.onerror = (error) => {
      console.error("🔴 Erro no WebSocket:", error);
    };
  };

  useEffect(() => {
    connectWebSocket();

    return () => {
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
      if (socketRef.current) {
        socketRef.current.close();
        socketRef.current = null;
      }
    };
  }, [auth.token]);

  const subscribe = (callback, subscriberId) => {
    const id = subscriberId || `sub_${subscriberIdCounter++}`;
    console.log(`📥 Subscriber-${id} registrado`);
    subscribersRef.current.set(id, callback);

    if (!socketRef.current || socketRef.current.readyState !== WebSocket.OPEN) {
      connectWebSocket();
    }

    return () => {
      console.log(`📤 Subscriber-${id} removido`);
      subscribersRef.current.delete(id);
    };
  };

  return (
    <WebSocketContext.Provider value={{ subscribe }}>
      {children}
    </WebSocketContext.Provider>
  );
}

// Custom hook for components to use
export function useWebSocketSubscription(
  callback,
  deps = [],
  componentName = "Unknown"
) {
  const { subscribe } = useContext(WebSocketContext);

  useEffect(() => {
    const unsubscribe = subscribe(callback, componentName);
    return () => unsubscribe();
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps
}
