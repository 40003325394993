import React from "react";
import "./Chart.scss";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";

function ChartAdministradorNaoMedico({ nome, data }) {
  // This function formats numbers with dots as thousand separators
  const formatCVE = (value) => {
    // Convert the number to a string and split it into integer and decimal parts
    const parts = value.toString().split(".");

    // Format the integer part with dots as thousand separators
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    // Join back with decimal part if it exists
    const formattedNumber = parts.join(",");

    return "$" + formattedNumber;
  };

  // Custom tooltip component with correct number formatting
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "4px",
          }}
        >
          <p>{`${label}`}</p>
          <p style={{ color: "#377bcc" }}>
            {`Lucro: ${formatCVE(payload[0].value)}`}
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="wholeChartContainer">
      <div className="chart__title">
        <div className="chart__title__div">
          <span className="chart__title__title">Olá, </span>
          <span className="chart__title__title2">{nome}</span>
        </div>
        <span className="chart__title__subtitle">
          Estatística de lucro desse ano
        </span>
      </div>
      <div className="chart">
        <BarChart
          className="barchart"
          width={730}
          height={250}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis dataKey="name" tick={{ fill: "white" }} />
          <YAxis tick={{ fill: "white" }} axisLine={false} />
          <Tooltip content={<CustomTooltip />} />
          <Bar dataKey="lucro" fill="#377bcc" barSize={7} />
        </BarChart>
      </div>
    </div>
  );
}

export default ChartAdministradorNaoMedico;
