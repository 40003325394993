import React, { useState, useEffect, useContext, useCallback } from "react";
import ListOrcamento from "../Lists/ListOrcamento/ListOrcamento";
import moment from "moment/moment";
import { AuthContext } from "../../../../../shared/context/auth-context";
import { ClinicaContext } from "../../../../../shared/context/clinica-context";
//MUI
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import "./OrcamentosComponent.scss";
import ModalAprovarOrcamento from "./components/ModalAprovarOrcamento";

import toast from "react-hot-toast";
import { useLoading } from "../../../../../shared/context/LoadingContext";
import ModalAdicionarCredencial from "./components/ModalAdicionarCredencial/ModalAdicionarCredencial";
import ModalEditarCredencial from "./components/ModalEditarCredencial/ModaleditarCredencial";
function OrcamentosComponent({
  clienteId,
  sendRequest,
  token,
  dadosOrcamento,
}) {
  const { startLoading, stopLoading } = useLoading();
  const [orcamentos, setOrcamentos] = useState([]);
  const [avaliacoes, setAvaliacoes] = useState([]);
  const [contaCorrente, setContaCorrente] = useState([]);

  const auth = useContext(AuthContext);
  const clinica = useContext(ClinicaContext);
  //Modal Code
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [modaisCredencialData, setModaisCredencialData] = useState({
    open: "",
  });

  const handleOpenModal = useCallback(
    (id, tipo) => {
      let dadosFiltrados;
      const orcamentoFiltrado = orcamentos.filter((or) => or.id === id)[0];

      if (tipo === "orcamento") dadosFiltrados = orcamentoFiltrado;
      else
        dadosFiltrados = avaliacoes.filter(
          (av) => av.id === orcamentoFiltrado.avaliacao._id
        )[0];

      setModalData(dadosFiltrados);
      setOpenModal(true);
    },
    [avaliacoes, orcamentos]
  );

  const handleCloseModal = () => {
    setOpenModal(false);
    setModalData({});
  };

  const handleCloseModalAprovarOrcamento = useCallback(() => {
    setModalData({});
  }, []);
  ///////////////////////////////////////

  useEffect(() => {
    const fetchData = async () => {
      startLoading();
      try {
        const [orcamentosResponse, avaliacoesResponse, contaCorrenteResponse] =
          await Promise.all([
            sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/orcamentos/cliente/${clienteId}`,
              "GET",
              null,
              { Authorization: "Bearer " + auth.token }
            ),
            sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/avaliacoes/cliente/comtratamento/${clienteId}`,
              "GET",
              null,
              { Authorization: "Bearer " + auth.token }
            ),
            sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/contascorrentes/cliente/${clienteId}`,
              "GET",
              null,
              { Authorization: "Bearer " + auth.token }
            ),
          ]);

        // Process orcamentos
        const orcamentos = processOrcamentos(orcamentosResponse.orcamentos);
        setOrcamentos(filterByUserRole(orcamentos, auth));

        // Process avaliacoes
        const avaliacoes = processAvaliacoes(avaliacoesResponse.avaliacoes);
        setAvaliacoes(filterByUserRole(avaliacoes, auth));

        // Set conta corrente
        setContaCorrente(contaCorrenteResponse.contaCorrente);
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    };

    fetchData();
  }, [sendRequest, clienteId, auth]);

  // Helper functions
  const processOrcamentos = (orcamentos) => {
    return orcamentos
      .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
      .map((orcamento) => ({
        ...orcamento,
        data_orcamento: moment(orcamento.created_at).format("DD-MM-YYYY"),
      }));
  };

  const processAvaliacoes = (avaliacoes) => {
    return avaliacoes
      .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
      .map((avaliacao) => ({
        ...avaliacao,
        data_avaliacao: moment(avaliacao.created_at).format("DD-MM-YYYY"),
      }));
  };

  const filterByUserRole = (items, auth) => {
    switch (auth.role) {
      // case "Médico/Administrador":
      // case "Dentista":
      //   return items.filter(
      //     (item) => item.medico_responsavel.id === auth.userId
      //   );
      case "Secretária(o)":
        return items.filter((item) =>
          auth.usuariosAssociados.some(
            (medico) => medico.id === item.medico_responsavel.id
          )
        );
      case "Assistente":
        return items.filter((item) =>
          auth.usuariosAssociados.some(
            (medico) => medico.id === item.medico_responsavel.id
          )
        );
      // case "Administrador Não Médico":
      //   return items;
      default:
        return items;
    }
  };
  ///////////////////////////////////////////////////////////////////

  const handleDesativar = useCallback(
    async (id) => {
      startLoading();
      try {
        try {
          const formDataOrcamento = new FormData();
          formDataOrcamento.append("desativadoPor", auth.userId);
          formDataOrcamento.append("utilizador", auth.userId);
          await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/orcamentos/toggleativo/${id}`,
            "PATCH",
            formDataOrcamento,
            {
              Authorization: "Bearer " + token,
            }
          );
        } catch (err) {
          console.error("err", err);
        }

        //filtramos o orcamento a desativar para obter o id da avaliacao que o gerou
        const orcamentoFiltrado = orcamentos.filter((or) => or.id === id)[0];

        let avaliacaoDoOrcamentoDesativado = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/avaliacoes/${orcamentoFiltrado.avaliacao._id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + token,
          }
        );

        avaliacaoDoOrcamentoDesativado =
          avaliacaoDoOrcamentoDesativado.avaliacao;

        //Alteramos os procedimentos da avaliacao que geraram o orcamento para não orçamentados
        avaliacaoDoOrcamentoDesativado.procedimentos.forEach((avPr) => {
          orcamentoFiltrado.procedimentos.forEach((orPr) => {
            if (avPr.id === orPr.id) {
              avPr.orcamentado = false;
            }
          });
        });

        //Obtemos a lista dos procedimentos do orçamento para depois compararmos com os procedimentos
        //orcamentados da avaliacao para podermos definir o estado da avaliação
        const procedimentosDaAvaliacaoOrcamentados =
          avaliacaoDoOrcamentoDesativado.procedimentos.filter(
            (pr) => pr.orcamentado
          );

        const formData = new FormData();
        formData.append(
          "procedimentos",
          JSON.stringify(avaliacaoDoOrcamentoDesativado.procedimentos)
        );
        formData.append("terminado", false);

        //Se todos os procedimentos da avaliacao passaram a ser não orcamentados os não
        if (procedimentosDaAvaliacaoOrcamentados.length > 0)
          formData.append("transformado", true);
        else formData.append("transformado", false);

        try {
          await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/avaliacoes/${orcamentoFiltrado.avaliacao._id}`,
            "PATCH",
            formData,
            {
              Authorization: "Bearer " + token,
            }
          );
        } catch (err) {
          console.error("err", err);
        }

        setOrcamentos((currentOrcamento) => {
          return currentOrcamento.filter((el) => {
            return el.id !== id;
          });
        });

        toast.success("Desativação de Orçamento efetuada com sucesso!");
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    },
    [orcamentos, sendRequest, token]
  );

  const gerarPlanoTratamento = useCallback(
    async (dadosConta, parcelas) => {
      startLoading();
      try {
        const id = dadosConta.orcamento;
        let planoTratamentoCriado;

        const orcamentoFiltrado = orcamentos.filter((or) => or.id === id)[0];
        const procedimentosFiltrados = [];

        orcamentoFiltrado.procedimentos.forEach((pr) => {
          procedimentosFiltrados.push({
            tratamento: pr.tratamento.id,
            dente: pr.dente,
            faces: pr.faces,
            procedimento_completo: false,
          });
        });
        const formData = new FormData();
        formData.append(
          "procedimentos",
          JSON.stringify(procedimentosFiltrados)
        );
        formData.append("cliente", orcamentoFiltrado.cliente.id);
        formData.append("clinica", orcamentoFiltrado.clinica.id);
        formData.append("orcamento", id);
        formData.append("canDelete", true);
        formData.append(
          "medico_responsavel",
          orcamentoFiltrado.medico_responsavel.id
        );
        formData.append("criadoPor", auth.userId);

        //Gravando plano de tratamento
        try {
          planoTratamentoCriado = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/planostratamento`,
            "POST",
            formData,
            {
              Authorization: "Bearer " + token,
            }
          );
        } catch (err) {
          console.error("err", err);
        }
        //Gravando plano de tratamento, transformando orcamento
        const formData2 = new FormData();
        formData2.append("utilizador", auth.userId);
        formData2.append("transformado", true);
        formData2.append(
          "planoTratamento",
          planoTratamentoCriado.planoTratamento._id
        );
        try {
          await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/orcamentos/transformar/${id}`,
            "PATCH",
            formData2,
            {
              Authorization: "Bearer " + token,
            }
          );
        } catch (err) {
          console.error("err", err);
        }
        //Atualizando a conta corrente

        const formData3 = new FormData();

        const filteredDadosConta = {
          ...dadosConta,
          aprovado: true,
          contaLiquidada:
            parcelas[0].entrada &&
            parcelas[0].quantiaPorPagar === dadosConta.total
              ? true
              : false,
          nrParcelas: parcelas[0].entrada
            ? parcelas.length - 1
            : parcelas.length,
          parcelas: parcelas,
        };

        const contasDaContaCorrente = contaCorrente.contas.filter(
          (conta) => conta.id !== dadosConta.id
        );
        contasDaContaCorrente.push(filteredDadosConta);

        formData3.append("contas", JSON.stringify(contasDaContaCorrente));
        try {
          await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/contasCorrentes/${contaCorrente.id}`,
            "PATCH",
            formData3,
            {
              Authorization: "Bearer " + token,
            }
          );
        } catch (err) {
          console.error("err", err);
        }

        /////////////////////////////////////////////////////
        let tempOrcamentos = orcamentos.map((or) => {
          if (or.id === id) {
            return {
              ...or,
              transformado: true,
            };
          } else {
            return or;
          }
        });
        setOrcamentos(tempOrcamentos);
        toast.success(
          `Orçamento ${
            dadosConta.total === 0 ? "isento de pagamento " : " "
          }aprovado com sucesso!`
        );
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    },
    [contaCorrente, orcamentos, sendRequest, token]
  );

  const aprovarOrcamento = useCallback(
    async (id) => {
      startLoading();
      try {
        const filteredConta = contaCorrente.contas.find(
          (conta) => conta.orcamento === id
        );

        if (!filteredConta) {
          toast.error(
            "Conta não encontrada. Contacte os desenvolvedores do aplicativo."
          );
          return;
        }

        const contaAtualizada = JSON.parse(JSON.stringify(filteredConta));

        const parcelasComVencimentos = contaAtualizada.parcelas.map(
          (parcela, index) => {
            const temEntrada = contaAtualizada.parcelas[0].entrada;

            return {
              ...parcela,
              dataLimitePagamento: temEntrada
                ? moment().add(
                    clinica.clinica.configuracoes.configuracoes.validadeParcela
                      .validade * index,
                    "days"
                  )
                : moment().add(
                    clinica.clinica.configuracoes.configuracoes.validadeParcela
                      .validade *
                      (index + 1),
                    "days"
                  ),
            };
          }
        );

        contaAtualizada.parcelas = parcelasComVencimentos;

        //Caso o orçamento foi isento de pagamento
        if (contaAtualizada.total === 0) {
          let responseCaixa;
          try {
            responseCaixa = await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/caixas/clinica/${clinica.clinica._id}`,
              "GET",
              null,
              {
                Authorization: "Bearer " + token,
              }
            );
          } catch (err) {
            console.error("err", err);
          }

          const caixaClinicaFiltrada = responseCaixa.caixas.filter(
            (caixa) => caixa.caixa.toLowerCase() === "clínica"
          )[0];

          gerarPlanoTratamento(contaAtualizada, [
            {
              ...contaAtualizada.parcelas[0],
              parcelaPaga: true,
              metodoPagamento: "Dinheiro",
              dataPagamento: new Date(),
              caixa: caixaClinicaFiltrada._id,
            },
          ]);
        } else {
          const filteredOrcamento = orcamentos.filter(
            (orc) => orc._id === id
          )[0];
          setModalData({
            open: "aprovarOrcamento",
            orcamento: filteredOrcamento,
            conta: contaAtualizada,
          });
        }
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    },
    [
      clinica.clinica._id,
      contaCorrente,
      gerarPlanoTratamento,
      sendRequest,
      token,
    ]
  );

  const orcamentoData = (
    tipo,
    serviceList,
    cliente,
    clinica,
    medico_responsavel,
    numero,
    desconto,
    tipoDesconto,
    orcamentoMisto
  ) => {
    const dados = {
      tipo,
      serviceList,
      cliente,
      clinica,
      medico_responsavel,
      numero,
      desconto,
      tipoDesconto,
      orcamentoMisto,
    };
    dadosOrcamento(dados);
  };

  const preAdicionarCredencialHandler = (id) => {
    setModaisCredencialData({ open: "inserir", idOrcamento: id });
  };

  const preEditarCredencialHandler = (id) => {
    const filteredOrcamento = orcamentos.filter((o) => o._id === id)[0];

    setModaisCredencialData({
      open: "editar",
      idOrcamento: id,
      nrCredencialInicial: filteredOrcamento.nrCredencial,
      dataInicial: filteredOrcamento.dataCredencial,
    });
  };

  const handleCloseModaisCredencial = () => {
    setModaisCredencialData({ open: "" });
  };

  const handleUpdateCredencialData = (nrCredencial, dataCredencial, idOrc) => {
    const filteredOrcamento = orcamentos.filter((o) => o._id === idOrc)[0];
    filteredOrcamento.nrCredencial = nrCredencial;
    filteredOrcamento.dataCredencial = dataCredencial;

    setOrcamentos((prevOrcs) =>
      prevOrcs.map((orc) => (orc._id === idOrc ? filteredOrcamento : orc))
    );
  };

  const updateOrcamentos = (orcamentoAtualizar) => {
    const tempOrcamentos = orcamentos.map((or) =>
      or._id === orcamentoAtualizar._id ? orcamentoAtualizar : or
    );
    setOrcamentos(processOrcamentos(tempOrcamentos));
  };

  return (
    <>
      {modaisCredencialData.open === "inserir" && (
        <ModalAdicionarCredencial
          open={modaisCredencialData.open === "inserir"}
          handleCloseModaisCredencial={handleCloseModaisCredencial}
          idOrcamento={modaisCredencialData.idOrcamento}
          sendRequest={sendRequest}
          token={token}
          handleUpdateCredencialData={handleUpdateCredencialData}
        />
      )}

      {modaisCredencialData.open === "editar" && (
        <ModalEditarCredencial
          open={modaisCredencialData.open === "editar"}
          handleCloseModaisCredencial={handleCloseModaisCredencial}
          idOrcamento={modaisCredencialData.idOrcamento}
          sendRequest={sendRequest}
          token={token}
          handleUpdateCredencialData={handleUpdateCredencialData}
          dataInicial={modaisCredencialData.dataInicial}
          nrCredencialInicial={modaisCredencialData.nrCredencialInicial}
        />
      )}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="fluxo-atendimento__modal__box avaliacoes__modal">
          <h1 className="fluxo-atendimento__modal__titulo">Avaliação</h1>
          <span className="fluxo-atendimento__modal__designacao">
            Tratamento
          </span>
          <span className="fluxo-atendimento__modal__designacao">Dentes</span>
          <span className="fluxo-atendimento__modal__designacao">Faces</span>
          {modalData.procedimentos &&
            modalData.procedimentos.map((proc, index) => (
              <React.Fragment key={index}>
                <span className="fluxo-atendimento__modal__cell">
                  {proc.tratamento.designacao}
                </span>
                <span className="fluxo-atendimento__modal__cell">
                  {proc.dente}
                </span>
                <span className="fluxo-atendimento__modal__cell">
                  {proc.faces.join(", ")}
                </span>
              </React.Fragment>
            ))}
          {modalData.observacoes && (
            <>
              <span className="fluxo-atendimento__modal__observacao-titulo">
                Observação
              </span>
              <textarea
                className="fluxo-atendimento__modal__textarea"
                cols="30"
                rows="4"
                readOnly
                defaultValue={modalData.observacoes}
              ></textarea>
            </>
          )}
          <div
            style={{
              gridColumn: "1/-1",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <span className="cancel-btn" onClick={() => setOpenModal(false)}>
              Fechar
            </span>
          </div>
        </Box>
      </Modal>

      {orcamentos.length !== 0 && (
        <>
          {/* Modal orcamento e Avaliacao */}
          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            {/* Verificar se nos dados da modal conter avaliacao. Se sim, estamos lidando com dados do orcamento */}
            <Box
              className={`fluxo-atendimento__modal__box ${
                modalData.avaliacao
                  ? modalData.beneficio === "Particular"
                    ? "orcamento__modal"
                    : "orcamento__modal--beneficiario"
                  : "avaliacao__modal"
              }`}
            >
              <span className="fluxo-atendimento__modal__titulo">
                {modalData.avaliacao
                  ? `Orçamento nr ${modalData.numero} ${
                      modalData.orcamentoMisto
                        ? "- Misto"
                        : " - " + modalData.beneficio
                    }`
                  : "Avaliação"}

                {modalData.total === 0 ? (
                  <span className="orcamentoIsentoTitleMessage">
                    {" "}
                    - Isento de Pagamento
                  </span>
                ) : (
                  ""
                )}
              </span>
              <span className="fluxo-atendimento__modal__designacao">
                Tratamento
              </span>
              <span className="fluxo-atendimento__modal__designacao">
                Dentes/Região
              </span>
              {!modalData.avaliacao && (
                <span className="fluxo-atendimento__modal__designacao">
                  Faces
                </span>
              )}
              {modalData.avaliacao && (
                <span className="fluxo-atendimento__modal__designacao">
                  Valor
                </span>
              )}

              {modalData.avaliacao && modalData.beneficio !== "Particular" && (
                <span className="fluxo-atendimento__modal__designacao">
                  INPS
                </span>
              )}
              {modalData.procedimentos &&
                modalData.procedimentos.map((proc, index) => (
                  <React.Fragment key={index}>
                    <span className="fluxo-atendimento__modal__cell">
                      {proc.tratamento.designacao}
                      {modalData.avaliacao &&
                        proc.faces.length > 0 &&
                        `- face(s) ${proc.faces.join(", ")}`}
                    </span>
                    <span className="fluxo-atendimento__modal__cell">
                      {proc.dente}
                    </span>
                    {!modalData.avaliacao && (
                      <span className="fluxo-atendimento__modal__cell">
                        {proc.faces.join(", ")}
                      </span>
                    )}

                    {modalData.avaliacao && (
                      <span className="fluxo-atendimento__modal__cell">
                        {proc.pr_total}
                      </span>
                    )}

                    {modalData.avaliacao &&
                      modalData.beneficio !== "Particular" && (
                        <span className="fluxo-atendimento__modal__cell">
                          {proc.tratamento.comparticipacao_inps}
                        </span>
                      )}
                  </React.Fragment>
                ))}
              {modalData.observacoes &&
                modalData.observacoes !== "undefined" && (
                  <>
                    <span className="fluxo-atendimento__modal__observacao-titulo">
                      Observação
                    </span>
                    <textarea
                      className="fluxo-atendimento__modal__textarea"
                      cols="30"
                      rows="4"
                      readOnly
                      defaultValue={
                        modalData.observacoes ? modalData.observacoes : ""
                      }
                    />
                  </>
                )}

              {modalData.avaliacao && (
                <div className="verOrcamentoModal__valorCredencialContainer">
                  <div className="verOrcamentoModal__valorCredencialContainer__col">
                    <div className="verOrcamentoModal__valorCredencialContainer__col__row">
                      <span className="orcamento__modal__valores__descricao">
                        Credencial
                      </span>
                      <span className="orcamento__modal__valores__valor">
                        {modalData.nrCredencial ? modalData.nrCredencial : ""}
                      </span>
                    </div>
                    <div className="verOrcamentoModal__valorCredencialContainer__col__row">
                      <span className="orcamento__modal__valores__descricao">
                        Data início
                      </span>
                      <span className="orcamento__modal__valores__valor">
                        {modalData.dataCredencial
                          ? moment(modalData.dataCredencial).format(
                              "DD-MM-YYYY"
                            )
                          : ""}
                      </span>
                    </div>
                    <div className="verOrcamentoModal__valorCredencialContainer__col__row">
                      <span className="orcamento__modal__valores__descricao">
                        Válido até
                      </span>
                      <span className="orcamento__modal__valores__valor orcamento__modal__valores__valor--total">
                        {modalData.dataCredencial
                          ? moment(modalData.dataCredencial)
                              .add(180, "days")
                              .format("DD-MM-YYYY")
                          : ""}
                      </span>
                    </div>
                  </div>
                  <div className="verOrcamentoModal__valorCredencialContainer__col">
                    <div className="verOrcamentoModal__valorCredencialContainer__col__row">
                      <span className="orcamento__modal__valores__descricao">
                        Subtotal
                      </span>
                      <span className="orcamento__modal__valores__valor">
                        {modalData.subtotal}$00
                      </span>
                    </div>
                    <div className="verOrcamentoModal__valorCredencialContainer__col__row">
                      <span className="orcamento__modal__valores__descricao">
                        Desconto
                      </span>
                      <span className="orcamento__modal__valores__valor">
                        {modalData.desconto}$00
                      </span>
                    </div>
                    <div className="verOrcamentoModal__valorCredencialContainer__col__row">
                      <span className="orcamento__modal__valores__descricao">
                        Total
                      </span>
                      <span className="orcamento__modal__valores__valor orcamento__modal__valores__valor--total">
                        {modalData.total}$00
                      </span>
                    </div>
                  </div>
                </div>
              )}
              <div
                style={{
                  gridColumn: "1/-1",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <span
                  className="cancel-btn"
                  onClick={() => setOpenModal(false)}
                >
                  Fechar
                </span>
              </div>
            </Box>
          </Modal>
          {/* Modal Aprovar Orcamento */}
          {modalData.open === "aprovarOrcamento" && (
            <ModalAprovarOrcamento
              open={modalData.open === "aprovarOrcamento"}
              modalData={modalData}
              handleCloseModalAprovarOrcamento={
                handleCloseModalAprovarOrcamento
              }
              sendRequest={sendRequest}
              auth={auth}
              contaCorrente={contaCorrente}
              updateOrcamentos={updateOrcamentos}
              clinica={clinica}
            />
          )}
          <ListOrcamento
            orcamentoData={orcamentoData}
            data={orcamentos}
            handleOpenModal={handleOpenModal}
            aprovarOrcamento={aprovarOrcamento}
            handleDesativar={handleDesativar}
            preAdicionarCredencialHandler={preAdicionarCredencialHandler}
            preEditarCredencialHandler={preEditarCredencialHandler}
            auth={auth}
          />
        </>
      )}
      {orcamentos.length === 0 && (
        <div className="no_data_div">Nenhum Orçamento encontrado.</div>
      )}
    </>
  );
}

export default OrcamentosComponent;
