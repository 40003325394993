import { useState, useEffect } from "react";
import moment from "moment";
import InputNumber from "../../../../../../../shared/components/inputs/InputNumber/InputNumber";
import InputText from "../../../../../../../shared/components/inputs/InputText/InputText";
import { Link } from "react-router-dom";
//MUI
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import toast from "react-hot-toast";

import InputSelect from "../../../../../../../shared/components/inputs/InputSelect/InputSelect";

import { useLoading } from "../../../../../../../shared/context/LoadingContext";
import "./ModalEntradaDebito.scss";

const formasPagamento = [
  "Dinheiro",
  "Cheque",
  // "Cheque Visado",
  // "Transferência de crédito",
  "Internet banking",
  // "Cartão do banco",
  // "Débito direto da conta bancária",
  "Cartão de crédito",
  // "Cartão de débito",
];

function ModalEntradaDebito({
  openModalEntrada,
  handleCloseModalEntrada,
  sendRequest,
  clinicaId,
  updateEntradas,
  emitirDocEletronicoReembolso,
  auth,
  cliente,
}) {
  const { startLoading, stopLoading } = useLoading();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openModal, setOpenModal] = useState(openModalEntrada);
  const [descricao, setDescricao] = useState("");
  const [valor, setValor] = useState(0);
  const [observacao, setObservacao] = useState("");
  const [categoria, setCategoria] = useState();
  const [categorias, setCategorias] = useState([]);
  const [caixa, setCaixa] = useState();
  const [caixas, setCaixas] = useState([]);

  const [metodoPagamento, setMetodoPagamento] = useState(formasPagamento[0]);

  useEffect(() => {
    const fetchData = async () => {
      startLoading();
      try {
        const [categoriasResponse, caixasResponse] = await Promise.all([
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/categoriasfinanceiro/clinica/entrada/${clinicaId}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/caixas/clinica/${clinicaId}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
        ]);

        // Process categorias
        const categoriasFormatted = categoriasResponse.categoriasFinanceiro.map(
          (cat) => ({
            id: cat.id,
            value: cat.categoria,
          })
        );
        setCategorias(categoriasFormatted);
        setCategoria(categoriasFormatted[0]?.id);

        // Process caixas
        const caixasFormatted = caixasResponse.caixas.map((cai) => ({
          id: cai.id,
          value: cai.caixa,
        }));
        setCaixas(caixasFormatted);
        setCaixa(caixasFormatted[0]?.id);
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    };

    fetchData();
  }, [auth.token, clinicaId, sendRequest]);

  useEffect(() => {
    setOpenModal(openModalEntrada);
  }, [openModalEntrada]);

  const descricaoChangeHandler = (value) => {
    setDescricao(value);
  };

  const valorChangeHandler = (value) => {
    setValor(value);
  };

  const guardarEntradaHandler = async (emit) => {
    startLoading();
    try {
      const formData = new FormData();
      formData.append("tipo", "reembolso");
      formData.append("clinica", clinicaId);
      formData.append("observacoes", observacao ? observacao : "");
      formData.append("valor", valor);
      formData.append("descricao", descricao.trim());
      formData.append("dataLimitePagamento", moment());
      formData.append("criadoPor", auth.userId);

      formData.append("categoria", categoria);
      formData.append("caixa", caixa);

      formData.append("cliente", cliente._id);
      formData.append("metodoPagamento", metodoPagamento);
      formData.append("dataPagamento", moment());
      formData.append("entradaRecebida", true);
      formData.append("recebidoPor", auth.userId);

      //LEMBRA DE ADICIONAR COMPROVATIVO MAIS TARDE
      let entradaAdicionada;
      try {
        entradaAdicionada = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/entradas`,
          "POST",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
      } catch (err) {
        console.error("err", err);
      }

      if (emit) {
        emitirDocEletronicoReembolso(
          entradaAdicionada.entrada,
          null,
          "FRE",
          "inserir"
        );
      } else {
        toast.success("Entrada adicionada com sucesso!");
        updateEntradas(entradaAdicionada.entrada, "inserir");
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setIsSubmitting(false);
      handleCloseModalEntrada();
    }
  };

  const metodoPagamentoChangeHandler = (valor) => {
    setMetodoPagamento(valor);
  };

  const preGuardarEntradaHandler = (e, emit) => {
    if (valor === 0 || valor.trim() === "") {
      toast.error("O valor da entrada não pode ser 0");
    } else if (!descricao || descricao.trim() === "") {
      toast.error("Introduza a descrição");
    } else if (!cliente) {
      toast.error("Escolha o Cliente");
    } else if (!categoria) {
      toast.error("Introduza uma categoria");
    } else if (!caixa) {
      toast.error("Introduza uma categoria");
    } else if (!isSubmitting) {
      setIsSubmitting(true);
      guardarEntradaHandler(emit);
    }
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleCloseModalEntrada}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="fluxo-atendimento__modal__box adicionar-entrada__modal"
          id="modal__adicionar-entrada"
        >
          <h1 className="fluxo-atendimento__modal__titulo">Nova entrada</h1>
          <div className="adicionar-entrada__modal__container">
            <InputText
              className="adicionar-entrada__modal__container--span2"
              label="Descrição *"
              initialValue={descricao}
              handleChange={descricaoChangeHandler}
            />

            <InputNumber
              className="adicionar-entrada__modal__container--span1"
              label="Valor *"
              initialValue={valor}
              handleChange={valorChangeHandler}
            />

            <InputSelect
              className="adicionar-entrada__modal__container--span1"
              label="Método de pagamento"
              initialValue={metodoPagamento}
              handleChange={metodoPagamentoChangeHandler}
              items={formasPagamento}
            />

            {categorias && categoria && (
              <div className="customInputContainer adicionar-entrada__modal__container--span2">
                <select
                  onChange={(e) => setCategoria(e.target.value)}
                  className="customInputSelect"
                  value={categoria}
                >
                  {categorias &&
                    categoria &&
                    categorias.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.value}
                      </option>
                    ))}
                </select>
                <label className="customInputLabelSelect">Categoria</label>
                <Link to={`../../configuracoes/categoriasFinanceiro`}>
                  <ControlPointIcon className="customInputPlusSign" />
                </Link>
              </div>
            )}

            {caixas && caixa && (
              <div className="customInputContainer adicionar-entrada__modal__container--span2">
                <select
                  onChange={(e) => setCaixa(e.target.value)}
                  className="customInputSelect"
                  value={caixa}
                >
                  {caixas &&
                    caixa &&
                    caixas.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.value}
                      </option>
                    ))}
                </select>
                <label className="customInputLabelSelect">Caixa</label>
                <Link to={`../../configuracoes/caixas`}>
                  <ControlPointIcon className="customInputPlusSign" />
                </Link>
              </div>
            )}

            <textarea
              cols="30"
              rows="7"
              placeholder="observacao"
              value={observacao}
              onChange={(e) => setObservacao(e.target.value)}
              className="adicionar-entrada__modal__container__textarea"
            ></textarea>
          </div>

          <div className="fluxo-atendimento__modal__bottom_btns">
            <span
              className="cancel-btn"
              onClick={handleCloseModalEntrada}
              style={{ display: "block" }}
            >
              Fechar
            </span>

            <span
              disabled={isSubmitting}
              className="blue-button"
              onClick={preGuardarEntradaHandler}
            >
              {isSubmitting ? "Guardando..." : "Guardar"}
            </span>
            <span
              disabled={isSubmitting}
              className={"blue-button"}
              onClick={preGuardarEntradaHandler.bind(null, null, "emitirFRE")}
            >
              {isSubmitting
                ? "Guardando e emitindo..."
                : "Guardar e emitir FRE"}
            </span>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default ModalEntradaDebito;
