import React from "react";
import "./SingleUpcomingAppointment.scss";

function SingleUpcomingAppointment({ agendamento }) {
  return (
    <div className="single-agendamento caixa">
      <div className="single-agendamento--data single-agendamento--column single-agendamento--column--1">
        <div>
          <span className="single-agendamento--data--data">
            {agendamento.data}
          </span>
        </div>
        <div className="single-agendamento--data--horas">
          <span className="single-agendamento--data--hora">
            {agendamento.horaInicio} -
          </span>

          <span className="single-agendamento--data--hora">
            {` ${agendamento.horaFim}`}
          </span>
        </div>
      </div>

      <div className="single-agendamento--tratamento single-agendamento--column single-agendamento--column--2">
        <span className="single-agendamento--tratamento--title single-agendamento--title">
          Procedimento
        </span>
        <span className="single-agendamento--tratamento--tratamento single-agendamento--text">
          {agendamento.tratamento}
        </span>
      </div>

      <div className="single-agendamento--medico single-agendamento--column single-agendamento--column--3">
        <span className="single-agendamento--medico--title single-agendamento--title">
          Médico
        </span>
        <span className="single-agendamento--medico--medico single-agendamento--text">
          {agendamento.medico.nome}
        </span>
      </div>
      <div className="single-agendamento--medico single-agendamento--column single-agendamento--column--3">
        <span className="single-agendamento--medico--title single-agendamento--title">
          Agendado por
        </span>
        <span className="single-agendamento--medico--medico single-agendamento--text">
          {agendamento.agendadoPor.nome}
        </span>
      </div>
    </div>
  );
}

export default SingleUpcomingAppointment;
