import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import { AuthContext } from "../../../../../shared/context/auth-context";
//MUI
import { DataGrid } from "@mui/x-data-grid";
import dataGridData from "./components/datagridData";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
/////////////////////////////////////////////////////
import ModalReceber from "./components/ModalReceber";
import ModalProcedimentos from "./components/ModalProcedimentos/ModalProcedimentos";
import ModalVerReceita from "./components/ModalVerPagamento/ModalVerReceita";
import ModalMotivoCancelamento from "./components/ModalMotivoCancelamento/ModalMotivoCancelamento";
import "./Debitos.scss";
import { useLoading } from "../../../../../shared/context/LoadingContext";
//Antd
import { Popover, Space } from "antd";
import { Popconfirm } from "antd";
import toast from "react-hot-toast";
import { ClinicaContext } from "../../../../../shared/context/clinica-context";
import ModalEditarDesconto from "./components/ModalEditarDesconto/ModalEditarDesconto";
import ModalVerEntrada from "../../../../financeiro/receitas/components/modalEntrada/ModalVerEntrada";
//Mui button dropdown
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ModalMotivoCancelamentoPendencias from "./components/ModalMotivoCancelamentoPendencias/ModalMotivoCancelamentoPendencias";
import ModalMotivoCancelamentoEntradasDebitos from "./components/ModalMotivoCancelamentoEntradasDebitos/ModalMotivoCancelamentoEntradasDebitos";
import ModalEditarEntradaDebito from "./components/modalEntradaDebito/ModalEditarEntradaDebito";
import ModalEntradaDebito from "./components/modalEntradaDebito/ModalEntradaDebito";

import CancelIcon from "@mui/icons-material/Cancel";
import HistoryIcon from "@mui/icons-material/History";
import DiscountIcon from "@mui/icons-material/Discount";
import InfoIcon from "@mui/icons-material/Info";
import EditIcon from "@mui/icons-material/Edit";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";

import ModalInfo from "../../../../../shared/components/UIElements/ModalInfo";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Chip,
  Alert,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ModalMotivoEventoDebitos from "./components/ModalMotivoEventoDebitos/ModalMotivoEventoDebitos";
import ModalReceberEntradaDebito from "./components/modalEntradaDebito/ModalReceberEntradaDebito";

function Debitos({
  clienteId,
  sendRequest,
  nomeCliente,
  dadosFatura,
  clienteData,
  dadosRecibo,
}) {
  const { startLoading, stopLoading } = useLoading();

  const [totalRecebido, setTotalRecebido] = useState(0);
  const [totalReceber, setTotalReceber] = useState(0);
  const [rows, setRows] = useState([]);
  const [contaCorrente, setContaCorrente] = useState();
  const [entradas, setEntradas] = useState();
  const [valor, setValor] = useState(0);
  const [idParcela, setIdParcela] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState();
  const [mostrarRecebidos, setMostrarRecebidos] = useState(true);
  const [dadosClinica, setDadosClinica] = useState();
  const [dadosModalMotivoCancelamento, setDadosModalMotivoCancelamento] =
    useState({
      open: false,
    });

  const auth = useContext(AuthContext);
  const clinica = useContext(ClinicaContext);

  const [modalProps, setModalProps] = useState({
    show: false,
    icon: "",
    title: "",
    message: "",
    form: false,
  });

  const handleModalCancel = () => {
    setModalProps((currentModalProps) => {
      return { ...currentModalProps, show: false };
    });
  };

  const handleConfirmRestaurarPendencia = async (conta) => {
    setModalProps({
      show: true,
      icon: "info",
      title: `Restaurar Pendências!`,
      message: `Tem a certeza que pretende restaurar as pendências da conta?`,
      form: true,
      onCancel: handleModalCancel,
      onConfirm: handleRestaurarPendencias.bind(null, conta),
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      startLoading();
      try {
        const [contaCorrenteResponse, entradasResponse, clinicaResponse] =
          await Promise.all([
            sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/contascorrentes/orcamento/cliente/${clienteId}`,
              "GET",
              null,
              { Authorization: "Bearer " + auth.token }
            ),
            auth.perm.includes("r-ent")
              ? sendRequest(
                  `${process.env.REACT_APP_BACKEND_LINK}/api/entradas/cliente/${clienteId}`,
                  "GET",
                  null,
                  { Authorization: "Bearer " + auth.token }
                )
              : Promise.resolve({ entradas: [] }),
            sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/clinicas/${clinica.clinica._id}`,
              "GET",
              null,
              { Authorization: "Bearer " + auth.token }
            ),
          ]);

        setContaCorrente(contaCorrenteResponse.contaCorrente);
        setEntradas(entradasResponse.entradas);
        setDadosClinica(clinicaResponse.clinica);
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    };

    fetchData();
  }, [clienteId, sendRequest, clinica, auth.token]);

  useEffect(() => {
    let recebido = 0;
    let porReceber = 0;
    let dataParaDataGrid = [];

    if (contaCorrente && contaCorrente.contas) {
      const contasNaoLiquidadas = contaCorrente.contas;

      contasNaoLiquidadas.forEach((conta) => {
        if (conta.aprovado && conta.orcamento.ativo) {
          conta.parcelas.forEach((parcela) => {
            if (!mostrarRecebidos && parcela.parcelaPaga) return;
            recebido += parcela.quantiaPaga;
            if (parcela.status !== "CANCELADA") {
              porReceber += parcela.quantiaPorPagar;
            }
            dataParaDataGrid.push({
              tipo: "receita",
              orcamento: conta.orcamento,
              id: parcela.id,
              contaId: conta._id,
              dataLimitePagamento: parcela.dataLimitePagamento,
              dataPagamento: parcela.dataPagamento,
              quantiaPorPagar: parcela.quantiaPorPagar,
              quantiaPaga: parcela.quantiaPaga,
              metodoPagamento: parcela.metodoPagamento,
              entrada: parcela.entrada,
              nrParcela: parcela.nrParcela,
              parcelaPaga: parcela.parcelaPaga,
              documentoEletronico: parcela.documentoEletronico,
              estado: parcela.parcelaPaga
                ? "Pago"
                : moment()
                    .startOf("day")
                    .isAfter(moment(parcela.dataLimitePagamento).startOf("day"))
                ? "Em atraso"
                : "",
              nrRecibo: parcela.nrRecibo,
              cancelamentosDocumentosEletronicos:
                parcela.cancelamentosDocumentosEletronicos,
              status: parcela.status,
              motivoEncerramento: conta.motivoEncerramento,
              recebidoPor: parcela.recebidoPor?.user?.name || "",
              dataRecebimento: parcela.recebidoPor?.data
                ? moment(parcela.recebidoPor?.data).format("DD/MM/YYYY HH:mm")
                : "",
              emitidoPor: parcela.documentoEletronico?.usuarioEmissor?.name,
              dataEmissao: parcela.documentoEletronico?.created_at
                ? moment(parcela.documentoEletronico?.created_at).format(
                    "DD/MM/YYYY HH:mm"
                  )
                : "",
            });
          });
        }
      });
    }

    if (entradas && entradas.length > 0) {
      entradas.forEach((entrada) => {
        if (!mostrarRecebidos && entrada.entradaRecebida) return;
        if (entrada.entradaRecebida && entrada.valor) {
          recebido += entrada.valor;
        } else {
          porReceber += entrada.valor;
        }
        dataParaDataGrid.push({
          tipo: "entrada",
          tipoEntrada: entrada.tipo,
          id: entrada.id,
          cliente: entrada.clienteFornecedor
            ? entrada.clienteFornecedor
            : entrada.cliente,
          dataPagamento: entrada.dataPagamento,
          dataLimitePagamento: entrada.dataLimitePagamento,
          valor: entrada.valor,
          metodoPagamento: entrada.metodoPagamento,
          descricao: entrada.descricao,
          comprovativo: entrada.comprovativo,
          nrRecibo: entrada.nrRecibo,
          documentoEletronico: entrada.documentoEletronico,
          entradaRecebida: entrada.entradaRecebida,
          cancelamentosDocumentosEletronicos:
            entrada.cancelamentosDocumentosEletronicos,
          recebidoPor: entrada.recebidoPor?.user?.name || "",
          dataRecebimento: entrada.recebidoPor?.data
            ? moment(entrada.recebidoPor?.data).format("DD/MM/YYYY HH:mm")
            : "",
          emitidoPor: entrada.documentoEletronico?.usuarioEmissor?.name,
          dataEmissao: entrada.documentoEletronico?.created_at
            ? moment(entrada.documentoEletronico?.created_at).format(
                "DD/MM/YYYY HH:mm"
              )
            : "",
        });
      });
    }

    dataParaDataGrid = dataParaDataGrid.sort(
      (a, b) =>
        new Date(a.dataLimitePagamento) - new Date(b.dataLimitePagamento)
    );
    setTotalRecebido(recebido.toLocaleString("pt-BR"));
    setTotalReceber(porReceber.toLocaleString("pt-BR"));
    setRows(dataGridData(dataParaDataGrid, nomeCliente));
  }, [contaCorrente, nomeCliente, mostrarRecebidos, entradas]);

  //Mui Button editarDesconto
  const [menuState, setMenuState] = React.useState({
    anchorEl: null,
    selectedConta: null,
    isClosing: false,
  });

  const handleClickMenu = (event, conta) => {
    event.preventDefault();
    event.stopPropagation();
    setMenuState({
      anchorEl: event.currentTarget,
      selectedConta: conta,
      isClosing: false,
    });
  };

  const handleCloseMenu = (event) => {
    if (event) {
      // Add this check since onClose can be called without an event
      event.preventDefault();
      event.stopPropagation();
    }
    setMenuState((prev) => ({
      ...prev,
      anchorEl: null,
      isClosing: true,
    }));
    // Clear the selected conta after the menu closing animation
    setTimeout(() => {
      setMenuState({
        anchorEl: null,
        selectedConta: null,
        isClosing: false,
      });
    }, 200); // MUI menu transition duration
  };

  //A funcao verifica o nif e compara com o nome retornado para ver se é identico
  const checkNifError = async (nif, nome, nomePais) => {
    let erro;
    if (nomePais !== "CABO VERDE") {
      erro = false;
    } else {
      const formData = new FormData();
      nif && formData.append("nif", nif);

      formData.append(
        "clienteSecret",
        clinica.clinica.dadosEmissor.cliente_oauth_secret
      );
      formData.append("clienteId", clinica.clinica.dadosEmissor.cliente_id);

      try {
        const response = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/documentoeletronico/nifbinome`,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        if (response.payload.length === 0 || response.payload.length > 1) {
          erro = true;
        } else if (response.payload.length === 1) {
          if (response.payload[0].Name.toLowerCase() === nome.toLowerCase())
            erro = false;
          else erro = true;
        }
      } catch (err) {
        console.error("err", err);
      }
    }
    return erro;
  };

  const handleOpenModalReceber = (idParcela, valorParcela) => {
    setOpenModal("receber");
    setValor(valorParcela);
    setIdParcela(idParcela);
  };

  const updateContaCorrente = (
    novaContaCorrente,
    dadosEmicaoDocumento,
    tipoDocumento
  ) => {
    if (!dadosEmicaoDocumento) {
      stopLoading();
    }

    setContaCorrente({ ...novaContaCorrente });

    if (dadosEmicaoDocumento) {
      const contaFiltrada = novaContaCorrente.contas.filter(
        (c) => c._id === dadosEmicaoDocumento.Idconta
      )[0];

      const parcelaFiltrada = contaFiltrada.parcelas.filter(
        (p) => p._id === dadosEmicaoDocumento.IdParcela
      )[0];

      if (tipoDocumento === "FRE") {
        emitirDocumentoFiscal("FRE", parcelaFiltrada);
      }
    }
  };

  const handleOpenModalProcedimentos = (orcamento) => {
    setModalData(orcamento);
    setOpenModal("procedimentos");
  };

  const handleCloseModal = () => {
    setOpenModal("");
    setDadosModalMotivoCancelamento({ open: false });
    setModalData(null);
  };

  const beforeCancelarRecebimento = (id, idConta) => {
    const copyContaCorrente = { ...contaCorrente };

    const filteredConta = copyContaCorrente.contas.filter(
      (conta) => conta.id === idConta
    )[0];

    const oldParcela = filteredConta.parcelas.filter(
      (parcela) => parcela.id === id
    )[0];

    if (oldParcela.documentoEletronico?.succeeded) {
      //Codigo para obter o motivo
      if (oldParcela.documentoEletronico.tipoDocumento === "FRE") {
        setDadosModalMotivoCancelamento({
          open: true,
          id,
          idConta,
          tipoDocumento: "NCE",
        });
      } else if (oldParcela.documentoEletronico.tipoDocumento === "TVE") {
        setModalData({
          open: "motivoEvento",
          id,
          idConta,
        });
      }
    } else {
      handleCancelarRecebimento(id, idConta);
    }
  };

  const handleCancelarRecebimento = async (
    id,
    idConta,
    motivoCancelamento,
    tipoDocumento
  ) => {
    startLoading();
    try {
      const copyContaCorrente = { ...contaCorrente };
      const filteredConta = copyContaCorrente.contas.filter(
        (conta) => conta.id === idConta
      )[0];
      const oldParcela = filteredConta.parcelas.filter(
        (parcela) => parcela.id === id
      )[0];

      let respostaDocumentoCancelamento;

      if (oldParcela.documentoEletronico?.succeeded) {
        if (!contaCorrente.cliente.nif) {
          toast.error("O cliente não possui um nif!");
        } else if (
          !contaCorrente.cliente.nomeFiscal ||
          contaCorrente.cliente.nomeFiscal.trim() === ""
        ) {
          toast.error("O cliente não possui um nome fiscal!");
        } else if (
          !contaCorrente.cliente.contacto ||
          contaCorrente.cliente.contacto.trim() === ""
        ) {
          toast.error("O cliente não possui um número de telefone!");
        } else if (
          !contaCorrente.cliente.endereco ||
          contaCorrente.cliente.endereco.trim() === ""
        ) {
          toast.error("O cliente não possui um endereço!");
        } else {
          let idDocumentoAEliminar;

          //SE existe 1 documento eletronico emitido com erro anteriormente
          if (
            (tipoDocumento === "Event" || tipoDocumento === "NCE") &&
            oldParcela.cancelamentosDocumentosEletronicos[
              oldParcela.cancelamentosDocumentosEletronicos?.length - 1
            ]?.documentoCancelamento?.succeeded === false
          ) {
            idDocumentoAEliminar =
              oldParcela.cancelamentosDocumentosEletronicos[
                oldParcela.cancelamentosDocumentosEletronicos?.length - 1
              ].documentoCancelamento._id;
          }

          const formData = new FormData();

          formData.append("clinicaId", clinica.clinica._id);
          formData.append(
            "IUDCancelar",
            oldParcela.documentoEletronico.entryName.replace(/\.xml$/, "")
          );
          formData.append("usuarioEmissor", auth.userId);
          formData.append("contaCorrente", contaCorrente._id);
          formData.append("conta", filteredConta._id);
          formData.append("parcela", oldParcela._id);
          formData.append("valor", oldParcela.quantiaPaga);

          if (tipoDocumento !== "Event") {
            formData.append("nomeCliente", contaCorrente.cliente.nomeFiscal);
            formData.append("nifCliente", contaCorrente.cliente.nif);
            formData.append(
              "descricaoPagamento",
              oldParcela.descricao && oldParcela.descricao.trim() !== ""
                ? oldParcela.descricao
                : "Tratamento dentário"
            );
            formData.append("contactoCliente", contaCorrente.cliente.contacto);
            formData.append("moradaCliente", contaCorrente.cliente.endereco);
            formData.append(
              "codigoPaisCliente",
              contaCorrente.cliente.pais.codigo
            );
            formData.append("metodoPagamento", oldParcela.metodoPagamento);
            formData.append("tipoDocEletronico", tipoDocumento);
            formData.append(
              "IssueReasonCode",
              JSON.stringify(motivoCancelamento)
            );
            formData.append("online", true);
          }
          if (tipoDocumento !== "Event") {
            try {
              respostaDocumentoCancelamento = await sendRequest(
                `${process.env.REACT_APP_BACKEND_LINK}/api/documentoeletronico/emitirdocumentoeletronico`,
                "POST",
                formData,
                {
                  Authorization: "Bearer " + auth.token,
                }
              );
            } catch (err) {
              console.error("err", err);
            }
          } else {
            formData.append("IssueReasonDescription", motivoCancelamento);
            formData.append(
              "idDocumentoAnular",
              oldParcela.documentoEletronico._id
            );
            try {
              respostaDocumentoCancelamento = await sendRequest(
                `${process.env.REACT_APP_BACKEND_LINK}/api/documentoeletronico/emitirevento`,
                "POST",
                formData,
                {
                  Authorization: "Bearer " + auth.token,
                }
              );
            } catch (err) {
              console.err(err);
            }
          }

          if (respostaDocumentoCancelamento && idDocumentoAEliminar) {
            try {
              await sendRequest(
                `${process.env.REACT_APP_BACKEND_LINK}/api/documentoeletronico/${idDocumentoAEliminar}`,
                "DELETE",
                {},
                {
                  Authorization: "Bearer " + auth.token,
                }
              );
            } catch (err) {
              console.error("err", err);
            }
          }
        }
      } else if (oldParcela.documentoEletronico?.succeeded === false) {
        try {
          await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/documentoeletronico/${oldParcela.documentoEletronico._id}`,
            "DELETE",
            {},
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
        }
      }

      let newParcela;

      //Se o envio do documentoCancelamento for bem sucedido ou se não existia FRE, a parcela fica atualizada como não paga
      if (
        (respostaDocumentoCancelamento?.respostaEfatura?.responses[0]
          ?.succeeded &&
          oldParcela.documentoEletronico) ||
        !oldParcela.documentoEletronico ||
        oldParcela.documentoEletronico?.succeeded === false
      ) {
        newParcela = {
          id: oldParcela.id,
          _id: oldParcela.id,
          dataLimitePagamento: oldParcela.dataLimitePagamento,
          parcelaPaga: false,
          quantiaPaga: 0,
          quantiaPorPagar: oldParcela.quantiaPorPagar + oldParcela.quantiaPaga,
          recebimentoCanceladoPor: auth.userId,
        };
      } else {
        newParcela = { ...oldParcela };
      }

      //Atualizando os campos que têm key value pairs de cancelamentos caso havia documento eletronico na parcela antiga
      //Se houve falha, o documento não fica a cancelado, porém fica com um doc de cancelamento com erro em cancelamentoDocumentosEletronicos.documentoCancelamento
      //Caso recebermos algum tipo de resposta também faz parte da condição porque as vezes não temos resposta nenhuma
      if (
        oldParcela.documentoEletronico?.succeeded &&
        respostaDocumentoCancelamento
      ) {
        if (oldParcela.cancelamentosDocumentosEletronicos) {
          newParcela.cancelamentosDocumentosEletronicos = [
            ...oldParcela.cancelamentosDocumentosEletronicos,
            {
              documento: oldParcela.documentoEletronico._id,
              documentoCancelamento:
                respostaDocumentoCancelamento.respostaDocumentoEletronico._id,
            },
          ];
        } else {
          newParcela.cancelamentosDocumentosEletronicos = [
            {
              documento: oldParcela.documentoEletronico._id,
              documentoCancelamento:
                respostaDocumentoCancelamento.respostaDocumentoEletronico._id,
            },
          ];
        }
      }

      if (oldParcela.nrParcela) newParcela.nrParcela = oldParcela.nrParcela;
      if (oldParcela.entrada) newParcela.entrada = oldParcela.entrada;

      const newParcelas = filteredConta.parcelas.map((parcela) => {
        if (parcela.id === newParcela.id) {
          return newParcela;
        } else {
          return parcela;
        }
      });

      filteredConta.parcelas = newParcelas;

      //A conta fica liquidada a false caso houver sucesso no envio do documento
      //Que resulta no posteriro cancelamento do doc eletronico
      if (
        (respostaDocumentoCancelamento?.respostaEfatura?.responses[0]
          ?.succeeded &&
          oldParcela.documentoEletronico) ||
        !oldParcela.documentoEletronico
      ) {
        filteredConta.contaLiquidada = false;
      }

      const nrParcelasPagas = filteredConta.parcelas.filter(
        (parcela) => parcela.parcelaPaga
      ).length;

      //Aqui devo fazer o update independentemente se houve ou não erro pois se houve erro no envio de dfe, ainda assim necessito guardar o doc
      //eletronico com erro na parcela
      try {
        const formData = new FormData();
        formData.append("contas", JSON.stringify(contaCorrente.contas));
        formData.append("operacao", "CANCELAMENTO_RECEBIMENTO");
        formData.append(
          "alteracoes",
          JSON.stringify({
            "Número da parcela": {
              anterior: oldParcela.nrParcela,
              novo: oldParcela.nrParcela,
            },
            Valor: {
              anterior: oldParcela.quantiaPaga,
              novo: oldParcela.quantiaPaga,
            },
            "Número de orçamento": {
              anterior: filteredConta.orcamento.codigo,
              novo: filteredConta.orcamento.codigo,
            },
          })
        );
        formData.append("utilizador", auth.userId);
        formData.append("nomeCliente", contaCorrente.cliente.nome);

        const contaCorrenteRetornada = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/contasCorrentes/${contaCorrente.id}`,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        setContaCorrente(contaCorrenteRetornada.contaCorrente);
      } catch (err) {
        console.error("err", err);
      }
      /////////////////////////////////////////////////////

      //Atualizar o plano de tratamento para que este não possa ser apagado
      //Caso essa for a primeria parcela a ser paga. Se não, isso quer dizer que
      //O campo canDelete já está a true e nao necessita ser atualizado
      if (nrParcelasPagas === 0) {
        let planoTratamento;
        try {
          planoTratamento = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/planostratamento/orcamento/${filteredConta.orcamento.id}`,
            "GET",
            null,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
        }

        const formData = new FormData();
        formData.append("canDelete", true);
        try {
          await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/planostratamento/candelete/${planoTratamento.planosTratamento[0].id}`,
            "PATCH",
            formData,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
        }
      }

      if (
        respostaDocumentoCancelamento?.respostaEfatura?.responses[0]?.succeeded
      ) {
        toast.success(
          `Cancelamento efetuado com sucesso! ${
            respostaDocumentoCancelamento?.respostaEfatura?.responses[0]
              ?.succeeded
              ? ` ${tipoDocumento} emitido com sucesso!`
              : ""
          } `
        );
      } else if (
        respostaDocumentoCancelamento?.respostaEfatura?.responses[0]
          ?.succeeded === false
      ) {
        toast.error(
          `Erro ao efetuar cancelamento. ${tipoDocumento} com erro: ${respostaDocumentoCancelamento?.respostaEfatura?.responses[0]?.messages[0].description}`
        );
      } else {
        toast.success(`Cancelamento efetuado com Sucesso!`);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  const verReceitaHandler = (id, idConta) => {
    const contaFiltrada = contaCorrente.contas.filter(
      (conta) => conta.id === idConta
    )[0];
    const parcelaFiltrada = contaFiltrada.parcelas.filter(
      (parcela) => parcela.id === id
    )[0];

    const dadosReceita = {
      metodoPagamento: parcelaFiltrada.metodoPagamento,
      valorPago: parcelaFiltrada.quantiaPaga,
      dataRecebimento: parcelaFiltrada.dataPagamento,
      observacao: parcelaFiltrada.observacao,
      caixa: parcelaFiltrada.caixa,
      recebidoPor: parcelaFiltrada.recebidoPor?.user?.name,
    };

    if (parcelaFiltrada.cartao) dadosReceita.cartao = parcelaFiltrada.cartao;
    if (parcelaFiltrada.documentoEletronico)
      dadosReceita.documentoEletronico = parcelaFiltrada.documentoEletronico;
    if (parcelaFiltrada.metodoPagamento === "Cheque") {
      dadosReceita.nrCheque = parcelaFiltrada.nrCheque;
      dadosReceita.dataCheque = parcelaFiltrada.dataCheque;
      dadosReceita.contaCliente = parcelaFiltrada.nrContaCliente;
      dadosReceita.banco = parcelaFiltrada.banco;
    }

    setModalData(dadosReceita);
    setOpenModal("verReceita");
  };

  const gerarPdfFaturaEletronicaHandler = async (id, idConta) => {
    const contaFiltrada = contaCorrente.contas.filter(
      (conta) => conta.id === idConta
    )[0];
    const parcelaFiltrada = contaFiltrada.parcelas.filter(
      (parcela) => parcela.id === id
    )[0];

    dadosFatura({
      clinica: dadosClinica,
      cliente: clienteData,
      valor: parcelaFiltrada.quantiaPaga,
      nrDocumento: parcelaFiltrada.documentoEletronico.nrDocumento,
      dataDocumento: parcelaFiltrada.documentoEletronico.authorizedDateTime,
      medico_responsavel: contaFiltrada.orcamento.medico_responsavel,
      designacao:
        parcelaFiltrada.descricao && parcelaFiltrada.descricao.trim() !== ""
          ? parcelaFiltrada.descricao
          : "Tratamento Dentário",
      metodoPagamento: parcelaFiltrada.metodoPagamento,
      entryName: parcelaFiltrada.documentoEletronico.entryName,
      serie: parcelaFiltrada.documentoEletronico.serie,
      tipoDocumento: parcelaFiltrada.documentoEletronico.tipoDocumento,
      usuarioEmissor: parcelaFiltrada.documentoEletronico.usuarioEmissor?.name,
    });
  };

  const gerarPdfFaturaEletronicaHandlerEntrada = async (entradaId) => {
    const entradaFiltrada = entradas.find((e) => e.id === entradaId);

    dadosFatura({
      clinica: dadosClinica,
      cliente: clienteData,
      valor: entradaFiltrada.valor,
      nrDocumento: entradaFiltrada.documentoEletronico.nrDocumento,
      dataDocumento: entradaFiltrada.documentoEletronico.authorizedDateTime,
      designacao: entradaFiltrada.descricao,
      metodoPagamento: entradaFiltrada.metodoPagamento,
      entryName: entradaFiltrada.documentoEletronico.entryName,
      serie: entradaFiltrada.documentoEletronico.serie,
      tipoDocumento: entradaFiltrada.documentoEletronico.tipoDocumento,
      usuarioEmissor: entradaFiltrada.documentoEletronico.usuarioEmissor?.name,
    });
  };

  const beforeGerarReciboHandler = async (id, idConta) => {
    startLoading();
    try {
      const contaFiltrada = contaCorrente.contas.filter(
        (conta) => conta._id === idConta
      )[0];

      const parcelaFiltrada = contaFiltrada.parcelas.filter(
        (parcela) => parcela._id === id
      )[0];

      let nrRecibo = 1;
      if (parcelaFiltrada.nrRecibo) {
        nrRecibo = parcelaFiltrada.nrRecibo;
        gerarPdfReciboHandler(parcelaFiltrada, nrRecibo);
      } else {
        let idDocumentoAEliminar;
        //SE existe 1 documento eletronico emitido com erro anteriormente
        if (parcelaFiltrada.documentoEletronico?.succeeded === false) {
          idDocumentoAEliminar = parcelaFiltrada.documentoEletronico._id;
        }
        try {
          const nrDocumentos = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/numeracaodocumentos/${dadosClinica._id}`,
            "GET",
            null,
            {
              Authorization: "Bearer " + auth.token,
            }
          );

          nrRecibo = nrDocumentos.numeracaoDocumento.numeracoes.recibo + 1;
        } catch (err) {
          console.error("err", err);
        }

        //Atualizar o numero de recibo
        try {
          const formData = new FormData();
          formData.append("valor", nrRecibo);
          formData.append("tipoDocumento", "recibo");
          await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/numeracaodocumentos/${dadosClinica._id}`,
            "PATCH",
            formData,
            {
              Authorization: "Bearer " + auth.token,
            }
          );

          if (idDocumentoAEliminar) {
            try {
              await sendRequest(
                `${process.env.REACT_APP_BACKEND_LINK}/api/documentoeletronico/${idDocumentoAEliminar}`,
                "DELETE",
                formData,
                {
                  Authorization: "Bearer " + auth.token,
                }
              );
            } catch (err) {
              console.error("err", err);
            }
          }
        } catch (err) {
          console.error("err", err);
        }

        try {
          const formData = new FormData();
          formData.append("parcelaId", id);
          formData.append("contaId", idConta);
          formData.append("nrRecibo", nrRecibo);
          formData.append("utilizador", auth.userId);
          formData.append("nomeCliente", contaCorrente.cliente.nome);

          //Na backend, documento eletronico é removido da parcela
          const contaCorrenteRetornada = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/contascorrentes/gerarrecibo/${contaCorrente.id}`,
            "PATCH",
            formData,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
          updateContaCorrente(contaCorrenteRetornada.contaCorrente);
        } catch (err) {
          console.error(
            "Erro na atualização da conta corrente. Por favor tente novamente.",
            err
          );
        }
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  const gerarPdfReciboHandler = async (parcelaFiltrada, nrRecibo) => {
    dadosRecibo({
      clinica: dadosClinica,
      cliente: clienteData,
      valor: parcelaFiltrada.quantiaPaga || parcelaFiltrada.valor,
      nrRecibo: nrRecibo,
      descricao: parcelaFiltrada.descricao,
    });
  };

  const emitirDocumentoFiscal = async (tipoDocumento, parc) => {
    startLoading();
    try {
      setOpenModal("");
      if (!contaCorrente.cliente.nif && tipoDocumento !== "TVE") {
        toast.error("O cliente não possui um nif");
      } else if (
        tipoDocumento !== "TVE" &&
        (!contaCorrente.cliente.nomeFiscal ||
          contaCorrente.cliente.nomeFiscal.trim() === "")
      ) {
        toast.error("O cliente não possui um nome fiscal!");
      } else if (
        (contaCorrente.cliente.nif === "000000000" ||
          contaCorrente.cliente.nif === "999999999") &&
        tipoDocumento === "FRE"
      ) {
        toast.error("Nifs 000000000 ou 999999999 não são válidos para FREs.");
      } else if (
        !contaCorrente.cliente.contacto ||
        contaCorrente.cliente.contacto.trim() === ""
      ) {
        toast.error("O cliente não possui um número de telefone");
      } else if (
        !contaCorrente.cliente.endereco ||
        contaCorrente.cliente.endereco.trim() === ""
      ) {
        toast.error("O cliente não possui um endereço");
      } else if (!contaCorrente.cliente.pais) {
        toast.error("O cliente não possui um pais");
      } else if (
        tipoDocumento === "TVE"
          ? false
          : await checkNifError(
              contaCorrente.cliente.nif,
              contaCorrente.cliente.nomeFiscal,
              contaCorrente.cliente.pais.nome
            )
      ) {
        toast.error("nif incomaptivel com o nome do cliente");
      } else {
        let parcelaFiltrada = parc;
        let contaFiltrada;
        let idDocumentoAEliminar;
        //Pode ser enviado o id da parcela ou a propria parcela.
        //Se for enviado o id da parcela, ela necessita ser filtrada
        if (parc._id) {
          contaCorrente.contas.forEach((conta) => {
            conta.parcelas.forEach((p) => {
              if (p._id === parc._id) contaFiltrada = conta;
            });
          });
        } else {
          contaCorrente.contas.forEach((conta) => {
            conta.parcelas.forEach((p) => {
              if (p._id === parc) {
                contaFiltrada = conta;
                parcelaFiltrada = p;
              }
            });
          });
        }

        //SE existe 1 documento eletronico emitido com erro anteriormente
        if (
          (tipoDocumento === "FRE" || tipoDocumento === "TVE") &&
          parcelaFiltrada.documentoEletronico?.succeeded === false
        ) {
          idDocumentoAEliminar = parcelaFiltrada.documentoEletronico._id;
        } else if (
          tipoDocumento === "NCE" &&
          parcelaFiltrada.cancelamentosDocumentosEletronicos[0]
            ?.documentoCancelamento?.succeeded === false
        ) {
          idDocumentoAEliminar =
            parcelaFiltrada.cancelamentosDocumentosEletronicos[0]
              .documentoCancelamento._id;
        }

        const formData = new FormData();
        formData.append("clinicaId", clinica.clinica._id);
        if (tipoDocumento !== "TVE") {
          formData.append("nomeCliente", contaCorrente.cliente.nomeFiscal);
          formData.append("nifCliente", contaCorrente.cliente.nif);
          formData.append("contactoCliente", contaCorrente.cliente.contacto);
          formData.append(
            "codigoPaisCliente",
            contaCorrente.cliente.pais.codigo
          );
          formData.append("moradaCliente", contaCorrente.cliente.endereco);
        }
        formData.append("valor", parcelaFiltrada.quantiaPaga);
        formData.append(
          "descricaoPagamento",
          parcelaFiltrada.descricao && parcelaFiltrada.descricao.trim() !== ""
            ? parcelaFiltrada.descricao
            : "Tratamento dentário"
        );
        formData.append("metodoPagamento", parcelaFiltrada.metodoPagamento);
        formData.append("tipoDocEletronico", tipoDocumento);
        formData.append("usuarioEmissor", auth.userId);
        formData.append("contaCorrente", contaCorrente._id);
        formData.append("conta", contaFiltrada._id);
        formData.append("parcela", parcelaFiltrada._id);
        formData.append("online", true);
        if (tipoDocumento === "NCE") {
          formData.append(
            "IUDCancelar",
            parcelaFiltrada.documentoEletronico.entryName.replace(/\.xml$/, "")
          );
        }

        let resposta;
        try {
          resposta = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/documentoeletronico/emitirdocumentoeletronico`,
            "POST",
            formData,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
        }

        ///SE NÂO HOUVER RESPOSTA DA EFATURA, cancelamos a execução da função pois a função só é responsável pela emissão de documentos eletrónicos
        if (!resposta) {
          toast.error(
            "Não houve resposta da Efatura. Por favor tente emitir o documento eletrónico novamente"
          );
          return;
        }

        if (tipoDocumento === "NCE") {
          const formData2 = new FormData();
          const novasContas = [...contaCorrente.contas];
          contaCorrente.contas.forEach((c) => {
            c.parcelas.forEach((p) => {
              if (p._id === parcelaFiltrada._id) {
                if (p.cancelamentosDocumentosEletronicos) {
                  p.cancelamentosDocumentosEletronicos.push({
                    documentoCancelamento:
                      resposta.respostaDocumentoEletronico._id,
                    documento: parcelaFiltrada.documentoEletronico._id,
                  });
                } else {
                  p.cancelamentosDocumentosEletronicos = [
                    {
                      documentoCancelamento:
                        resposta.respostaDocumentoEletronico._id,
                      documento: parcelaFiltrada.documentoEletronico._id,
                    },
                  ];
                }
                p.documentoEletronico = null;
              }
            });
          });
          formData2.append("contas", JSON.stringify(novasContas));
          let novaContaCorrente;
          try {
            novaContaCorrente = await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/contascorrentes/${contaCorrente._id}`,
              "PATCH",
              formData2,
              {
                Authorization: "Bearer " + auth.token,
              }
            );
            setContaCorrente(novaContaCorrente.contaCorrente);
          } catch (err) {
            console.error("err", err);
          }
        } else {
          // Atualizar o documento eletronico na parcela na base de dados
          const novasContas = contaCorrente.contas;
          novasContas.forEach((c) => {
            c.parcelas.forEach((p) => {
              if (p.id === parcelaFiltrada.id)
                p.documentoEletronico =
                  resposta.respostaDocumentoEletronico._id;
            });
          });

          const formData2 = new FormData();
          formData2.append("contas", JSON.stringify(novasContas));
          let novaContaCorrente;
          try {
            novaContaCorrente = await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/contascorrentes/${contaCorrente.id}`,
              "PATCH",
              formData2,
              {
                Authorization: "Bearer " + auth.token,
              }
            );
          } catch (err) {
            console.error("err", err);
          }
          setContaCorrente(novaContaCorrente.contaCorrente);
        }

        if (resposta && idDocumentoAEliminar) {
          try {
            await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/documentoeletronico/${idDocumentoAEliminar}`,
              "DELETE",
              formData,
              {
                Authorization: "Bearer " + auth.token,
              }
            );
          } catch (err) {
            console.error("err", err);
          }
        }

        if (resposta?.respostaEfatura?.responses[0]?.succeeded) {
          toast.success(`${tipoDocumento} emitido com sucesso`);
        } else {
          toast.error(
            "Erro ao emitir" +
              tipoDocumento +
              ". " +
              resposta?.respostaEfatura?.responses[0]?.messages[0]?.description
          );
        }
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  const handleBeforeEmitirDocEletronico = (id, idConta, tipoDocumento) => {
    const contaFiltrada = contaCorrente.contas.filter(
      (conta) => conta.id === idConta
    )[0];

    const parcelaFiltrada = contaFiltrada.parcelas.filter(
      (parcela) => parcela.id === id
    )[0];

    emitirDocumentoFiscal(tipoDocumento, parcelaFiltrada);
  };

  const handleOpenModalEditarDesconto = (conta) => {
    setModalData(conta);
    setOpenModal("editarDesconto");
  };

  const handleOpenModalCancelamentoPendencias = (conta) => {
    setModalData(conta);
    setOpenModal("cancelarPendencias");
  };

  const cancelarIsencaoHandler = async (contaFiltrada) => {
    try {
      handleModalCancel();
      startLoading();
      const novaConta = {
        ...contaFiltrada,
        contaLiquidada: false,
        nrParcelas: 1,
        orcamento: contaFiltrada.orcamento._id,
        total: contaFiltrada.orcamento.subtotal,
        parcelas: [
          {
            cancelamentosDocumentosEletronicos:
              contaFiltrada.parcelas[0].cancelamentosDocumentosEletronicos,
            dataLimitePagamento: contaFiltrada.parcelas[0].dataLimitePagamento,
            parcelaPaga: false,
            quantiaPaga: 0,
            quantiaPorPagar: contaFiltrada.orcamento.subtotal,
            nrParcela: "1/1",
            observacao: "",
          },
        ],
      };

      const formData = new FormData();
      formData.append("desconto", 0);
      formData.append("utilizador", auth.userId);
      formData.append("tipoDesconto", "$");
      formData.append("total", contaFiltrada.orcamento.subtotal);
      formData.append("subtotal", contaFiltrada.orcamento.subtotal);

      try {
        await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/orcamentos/dadosdesconto/${contaFiltrada.orcamento._id}`,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
      } catch (err) {
        console.error("err", err);
      }

      const contas = contaCorrente.contas.filter(
        (c) => c._id !== contaFiltrada._id
      );
      contas.push(novaConta);
      const formData2 = new FormData();
      formData2.append("contas", JSON.stringify(contas));

      formData2.append("operacao", "CANCELAMENTO_ISENCAO");
      formData2.append(
        "alteracoes",
        JSON.stringify({
          "Total do orçamento": {
            anterior: 0,
            novo: contaFiltrada.orcamento.subtotal,
          },
          "Número de orçamento": {
            anterior: contaFiltrada.orcamento.codigo,
            novo: contaFiltrada.orcamento.codigo,
          },
        })
      );
      formData2.append("utilizador", auth.userId);
      formData2.append("nomeCliente", contaCorrente.cliente.nome);

      let contaCorrenteParaState;
      try {
        contaCorrenteParaState = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/contascorrentes/${contaCorrente._id}`,
          "PATCH",
          formData2,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
      } catch (err) {
        console.error("err", err);
      }
      toast.success("Isenção cancelada com sucesso!");

      updateContaCorrente(contaCorrenteParaState.contaCorrente);
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  const handleConfirmCancelarIsencao = (conta) => {
    setModalProps({
      show: true,
      icon: "info",
      title: `Cancelar Isenção!`,
      message: `Tem a certeza que pretende cancelar a isenção da conta?`,
      form: true,
      onCancel: handleModalCancel,
      onConfirm: cancelarIsencaoHandler.bind(null, conta),
    });
  };

  const verEntradaHandler = (id) => {
    const entradaFiltrada = entradas.filter((entr) => entr.id === id)[0];
    setModalData(entradaFiltrada);
    setOpenModal("verEntrada");
  };

  const handleRestaurarPendencias = async (conta) => {
    startLoading();
    try {
      const formData = new FormData();
      formData.append("contaCorrenteId", contaCorrente._id);
      formData.append("contaId", conta._id);

      const contaCorrenteRetornada = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/contascorrentes/restaurarPendencias/${clinica.clinica._id}`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );
      toast.success("Conta restaurada com sucesso!");
      updateContaCorrente(contaCorrenteRetornada.contaCorrente);
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
      handleModalCancel();
    }
  };

  const desativarEntradaHandler = async (id) => {
    startLoading();
    const filteredEntrada = entradas.find((e) => e._id === id);
    try {
      const formDataEntradaDesativar = new FormData();
      formDataEntradaDesativar.append("utilizador", auth.userId);
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/entradas/toggleativo/${id}`,
        "PATCH",
        formDataEntradaDesativar,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      //Se a entrada for referente à cobrança de uma avaliação, essa avaliação deixa de ser debitada
      if (filteredEntrada.avaliacao) {
        await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/avaliacoes/removerdebitodeavaliacao/${filteredEntrada.avaliacao}`,
          "PATCH",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
      }

      const tempEntradas = [...entradas];

      const indexEntrada = tempEntradas.findIndex(
        (entrada) => entrada._id === id
      );
      tempEntradas.splice(indexEntrada, 1);
      setEntradas([...tempEntradas]);

      toast.success(
        `Entrada desativada com sucesso!${
          filteredEntrada.avaliacao
            ? " A avaliação volta ao estado não debitado."
            : ""
        }`
      );
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  const editarEntradaHandler = (id) => {
    const entradaFiltrada = entradas.find((e) => e._id === id);
    if (!entradaFiltrada) {
      toast.error("Entrada não encontrada!");
      return;
    }
    setModalData({ open: "editarEntrada", entrada: entradaFiltrada });
  };

  const updateEntradas = (entradaRetornada, action) => {
    if (action === "inserir") {
      //Inserir
      const tempEntradas = [...entradas];
      tempEntradas.push(entradaRetornada);
      setEntradas([...tempEntradas]);
      stopLoading();
    } else {
      //Atualizar
      let tempEntradas;

      tempEntradas = entradas.map((e) => {
        if (e._id === entradaRetornada._id) {
          return entradaRetornada;
        } else {
          return e;
        }
      });

      setEntradas([...tempEntradas]);
      stopLoading();
    }
  };

  //Emitir FRE ou TVE para Entradas
  const emitirDocEletronicoReembolso = async (
    entrada,
    idEntrada,
    tipoDocumento,
    operacao
  ) => {
    startLoading();
    try {
      let entradaFiltrada;
      let idDocumentoAEliminar;
      if (entrada) {
        entradaFiltrada = entrada;
      } else {
        entradaFiltrada = entradas.filter((e) => e._id === idEntrada)[0];
      }

      if (!clienteData.nif && tipoDocumento !== "TVE") {
        toast.error("O cliente não possui um nif");
        updateEntradas(
          {
            ...entradaFiltrada,
          },
          operacao
        );
      } else if (
        (clienteData.nif === "000000000" || clienteData.nif === "999999999") &&
        tipoDocumento === "FRE"
      ) {
        toast.error("Nifs 000000000 ou 999999999 não são válidos para FREs.");
        updateEntradas(
          {
            ...entradaFiltrada,
          },
          operacao
        );
      } else if (!clienteData.contacto) {
        toast.error("O cliente não possui um número de telefone");
        updateEntradas(
          {
            ...entradaFiltrada,
          },
          operacao
        );
      } else if (!clienteData.endereco) {
        toast.error("O cliente não possui um endereço");
        updateEntradas(
          {
            ...entradaFiltrada,
          },
          operacao
        );
      } else if (!clienteData.pais) {
        toast.error("O cliente não possui um pais");
        updateEntradas(
          {
            ...entradaFiltrada,
          },
          operacao
        );
      } else if (
        tipoDocumento === "TVE"
          ? false
          : await checkNifError(
              clienteData.nif,
              clienteData.nomeFiscal,
              clienteData.pais.nome
            )
      ) {
        toast.error("nif incomaptivel com o nome do cliente");
        updateEntradas(
          {
            ...entradaFiltrada,
          },
          operacao
        );
      } else {
        //SE existe 1 documento eletronico emitido com erro anteriormente
        if (
          (tipoDocumento === "FRE" || tipoDocumento === "TVE") &&
          entradaFiltrada.documentoEletronico?.succeeded === false
        ) {
          idDocumentoAEliminar = entradaFiltrada.documentoEletronico._id;
        }

        const formData = new FormData();
        formData.append("clinicaId", clinica.clinica._id);
        if (tipoDocumento !== "TVE") {
          formData.append("nomeCliente", clienteData.nomeFiscal);
          formData.append("nifCliente", clienteData.nif);
          formData.append("contactoCliente", clienteData.contacto);
          formData.append("moradaCliente", clienteData.endereco);
          formData.append("codigoPaisCliente", clienteData.pais.codigo);
        }

        formData.append("valor", entradaFiltrada.valor);
        formData.append("descricaoPagamento", entradaFiltrada.descricao);
        formData.append("metodoPagamento", entradaFiltrada.metodoPagamento);
        formData.append("tipoDocEletronico", tipoDocumento);
        formData.append("usuarioEmissor", auth.userId);
        formData.append("entrada", entradaFiltrada._id);
        formData.append("online", true);

        let resposta;
        try {
          resposta = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/documentoeletronico/emitirdocumentoeletronico`,
            "POST",
            formData,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
        }

        if (resposta && idDocumentoAEliminar) {
          try {
            await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/documentoeletronico/${idDocumentoAEliminar}`,
              "DELETE",
              formData,
              {
                Authorization: "Bearer " + auth.token,
              }
            );
          } catch (err) {
            console.error("err", err);
          }
        }

        const formData2 = new FormData();
        formData2.append(
          "documentoEletronico",
          resposta.respostaDocumentoEletronico._id
        );
        let novaEntrada;

        try {
          novaEntrada = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/entradas/documentoeletronico/${entradaFiltrada.id}`,
            "PATCH",
            formData2,
            {
              Authorization: "Bearer " + auth.token,
            }
          );

          updateEntradas(
            {
              ...entradaFiltrada,
              documentoEletronico: novaEntrada.entrada.documentoEletronico,
            },
            operacao
          );

          toast.success(`${tipoDocumento} emitido com sucesso.`);
        } catch (err) {
          console.error("err", err);
          toast.error(
            "Documento eletrónico emitido, mas não associado à entrada. Contacte a equipa de suporte para resolver o problema!"
          );
        }
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  const gerarReciboInternoEntradas = async (idEntrada) => {
    startLoading();
    try {
      const entradaFiltrada = entradas.find((e) => e._id === idEntrada);
      let entradaRetornada;
      let nrRecibo = 1;
      if (entradaFiltrada.nrRecibo) {
        nrRecibo = entradaFiltrada.nrRecibo;
        gerarPdfReciboHandler(entradaFiltrada, nrRecibo);
      } else {
        let idDocumentoAEliminar;
        //SE existe 1 documento eletronico emitido com erro anteriormente
        if (entradaFiltrada.documentoEletronico?.succeeded === false) {
          idDocumentoAEliminar = entradaFiltrada.documentoEletronico._id;
        }
        try {
          const nrDocumentos = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/numeracaodocumentos/${dadosClinica._id}`,
            "GET",
            null,
            {
              Authorization: "Bearer " + auth.token,
            }
          );

          nrRecibo = nrDocumentos.numeracaoDocumento.numeracoes.recibo + 1;
        } catch (err) {
          console.error("err", err);
        }

        //Atualizar o numero de recibo
        const formData = new FormData();
        formData.append("valor", nrRecibo);
        formData.append("tipoDocumento", "recibo");
        try {
          await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/numeracaodocumentos/${dadosClinica._id}`,
            "PATCH",
            formData,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
        }

        try {
          const formData = new FormData();
          formData.append("nrRecibo", nrRecibo);
          formData.append("utilizador", auth.userId);

          //Na backend, documento eletronico é removido da entrada
          const tempEntradaRetornada = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/entradas/gerarrecibo/${idEntrada}`,
            "PATCH",
            formData,
            {
              Authorization: "Bearer " + auth.token,
            }
          );

          entradaRetornada = tempEntradaRetornada.entrada;

          if (entradaRetornada && idDocumentoAEliminar) {
            try {
              await sendRequest(
                `${process.env.REACT_APP_BACKEND_LINK}/api/documentoeletronico/${idDocumentoAEliminar}`,
                "DELETE",
                formData,
                {
                  Authorization: "Bearer " + auth.token,
                }
              );
            } catch (err) {
              console.error("err", err);
            }
          }

          toast.success("Recibo gerado com sucesso!");
          updateEntradas(entradaRetornada, "atualizar");
        } catch (err) {
          console.error("err", err);
        }
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  const handleOpenModalReceberEntrada = (id) => {
    const entradaFiltada = entradas.find((e) => e.id === id);
    if (!entradaFiltada) {
      toast.error("Não foi possivel encontrar a entrada");
      return;
    }
    setModalData({ open: "receberEntrada", entrada: entradaFiltada });
  };

  const handleBeforeCancelarRecebimentoEntrada = (
    id,
    enviarDocCancelamento
  ) => {
    const entradaFiltrada = entradas.find((e) => e.id === id);

    if (!entradaFiltrada) {
      toast.error("Entrada não encontrada!");
      return;
    }
    if (enviarDocCancelamento) {
      setModalData({
        open: "motivoCancelamentoEntradas",
        entrada: entradaFiltrada,
        tipoDocumento: "NCE",
        tipoDocumentoAnular: "FRE",
      });
    } else {
      handleCancelarRecebimentoEntrada(entradaFiltrada);
    }
  };

  const handleCancelarRecebimentoEntrada = async (
    entradaFiltrada,
    enviarDocCancelamento,
    motivoCancelamento,
    tipoDocumento
  ) => {
    startLoading();
    try {
      let novaEntrada = entradaFiltrada;
      let resposta;

      //Enviar documento de cancelamento quando a entrada tem 1 doc eletronico emitido
      //E bem sucedido
      if (enviarDocCancelamento) {
        const formData = new FormData();
        formData.append("clinicaId", clinica.clinica._id);
        formData.append("usuarioEmissor", auth.userId);
        formData.append("entrada", entradaFiltrada._id);
        formData.append("valor", entradaFiltrada.valor);

        if (tipoDocumento !== "Event") {
          formData.append(
            "IUDCancelar",
            entradaFiltrada.documentoEletronico.entryName.replace(/\.xml$/, "")
          );
          formData.append(
            "IssueReasonCode",
            JSON.stringify(motivoCancelamento)
          );
          formData.append("nomeCliente", clienteData.nomeFiscal);
          formData.append("nifCliente", clienteData.nif);
          formData.append("descricaoPagamento", entradaFiltrada.descricao);
          formData.append("contactoCliente", clienteData.contacto);
          formData.append("moradaCliente", clienteData.endereco);
          formData.append("codigoPaisCliente", clienteData.pais.codigo);
          formData.append("metodoPagamento", entradaFiltrada.metodoPagamento);
          formData.append("dataPagamento", entradaFiltrada.dataPagamento);
          formData.append("tipoDocEletronico", tipoDocumento);
          formData.append("online", true);
        }

        let idDocumentoAEliminar;

        //SE existe 1 documento eletronico emitido com erro anteriormente
        if (
          (tipoDocumento === "Event" || tipoDocumento === "NCE") &&
          entradaFiltrada.cancelamentosDocumentosEletronicos[
            entradaFiltrada.cancelamentosDocumentosEletronicos?.length - 1
          ]?.documentoCancelamento?.succeeded === false
        ) {
          idDocumentoAEliminar =
            entradaFiltrada.cancelamentosDocumentosEletronicos[
              entradaFiltrada.cancelamentosDocumentosEletronicos?.length - 1
            ].documentoCancelamento._id;
        }

        //Emitir NCE e guardar em resposta
        if (tipoDocumento !== "Event") {
          try {
            resposta = await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/documentoeletronico/emitirdocumentoeletronico`,
              "POST",
              formData,
              {
                Authorization: "Bearer " + auth.token,
              }
            );
          } catch (err) {
            console.error("err", err);
          }
          //CASO EMITIR EVENTO:
        } else {
          formData.append(
            "idDocumentoAnular",
            entradaFiltrada.documentoEletronico._id
          );
          formData.append(
            "IUDCancelar",
            entradaFiltrada.documentoEletronico.entryName.replace(/\.xml$/, "")
          );
          formData.append("IssueReasonDescription", motivoCancelamento);
          try {
            resposta = await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/documentoeletronico/emitirevento`,
              "POST",
              formData,
              {
                Authorization: "Bearer " + auth.token,
              }
            );
          } catch (err) {
            console.error("err", err);
          }
        }

        //Se existe documento de cancelamento a ser eliminado, eliminamo-lo e atualizamos a novaEntrada
        if (resposta && idDocumentoAEliminar) {
          try {
            await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/documentoeletronico/${idDocumentoAEliminar}`,
              "DELETE",
              {},
              {
                Authorization: "Bearer " + auth.token,
              }
            );

            const entradaRetornada = await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/entradas/removelastcancelamento/${entradaFiltrada._id}`,
              "PATCH",
              {},
              {
                Authorization: "Bearer " + auth.token,
              }
            );
            novaEntrada = entradaRetornada.entrada;
          } catch (err) {
            console.error("err", err);
          }
        }

        ////////////////////////////////////////////////////////
        // Atualizar o documento eletronico na entrada na base de dados
        const formData2 = new FormData();
        let tempNovaEntrada;
        formData2.append(
          "documentoCancelamento",
          resposta.respostaDocumentoEletronico._id
        );
        formData2.append(
          "documentoEletronico",
          entradaFiltrada.documentoEletronico._id
        );

        //Se houver sucesso na emissao do cancelamento, eliminar o doc eletronico da entrada
        if (resposta?.respostaEfatura?.responses[0]?.succeeded === true) {
          formData2.append("removerDocEletronico", true);
        }

        tempNovaEntrada = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/entradas/aposcancelamentodoceletronico/${entradaFiltrada.id}`,
          "PATCH",
          formData2,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        if (tempNovaEntrada?.entrada) {
          novaEntrada = tempNovaEntrada.entrada;
        }
        ////////////////////////////////////////////////////////
        //Caso a entrada não tiver 1 doc eletronico emitido com sucesso, remover o doc eletronico se esse não for bem sucedido
      } else if (entradaFiltrada.documentoEletronico?.succeeded === false) {
        try {
          await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/documentoeletronico/${entradaFiltrada.documentoEletronico._id}`,
            "DELETE",
            {},
            {
              Authorization: "Bearer " + auth.token,
            }
          );

          const entradaRetornada = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/entradas/clearDocumentoEletronico/${entradaFiltrada._id}`,
            "PATCH",
            {},
            {
              Authorization: "Bearer " + auth.token,
            }
          );
          novaEntrada = entradaRetornada.entrada;
        } catch (err) {
          console.error("err", err);
        }
      }

      //Agora atualizamos o campo entrada recebida para false caso o envio do NCE Ocorrer ou caso não houver necessidade de enviar NCE ou Evento
      if (
        (enviarDocCancelamento &&
          resposta?.respostaEfatura?.responses[0]?.succeeded === true) ||
        !enviarDocCancelamento
      ) {
        let resultAnularRecebimento;
        try {
          const formDataCancelarRecebimento = new FormData();
          formDataCancelarRecebimento.append("canceladoPor", auth.userId);
          resultAnularRecebimento = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/entradas/anularRecebimento/${entradaFiltrada._id}`,
            "PATCH",
            formDataCancelarRecebimento,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
        }

        //Se anulação de pagamento
        if (resultAnularRecebimento?.entrada) {
          updateEntradas(resultAnularRecebimento.entrada, "atualizar");
          toast.success(
            `Recebimento revertido com sucesso ${
              resposta?.respostaEfatura?.responses[0]?.succeeded === true
                ? " e DFE cancelado!"
                : "!"
            }`
          );
        } else {
          //A entrada continua recebida
          updateEntradas(novaEntrada, "atualizar");
          toast.error("Erro no cancelamento do recebimento da entrada.");
        }
      } else if (resposta?.respostaEfatura?.responses[0]?.succeeded === false) {
        toast.error(
          `Erro ao efetuar cancelamento. ${tipoDocumento} com erro: ${resposta?.respostaEfatura?.responses[0]?.messages[0].description}`
        );
        updateEntradas(novaEntrada, "atualizar");
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  const emitirDocumentoFiscalParaEntradas = async (
    id,
    tipoDocumento,
    action,
    motivoCancelamento
  ) => {
    startLoading();
    try {
      let entradaFiltrada;
      let idDocumentoAEliminar;
      //Caso eu passe a entrada em vez do id
      if (id?._id) {
        entradaFiltrada = id;
      } else {
        entradaFiltrada = entradas.filter((e) => e.id === id)[0];
      }

      //SE existe 1 documento eletronico emitido com erro anteriormente
      if (
        tipoDocumento === "NCE" &&
        entradaFiltrada.cancelamentosRecibosEletronicos[
          entradaFiltrada.cancelamentosRecibosEletronicos?.length - 1
        ]?.documentoCancelamento?.succeeded === false
      ) {
        idDocumentoAEliminar =
          entradaFiltrada.cancelamentosRecibosEletronicos[
            entradaFiltrada.cancelamentosRecibosEletronicos?.length - 1
          ].documentoCancelamento._id;
      }

      //Enviamos o dfe escolhid. NCE ou FTE. Os dados enviados dependem do tipo de documento escolhido
      const formData = new FormData();
      formData.append("clinicaId", clinica.clinica._id);
      formData.append("nifCliente", clienteData.nif);
      formData.append("valor", entradaFiltrada.valor);
      formData.append("descricaoPagamento", entradaFiltrada.descricao);
      formData.append("nomeCliente", clienteData.nome);
      formData.append("contactoCliente", clienteData.contacto);
      formData.append("moradaCliente", clienteData.endereco);
      formData.append("codigoPaisCliente", clienteData.pais.codigo);
      formData.append("metodoPagamento", entradaFiltrada.metodoPagamento);

      formData.append(
        "dataLimitePagamento",
        entradaFiltrada.dataLimitePagamento
      );

      formData.append("tipoDocEletronico", tipoDocumento);
      formData.append("usuarioEmissor", auth.userId);
      formData.append("entrada", entradaFiltrada._id);
      formData.append("online", true);
      formData.append("valor", valor);

      if (tipoDocumento === "NCE") {
        formData.append(
          "IUDCancelar",
          entradaFiltrada.documentoEletronico.entryName.replace(/\.xml$/, "")
        );
        formData.append("IssueReasonCode", JSON.stringify(motivoCancelamento));
      }
      let resposta;
      try {
        resposta = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/documentoeletronico/emitirdocumentoeletronico`,
          "POST",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
      } catch (err) {
        console.error("err", err);
      }

      //Atualizar a array de cancelamentos de docmento eletronico
      const formData2 = new FormData();
      if (tipoDocumento === "NCE") {
        try {
          formData2.append(
            "documentoCancelamento",
            resposta.respostaDocumentoEletronico._id
          );
          formData2.append(
            "documentoEletronico",
            entradaFiltrada.documentoEletronico._id
          );

          const novaEntrada = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/entradas/aposcancelamentodoceletronico/${entradaFiltrada.id}`,
            "PATCH",
            formData2,
            {
              Authorization: "Bearer " + auth.token,
            }
          );

          updateEntradas(
            {
              ...entradaFiltrada,
              documentoEletronico: null,
              cancelamentosDocumentosEletronicos:
                novaEntrada.entrada.cancelamentosDocumentosEletronicos,
            },
            "atualizar"
          );
        } catch (err) {
          console.error("err", err);
        }
      }

      if (resposta && idDocumentoAEliminar) {
        try {
          await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/documentoeletronico/${idDocumentoAEliminar}`,
            "DELETE",
            formData,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
        }
      }

      if (resposta?.respostaEfatura?.responses[0]?.succeeded) {
        toast.success(tipoDocumento + " emitido com sucesso");
      } else {
        toast.error(
          "Erro ao emitir" +
            tipoDocumento +
            ". " +
            resposta?.respostaEfatura.responses[0].messages[0].description
        );
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  const actionColumn = [
    {
      field: "data",
      headerName: "Data",
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            {params.row.tipo === "receita" && (
              <span
                className={
                  params.row.parcelaPaga && params.row.status !== "CANCELADA"
                    ? "spanCellPago"
                    : ""
                }
              >
                {params.row.dataPagamento
                  ? moment(params.row.dataPagamento).format("DD-MM-YYYY")
                  : moment(params.row.dataLimitePagamento).format("DD-MM-YYYY")}
              </span>
            )}

            {params.row.tipo === "entrada" && (
              <div
                className={
                  params.row.entradaRecebida && params.row.dataPagamento
                    ? "spanCellPago"
                    : !params.row.entradaRecebida &&
                      moment().isAfter(
                        moment(params.row.dataLimitePagamento),
                        "day"
                      )
                    ? "spanCellAtraso"
                    : ""
                }
              >
                {params.row.entradaRecebida && params.row.dataPagamento
                  ? moment(params.row.dataPagamento).format("DD-MM-YYYY")
                  : moment(params.row.dataLimitePagamento).format("DD-MM-YYYY")}
              </div>
            )}
          </div>
        );
      },
    },
    {
      field: "nome",
      headerName: "Nome",
      flex: 6,
      renderCell: (params) => {
        return (
          <>
            {params.row.tipo === "receita" && (
              <div className="cellDebitoNome">
                <span
                  className={
                    params.row.parcelaPaga && params.row.status !== "CANCELADA"
                      ? "spanCellPago"
                      : ""
                  }
                >
                  {params.row.status === "CANCELADA"
                    ? "PARCELA " + params.row.status
                    : params.row.nome}
                </span>
                {params.row.nrParcela && params.row.status !== "CANCELADA" && (
                  <Tooltip
                    title={`Parcelado em ${params.row.nrParcela[2]} vezes`}
                  >
                    <IconButton>
                      <span className="nrParcela">{params.row.nrParcela}</span>
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="Ver orçamento">
                  <IconButton
                    onClick={handleOpenModalProcedimentos.bind(
                      null,
                      params.row.orcamento
                    )}
                  >
                    <ErrorIcon className="muiIconVerOrcamento" />
                  </IconButton>
                </Tooltip>
              </div>
            )}

            {params.row.tipo === "entrada" && (
              <div
                className={
                  "link-cliente-receita " + params.row.entradaRecebida &&
                  params.row.dataPagamento
                    ? "spanCellPago"
                    : !params.row.entradaRecebida &&
                      moment().isAfter(
                        moment(params.row.dataLimitePagamento),
                        "day"
                      )
                    ? "spanCellAtraso"
                    : ""
                }
              >
                Entrada - {params.row.descricao}
                {params.row.cliente && (
                  <>
                    {" - "}
                    {params.row.cliente.nome}
                  </>
                )}
              </div>
            )}
          </>
        );
      },
    },
    {
      field: "estado",
      headerName: "Pagamento",
      flex: 2,
      renderCell: (params) => {
        return (
          <>
            {params.row.tipo === "receita" && (
              <div className="cellDebitoEstado">
                {params.row.orcamento.desconto ===
                  params.row.orcamento.subtotal && (
                  <span className="debitos__isento">Isenção</span>
                )}

                {params.row.orcamento.desconto !==
                  params.row.orcamento.subtotal && (
                  <span
                    className={`debitos__container__estado ${
                      params.row.estado === "Em atraso" &&
                      params.row.status !== "CANCELADA"
                        ? "debitos__container__estado--atraso"
                        : params.row.estado === "Pago" &&
                          params.row.status !== "CANCELADA"
                        ? "debitos__container__estado--pago"
                        : ""
                    }`}
                  >
                    {params.row.metodoPagamento
                      ? params.row.metodoPagamento
                      : params.row.estado}
                  </span>
                )}
              </div>
            )}
            {params.row.tipo === "entrada" && (
              <div className="cellDebitoEstado">
                <span
                  className={
                    "debitos__container__estado debitos__container__estado--pago"
                  }
                >
                  {params.row.metodoPagamento}
                </span>
              </div>
            )}
          </>
        );
      },
    },
    {
      field: "valor",
      headerName: "Valor",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.tipo === "receita" && (
              <div className="cellAction">
                {params.row.orcamento.desconto !==
                  params.row.orcamento.subtotal && (
                  <span
                    className={`debitos__container__valor ${
                      params.row.estado === "Em atraso" &&
                      params.row.status !== "CANCELADA"
                        ? "debitos__container__valor--atraso"
                        : params.row.estado === "Pago" &&
                          params.row.status !== "CANCELADA"
                        ? "debitos__container__valor--pago"
                        : ""
                    }`}
                  >
                    {params.row.valor}
                  </span>
                )}
              </div>
            )}

            {params.row.tipo === "entrada" && (
              <span
                className={
                  params.row.entradaRecebida && params.row.dataPagamento
                    ? "spanCellPago"
                    : !params.row.entradaRecebida &&
                      moment().isAfter(
                        moment(params.row.dataLimitePagamento),
                        "day"
                      )
                    ? "spanCellAtraso"
                    : ""
                }
                style={{ marginLeft: "auto" }}
              >
                {params.row.valor}
              </span>
            )}
          </>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1.5,
      renderCell: (params) => {
        return (
          <div className="cellDebitoStatus">
            {params.row.estado === "Pago" && (
              <span
                className={
                  params.row.orcamento?.desconto ===
                  params.row.orcamento?.subtotal
                    ? "debitos__container__receber debitos__container__receber--isento"
                    : "debitos__container__receber debitos__container__receber--pago"
                }
              >
                <Tooltip
                  title={
                    params.row.orcamento?.desconto ===
                    params.row.orcamento?.subtotal
                      ? "Parcela Isenta de Pagamento"
                      : `Parcela recebida ${
                          params.row.recebidoPor
                            ? "por " + params.row.recebidoPor
                            : ""
                        }${
                          params.row.dataRecebimento
                            ? " aos " + params.row.dataRecebimento
                            : ""
                        }`
                  }
                >
                  <CheckCircleIcon sx={{ cursor: "help" }} />
                </Tooltip>
              </span>
            )}

            {params.row.documentoEletronico?.succeeded === true && (
              <span className="debitos__container__receber debitos__container__receber--pago">
                <Tooltip
                  title={
                    <div style={{ whiteSpace: "pre-line" }}>
                      {`${
                        params.row.documentoEletronico?.tipoDocumento
                      } emitido ${
                        params.row.emitidoPor
                          ? `por ${params.row.emitidoPor}`
                          : ""
                      }${
                        params.row.dataEmissao
                          ? ` aos ${params.row.dataEmissao}`
                          : ""
                      }.`}
                      {params.row?.cancelamentosDocumentosEletronicos?.length >
                        0 && (
                        <>
                          <br />
                          {`${params.row.cancelamentosDocumentosEletronicos.length} cancelamentos efetuados.`}
                          {params.row.cancelamentosDocumentosEletronicos.map(
                            (c, index) => (
                              <React.Fragment key={index}>
                                <br />
                                {c.documento.entryName.split(".")[0]}
                              </React.Fragment>
                            )
                          )}
                        </>
                      )}
                    </div>
                  }
                >
                  <ArticleOutlinedIcon sx={{ cursor: "help" }} />
                </Tooltip>
              </span>
            )}

            {params.row.nrRecibo && (
              <span className="debitos__container__receber debitos__container__receber--reciboInterno">
                <Tooltip title="Recibo Interno">
                  <ArticleOutlinedIcon sx={{ cursor: "help" }} />
                </Tooltip>
              </span>
            )}

            {params.row.documentoEletronico?.succeeded === false && (
              <span className="debitos__container__receber debitos__container__receber--atraso">
                <Tooltip
                  title={
                    <div style={{ whiteSpace: "pre-line" }}>
                      {`${params.row.documentoEletronico?.tipoDocumento} com erro.`}
                      {params.row?.documentoEletronico?.messages?.length >
                        0 && (
                        <>
                          {params.row.documentoEletronico.messages.map(
                            (m, index) => (
                              <React.Fragment key={index}>
                                <br />
                                <br />
                                {m.description}
                              </React.Fragment>
                            )
                          )}
                        </>
                      )}
                    </div>
                  }
                >
                  <ArticleOutlinedIcon sx={{ cursor: "help" }} />
                </Tooltip>
              </span>
            )}

            {params.row.cancelamentosDocumentosEletronicos?.length > 0 &&
              params.row.cancelamentosDocumentosEletronicos[
                params.row.cancelamentosDocumentosEletronicos?.length - 1
              ]?.documentoCancelamento?.succeeded === false && (
                <span className="debitos__container__receber debitos__container__receber--atraso">
                  <Tooltip
                    title={
                      <React.Fragment>
                        {
                          params.row.cancelamentosDocumentosEletronicos[
                            params.row.cancelamentosDocumentosEletronicos
                              ?.length - 1
                          ]?.documentoCancelamento?.tipoDocumento
                        }{" "}
                        com erro:
                        <br />
                        {params.row.cancelamentosDocumentosEletronicos[
                          params.row.cancelamentosDocumentosEletronicos.length -
                            1
                        ].documentoCancelamento.messages.map((m, index) => (
                          <React.Fragment key={index}>
                            {index > 0 && (
                              <>
                                <br />
                                <br />
                              </>
                            )}
                            {m.description}
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    }
                  >
                    <ArticleOutlinedIcon sx={{ cursor: "help" }} />
                  </Tooltip>
                </span>
              )}

            {/* Entradas */}
            {params.row.tipo === "entrada" && params.row.entradaRecebida && (
              <span className="debitos__container__receber debitos__container__receber--pago">
                <Tooltip
                  title={`Entrada recebida ${
                    params.row.recebidoPor
                      ? `por ${params.row.recebidoPor}`
                      : ""
                  }${
                    params.row.dataRecebimento
                      ? " aos " + params.row.dataRecebimento
                      : ""
                  }`}
                >
                  <CheckCircleIcon sx={{ cursor: "help" }} />
                </Tooltip>
              </span>
            )}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Ações",
      flex: 1.5,
      renderCell: (params) => {
        let content;
        if (params.row.tipo === "receita") {
          if (
            params.row.orcamento.desconto === params.row.orcamento.subtotal &&
            !params.row.motivoEncerramento
          ) {
            return null;
          } else if (params.row.parcelaPaga && !params.row.motivoEncerramento) {
            content = (
              <>
                <div
                  className="popOverMenu--option"
                  onClick={verReceitaHandler.bind(
                    null,
                    params.row.id,
                    params.row.contaId
                  )}
                >
                  <span>Ver</span>
                  <RemoveRedEyeIcon className="popOverMenu--option__icon" />
                </div>

                {/*PDF FRE - apenas se o documento fiscal enviado for bem sucedido  */}
                {auth.perm.includes("u-parc") &&
                  params.row.documentoEletronico?.succeeded && (
                    <div
                      className="popOverMenu--option"
                      onClick={gerarPdfFaturaEletronicaHandler.bind(
                        null,
                        params.row.id,
                        params.row.contaId
                      )}
                    >
                      <span>
                        Gerar PDF {params.row.documentoEletronico.tipoDocumento}
                      </span>
                      <ArticleOutlinedIcon className="popOverMenu--option__icon" />
                    </div>
                  )}

                {/* EMITIR FRE - se a parcela nao esta associada a um documento fiscal e se o ultimo cancelamento não tiver erro */}
                {auth.perm.includes("u-parc") &&
                  (!params.row.documentoEletronico ||
                    params.row.documentoEletronico?.succeeded === false) &&
                  !params.row.nrRecibo &&
                  (params.row.cancelamentosDocumentosEletronicos?.length ===
                    0 ||
                    (params.row.cancelamentosDocumentosEletronicos?.length >
                      0 &&
                      params.row.cancelamentosDocumentosEletronicos[
                        params.row.cancelamentosDocumentosEletronicos?.length -
                          1
                      ]?.documentoCancelamento?.succeeded === true)) && (
                    <Popconfirm
                      title="Fatura Recibo Eletrónico"
                      description={`Pretende emitir a FRE?`}
                      icon={<ErrorIcon style={{ color: "red" }} />}
                      okText="Sim"
                      cancelText="Não"
                      onConfirm={handleBeforeEmitirDocEletronico.bind(
                        null,
                        params.row.id,
                        params.row.contaId,
                        "FRE"
                      )}
                    >
                      <div className="popOverMenu--option">
                        Emitir FRE
                        <ArticleOutlinedIcon className="popOverMenu--option__icon" />
                      </div>
                    </Popconfirm>
                  )}

                {/* EMITIR TVE - se a parcela nao esta associada a um documento fiscal e se o ultimo cancelamento não tiver erro */}
                {auth.perm.includes("u-parc") &&
                  (!params.row.documentoEletronico ||
                    params.row.documentoEletronico?.succeeded === false) &&
                  !params.row.nrRecibo &&
                  (params.row.cancelamentosDocumentosEletronicos?.length ===
                    0 ||
                    (params.row.cancelamentosDocumentosEletronicos?.length >
                      0 &&
                      params.row.cancelamentosDocumentosEletronicos[
                        params.row.cancelamentosDocumentosEletronicos?.length -
                          1
                      ]?.documentoCancelamento?.succeeded === true)) &&
                  (!clienteData.nif ||
                    clienteData.nif === "000000000" ||
                    clienteData.nif === "999999999") && (
                    <Popconfirm
                      title="Talão de Venda Eletrónico"
                      description={`Pretende emitir a TVE?`}
                      icon={<ErrorIcon style={{ color: "red" }} />}
                      okText="Sim"
                      cancelText="Não"
                      onConfirm={handleBeforeEmitirDocEletronico.bind(
                        null,
                        params.row.id,
                        params.row.contaId,
                        "TVE"
                      )}
                    >
                      <div className="popOverMenu--option">
                        Emitir TVE
                        <ArticleOutlinedIcon className="popOverMenu--option__icon" />
                      </div>
                    </Popconfirm>
                  )}

                {/* RECIBO INTERNO - Parcela paga (no If lá acima), com dinheiro, orcamento particular, sem documentoEletronico com ou sem erro e sem cancelamento com erro */}
                {auth.perm.includes("u-parc") &&
                  !params.row.nrRecibo &&
                  (params.row.orcamento.beneficio === "Particular" ||
                    params.row.orcamento.orcamentoMisto) &&
                  params.row.metodoPagamento === "Dinheiro" &&
                  (!params.row.documentoEletronico ||
                    params.row.documentoEletronico?.succeeded === false) &&
                  (params.row.cancelamentosDocumentosEletronicos?.length ===
                    0 ||
                    (params.row.cancelamentosDocumentosEletronicos?.length >
                      0 &&
                      params.row.cancelamentosDocumentosEletronicos[
                        params.row.cancelamentosDocumentosEletronicos?.length -
                          1
                      ]?.documentoCancelamento?.succeeded === false)) && (
                    <Popconfirm
                      title="Recibo Interno"
                      description={`Pretende emitir um recibo Interno?`}
                      icon={<ErrorIcon style={{ color: "red" }} />}
                      okText="Sim"
                      cancelText="Não"
                      onConfirm={beforeGerarReciboHandler.bind(
                        null,
                        params.row.id,
                        params.row.contaId
                      )}
                    >
                      <div className="popOverMenu--option">
                        Emitir Recibo Interno
                        <ArticleOutlinedIcon className="popOverMenu--option__icon" />
                      </div>
                    </Popconfirm>
                  )}

                {auth.perm.includes("u-parc") && params.row.nrRecibo && (
                  <div
                    className="popOverMenu--option"
                    onClick={beforeGerarReciboHandler.bind(
                      null,
                      params.row.id,
                      params.row.contaId
                    )}
                  >
                    <span>Ver Recibo Interno</span>
                    <ArticleOutlinedIcon className="popOverMenu--option__icon" />
                  </div>
                )}

                {/* Exatamento como o anterior. Caso exista documentos eletronicos ou cancelamentos, são verificados como o anterior */}
                {auth.perm.includes("u-parc") && params.row.parcelaPaga && (
                  <Popconfirm
                    title="Cancelar Recebimento"
                    description={`Pretende cancelar o recebimento da ${
                      params.row.nome
                    } ${
                      params.row.documentoEletronico?.tipoDocumento === "FRE" &&
                      params.row.documentoEletronico?.succeeded === true
                        ? "com a emissão de uma Nota de Crédito?"
                        : params.row.documentoEletronico?.tipoDocumento ===
                            "TVE" &&
                          params.row.documentoEletronico?.succeeded === true
                        ? "com a emissão de um Evento??"
                        : "?"
                    }`}
                    icon={<ErrorIcon style={{ color: "red" }} />}
                    okText="Sim"
                    cancelText="Não"
                    onConfirm={beforeCancelarRecebimento.bind(
                      null,
                      params.row.id,
                      params.row.contaId
                    )}
                  >
                    <div className="popOverMenu--option">
                      <span>Cancelar recebimento</span>
                      <SettingsBackupRestoreIcon className="popOverMenu--option__icon" />
                    </div>
                  </Popconfirm>
                )}
              </>
            );
          }
        }
        if (params.row.tipo === "entrada") {
          content = (
            <div className="dots__menu__popup">
              {/* VER ENTRADA */}
              {auth.perm.includes("r-ent") && (
                <div
                  className="popOverMenu--option"
                  onClick={verEntradaHandler.bind(null, params.row.id)}
                >
                  <span>Ver</span>
                  <RemoveRedEyeIcon className="popOverMenu--option__icon" />
                </div>
              )}
              {/* EDITAR ENTRADA - apenas entradas que não possuem documento fiscal, cujo envio resultou em erro ou que nao estao pagas, não pode editar um documento com um envio de nce com erro*/}
              {auth.perm.includes("u-ent") &&
                (!params.row.documentoEletronico ||
                  params.row.documentoEletronico?.succeeded === false) &&
                !params.row.entradaRecebida &&
                (params.row.cancelamentosDocumentosEletronicos?.length === 0 ||
                  (params.row.cancelamentosDocumentosEletronicos?.length > 0 &&
                    params.row.cancelamentosDocumentosEletronicos[
                      params.row.cancelamentosDocumentosEletronicos?.length - 1
                    ]?.documentoCancelamento?.succeeded === true)) &&
                auth.perm.includes("u-ent") && (
                  <div
                    className="popOverMenu--option"
                    onClick={editarEntradaHandler.bind(null, params.row.id)}
                  >
                    <span>Editar</span>
                    <EditIcon className="popOverMenu--option__icon" />
                  </div>
                )}

              {/* GERAR PDF FRE - se existe documento eletronico e foi bem sucedido */}
              {auth.perm.includes("u-ent") &&
                params.row.documentoEletronico?.succeeded && (
                  <div
                    className="popOverMenu--option"
                    onClick={gerarPdfFaturaEletronicaHandlerEntrada.bind(
                      null,
                      params.row.id
                    )}
                  >
                    <span>
                      Gerar PDF {params.row.documentoEletronico?.tipoDocumento}
                    </span>
                    <ArticleOutlinedIcon className="popOverMenu--option__icon" />
                  </div>
                )}

              {auth.perm.includes("u-ent") && params.row.nrRecibo && (
                <div
                  className="popOverMenu--option"
                  onClick={gerarReciboInternoEntradas.bind(null, params.row.id)}
                >
                  <span>Gerar PDF Recibo Interno</span>
                  <ArticleOutlinedIcon className="popOverMenu--option__icon" />
                </div>
              )}

              {/*Emitir Fre para entrada*/}
              {auth.perm.includes("u-ent") &&
                (!params.row.documentoEletronico ||
                  params.row.documentoEletronico?.succeeded === false) &&
                !params.row.nrRecibo &&
                (params.row.cancelamentosDocumentosEletronicos?.length === 0 ||
                  (params.row.cancelamentosDocumentosEletronicos?.length > 0 &&
                    params.row.cancelamentosDocumentosEletronicos[
                      params.row.cancelamentosDocumentosEletronicos?.length - 1
                    ]?.documentoCancelamento?.succeeded === true)) &&
                params.row.entradaRecebida &&
                params.row.tipoEntrada === "reembolso" && (
                  <Popconfirm
                    title="Fatura/Recibo Eletrónico - FRE"
                    description={`Confirma a emissão?`}
                    icon={<ArticleOutlinedIcon style={{ color: "green" }} />}
                    okText="Sim"
                    cancelText="Não"
                    onConfirm={emitirDocEletronicoReembolso.bind(
                      null,
                      null,
                      params.row.id,
                      "FRE",
                      "atualizar"
                    )}
                  >
                    <div className="popOverMenu--option">
                      <span>Emitir FRE</span>
                      <ArticleOutlinedIcon className="popOverMenu--option__icon" />
                    </div>
                  </Popconfirm>
                )}

              {auth.perm.includes("u-ent") &&
                !params.row.nrRecibo &&
                params.row.metodoPagamento === "Dinheiro" &&
                (!params.row.documentoEletronico ||
                  params.row.documentoEletronico?.succeeded === false) &&
                (params.row.cancelamentosDocumentosEletronicos?.length === 0 ||
                  (params.row.cancelamentosDocumentosEletronicos?.length > 0 &&
                    params.row.cancelamentosDocumentosEletronicos[
                      params.row.cancelamentosDocumentosEletronicos?.length - 1
                    ]?.documento?.succeeded === false)) && (
                  <Popconfirm
                    title="Recibo Interno"
                    description={`Pretende emitir um recibo Interno?`}
                    icon={<ErrorIcon style={{ color: "red" }} />}
                    okText="Sim"
                    cancelText="Não"
                    onConfirm={gerarReciboInternoEntradas.bind(
                      null,
                      params.row.id
                    )}
                  >
                    <div className="popOverMenu--option">
                      <span>Emitir Recibo Interno</span>
                      <ArticleOutlinedIcon className="popOverMenu--option__icon" />
                    </div>
                  </Popconfirm>
                )}

              {/*Emitir TVE para reembolsos*/}
              {auth.perm.includes("u-ent") &&
                (!params.row.documentoEletronico ||
                  params.row.documentoEletronico?.succeeded === false) &&
                !params.row.nrRecibo &&
                (params.row.cancelamentosDocumentosEletronicos?.length === 0 ||
                  (params.row.cancelamentosDocumentosEletronicos?.length > 0 &&
                    params.row.cancelamentosDocumentosEletronicos[
                      params.row.cancelamentosDocumentosEletronicos?.length - 1
                    ]?.documentoCancelamento?.succeeded === true)) &&
                params.row.entradaRecebida && (
                  <Popconfirm
                    title="Talão de Venda Eletrónico - TVE"
                    description={`Confirma a emissão do Talão de Venda?`}
                    icon={<ArticleOutlinedIcon style={{ color: "green" }} />}
                    okText="Sim"
                    cancelText="Não"
                    onConfirm={emitirDocEletronicoReembolso.bind(
                      null,
                      null,
                      params.row.id,
                      "TVE",
                      "atualizar"
                    )}
                  >
                    <div className="popOverMenu--option">
                      <span>Emitir TVE</span>
                      <ArticleOutlinedIcon className="popOverMenu--option__icon" />
                    </div>
                  </Popconfirm>
                )}

              {/* Comprovativo */}
              {auth.perm.includes("r-ent") &&
                params.row.comprovativo &&
                params.row.comprovativo.ficheiro && (
                  <a
                    href={`${process.env.REACT_APP_BACKEND_LINK}/${params.row.comprovativo.ficheiro}`}
                    download
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="popOverMenu--option">
                      <span>Baixar Comprovativo</span>
                      <DownloadIcon className="popOverMenu--option__icon" />
                    </div>
                  </a>
                )}

              {/*CANCELAR RECEBIMENTO 1 - apenas para FTES, caso a entrada estiver paga, o documento enviado obteve sucesso e se caso houver um envio de NCE, esse foi bem sucedido */}
              {auth.perm.includes("u-ent") &&
                params.row.entradaRecebida &&
                auth.perm.includes("u-ent") && (
                  <Popconfirm
                    title="Cancelar Recebimento"
                    description={`Pretende cancelar o recebimento da entrada - ${
                      params.row.descricao
                    } ${
                      params.row.documentoEletronico?.succeeded === true
                        ? ` e cancelar a/o ${params.row?.documentoEletronico?.tipoDocumento}`
                        : ""
                    }?`}
                    icon={<ErrorIcon style={{ color: "red" }} />}
                    okText="Sim"
                    cancelText="Não"
                    onConfirm={handleBeforeCancelarRecebimentoEntrada.bind(
                      null,
                      params.row.id,
                      params.row.documentoEletronico?.succeeded ? true : false
                    )}
                  >
                    <div className="popOverMenu--option">
                      <span>Cancelar recebimento</span>
                      <SettingsBackupRestoreIcon className="popOverMenu--option__icon" />
                    </div>
                  </Popconfirm>
                )}

              {/* DESATIVAR ENTRADA -  se não houver nenum tipo de documento fiscal com ou sem erro e a entrada não estiver recebida */}
              {auth.perm.includes("d-ent") &&
                !params.row.documentoEletronico?.succeeded &&
                (!params.row.cancelamentosDocumentosEletronicos ||
                  params.row.cancelamentosDocumentosEletronicos?.length ===
                    0) &&
                !params.row.entradaRecebida &&
                auth.perm.includes("d-ent") && (
                  <Popconfirm
                    title="Desativar Entrada"
                    description={`Pretende desativar a entrada - ${params.row.descricao}?`}
                    icon={<ErrorIcon style={{ color: "red" }} />}
                    okText="Sim"
                    cancelText="Não"
                    onConfirm={desativarEntradaHandler.bind(
                      null,
                      params.row.id
                    )}
                  >
                    <div className="popOverMenu--option">
                      <span>Desativar</span>
                      <DeleteIcon className="popOverMenu--option__icon" />
                    </div>
                  </Popconfirm>
                )}
            </div>
          );
        }

        return (
          <>
            {params.row.estado !== "Pago" &&
              params.row.tipo === "receita" &&
              params.row.quantiaPorPagar !== 0 &&
              !params.row.motivoEncerramento &&
              auth.perm.includes("u-parc") && (
                <span
                  className={`debitos__container__receber ${
                    params.row.estado === "Em atraso" &&
                    "debitos__container__receber--atraso"
                  }`}
                  onClick={handleOpenModalReceber.bind(
                    null,
                    params.row.id,
                    params.row.valor
                  )}
                >
                  Receber
                </span>
              )}

            {params.row.tipo === "entrada" &&
              !params.row.entradaRecebida &&
              auth.perm.includes("u-ent") && (
                <span
                  className={`debitos__container__receber ${
                    params.row.estado === "Em atraso" &&
                    "debitos__container__receber--atraso"
                  }`}
                  onClick={handleOpenModalReceberEntrada.bind(
                    null,
                    params.row.id
                  )}
                >
                  Receber
                </span>
              )}

            {content && (
              <Space wrap>
                <Popover content={content} trigger="click" placement="bottom">
                  <div className="dots__menu">
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                  </div>
                </Popover>
              </Space>
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      <div className="debitos__container caixa">
        <ModalInfo {...modalProps} />
        <div className="debitos__container__cabecalho">
          <div
            className="checkbox-wrapper-51"
            id="debitos__container__cabecalho__mostrar-recebidos"
          >
            <input
              type="checkbox"
              id="mostrarRecebidos"
              onChange={(e) => setMostrarRecebidos(e.target.checked)}
              checked={mostrarRecebidos}
            />
            <label htmlFor="mostrarRecebidos" className="toggle">
              <span>
                <svg width="10px" height="10px" viewBox="0 0 10 10">
                  <path d="M5,1 L5,1 C2.790861,1 1,2.790861 1,5 L1,5 C1,7.209139 2.790861,9 5,9 L5,9 C7.209139,9 9,7.209139 9,5 L9,5 C9,2.790861 7.209139,1 5,1 L5,9 L5,1 Z"></path>
                </svg>
              </span>
            </label>
            <span className="checkbox-text">Mostrar Recebidos</span>
          </div>

          <div className="debitos__container__cabecalho__totais">
            {mostrarRecebidos && (
              <div className="debitos__container__cabecalho__totais--recebido">
                <span>Total Recebido</span>
                <span>{totalRecebido}$00</span>
              </div>
            )}
            <div className="debitos__container__cabecalho__totais--por-receber">
              <span>Total a receber</span>
              <span>{totalReceber}$00</span>
            </div>
          </div>

          <div style={{ justifySelf: "flex-end" }}>
            {auth.perm.includes("c-ent") && (
              <Button
                variant="contained"
                color="success"
                id="basic-button"
                onClick={(e) => setModalData({ open: "entrada" })}
              >
                + Adicionar Entrada
              </Button>
            )}
          </div>
        </div>
        {contaCorrente &&
          contaCorrente.contas
            .filter((conta) => conta.aprovado === true)
            .sort((a, b) => {
              // Helper function to determine if conta should be expanded
              const shouldBeExpanded = (conta) =>
                !conta.contaLiquidada &&
                conta.total !== 0 &&
                conta.status !== "INTERROMPIDO";

              // Sort expanded first (returns -1), collapsed last (returns 1)
              if (shouldBeExpanded(a) && !shouldBeExpanded(b)) return -1;
              if (!shouldBeExpanded(a) && shouldBeExpanded(b)) return 1;
              return 0;
            })
            .map((conta) => {
              // Skip if we're not showing received and all parcelas are paid
              if (
                !mostrarRecebidos &&
                conta.parcelas.every((p) => p.parcelaPaga)
              ) {
                return null;
              }

              // Calculate conta totals
              const totalPago = conta.parcelas.reduce(
                (sum, p) => sum + (p.quantiaPaga || 0),
                0
              );
              const totalPorPagar = conta.parcelas.reduce(
                (sum, p) => sum + (p.quantiaPorPagar || 0),
                0
              );

              return (
                <Accordion
                  key={conta._id}
                  defaultExpanded={
                    !conta.contaLiquidada &&
                    conta.total !== 0 &&
                    conta.status !== "INTERROMPIDO"
                  }
                  sx={{
                    mb: 2,
                    "&:before": {
                      display: "none",
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon
                        sx={{
                          fontSize: "2rem",
                          "&:hover": {
                            transform: "scale(1.2)",
                            color: "primary.main",
                            filter: "brightness(1.2)",
                            transition: "all 0.2s ease-in-out",
                          },
                        }}
                      />
                    }
                    sx={{
                      height: "55px",
                      backgroundColor:
                        conta.status === "INTERROMPIDO"
                          ? "#fff8f8"
                          : conta.total === 0
                          ? "#f9feff"
                          : "#fdfdfd",
                      borderLeft:
                        conta.status === "INTERROMPIDO"
                          ? "4px solid #eb3c5f"
                          : conta.total === 0
                          ? "4px solid #1976d2"
                          : "4px solid #4caf50",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 2 }}
                      >
                        <span className="debitos__accordion__title">
                          Orçamento #{conta.orcamento?.codigo || "N/A"} -{" "}
                          {conta.orcamento?.orcamentoMisto
                            ? "Misto"
                            : conta.orcamento?.beneficio}
                        </span>
                        {conta.status === "INTERROMPIDO" && (
                          <Chip
                            label="Tratamento Interrompido"
                            size="small"
                            sx={{
                              color: "#eb3c5f",
                              backgroundColor:
                                "rgba(241, 167, 182, 0.4745098039)",
                            }}
                          />
                        )}

                        {conta.total === 0 && (
                          <Chip
                            label="Isento de Pagamento"
                            size="small"
                            sx={{
                              color: "#1976D2",
                              backgroundColor: "#bbdefb4f",
                            }}
                          />
                        )}

                        {conta.contaLiquidada && conta.total !== 0 && (
                          <Chip
                            label="Liquidada"
                            size="small"
                            sx={{
                              color: "#12d453",
                              backgroundColor: "#e5faec",
                            }}
                          />
                        )}

                        {/* Botao de ação */}
                        {/* -------------------------------------------------------- */}
                        {(((!conta.contaLiquidada || conta.total === 0) &&
                          conta.orcamento.ativo &&
                          conta.aprovado &&
                          !conta.motivoEncerramento) ||
                          conta.motivoEncerramento) && (
                          <Box sx={{ display: "flex", gap: 1 }}>
                            <Button
                              size="small"
                              onClick={(event) => handleClickMenu(event, conta)}
                              variant="contained"
                              disableElevation
                              color="primary"
                              sx={{ textTransform: "none" }}
                              endIcon={<ExpandMoreIcon />}
                            >
                              Ações
                            </Button>
                            <Menu
                              anchorEl={menuState.anchorEl}
                              open={Boolean(menuState.anchorEl)}
                              onClose={handleCloseMenu}
                              onClick={(e) => e.stopPropagation()}
                              MenuListProps={{
                                "aria-labelledby": "basic-button",
                              }}
                              TransitionProps={{
                                onExited: () => {
                                  setMenuState((prev) => ({
                                    ...prev,
                                    selectedConta: null,
                                    isClosing: false,
                                  }));
                                },
                              }}
                            >
                              {menuState.selectedConta &&
                                !menuState.isClosing &&
                                (menuState.selectedConta.motivoEncerramento &&
                                auth.perm.includes("u-pend") ? (
                                  (auth.role === "Médico/Administrador" ||
                                    auth.role ===
                                      "Administrador Não Médico") && (
                                    <MenuItem
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        handleConfirmRestaurarPendencia(
                                          menuState.selectedConta
                                        );
                                        handleCloseMenu();
                                      }}
                                      sx={{
                                        color: "#273445",
                                        "&:hover": {
                                          backgroundColor: "#f5f5f5",
                                        },
                                      }}
                                    >
                                      <HistoryIcon
                                        sx={{ mr: 1, color: "#88959e" }}
                                      />
                                      Restaurar Pendências
                                    </MenuItem>
                                  )
                                ) : (
                                  // Replace the array with conditional rendering
                                  <>
                                    {menuState.selectedConta.total === 0 ? (
                                      auth.perm.includes("u-desc") && (
                                        <MenuItem
                                          onClick={(event) => {
                                            event.stopPropagation();
                                            handleConfirmCancelarIsencao(
                                              menuState.selectedConta
                                            );
                                            handleCloseMenu();
                                          }}
                                          sx={{
                                            color: "#273445",
                                            "&:hover": {
                                              backgroundColor: "#f5f5f5",
                                            },
                                          }}
                                        >
                                          <CancelIcon
                                            sx={{ mr: 1, color: "#88959e" }}
                                          />
                                          Cancelar isenção
                                        </MenuItem>
                                      )
                                    ) : (
                                      <>
                                        {auth.perm.includes("u-desc") && (
                                          <MenuItem
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              handleOpenModalEditarDesconto(
                                                menuState.selectedConta
                                              );
                                              handleCloseMenu();
                                            }}
                                            sx={{
                                              color: "#273445",
                                              "&:hover": {
                                                backgroundColor: "#f5f5f5",
                                              },
                                            }}
                                          >
                                            <DiscountIcon
                                              sx={{ mr: 1, color: "#88959e" }}
                                            />
                                            Editar Desconto
                                          </MenuItem>
                                        )}
                                        {auth.perm.includes("u-pend") &&
                                          menuState.selectedConta.parcelas.some(
                                            (parcela) =>
                                              parcela.quantiaPorPagar === 0
                                          ) && (
                                            <MenuItem
                                              onClick={(event) => {
                                                event.stopPropagation();
                                                handleOpenModalCancelamentoPendencias(
                                                  menuState.selectedConta
                                                );
                                                handleCloseMenu();
                                              }}
                                              sx={{
                                                color: "#273445",
                                                "&:hover": {
                                                  backgroundColor: "#f5f5f5",
                                                },
                                              }}
                                            >
                                              <CancelIcon
                                                sx={{
                                                  mr: 1,
                                                  color: "#88959e",
                                                }}
                                              />
                                              Terminar Pendências
                                            </MenuItem>
                                          )}
                                      </>
                                    )}
                                  </>
                                ))}
                            </Menu>
                          </Box>
                        )}
                        {/* -------------------------------------------------------- */}
                      </Box>
                      <Box sx={{ display: "flex", gap: 3 }}>
                        {mostrarRecebidos && conta.total !== 0 && (
                          <Box>
                            <Typography
                              variant="caption"
                              color="text.secondary"
                            >
                              Total Recebido
                            </Typography>
                            <Typography color="success.main">
                              {totalPago.toLocaleString("pt-BR")}$00
                            </Typography>
                          </Box>
                        )}
                        {conta.total !== 0 && (
                          <Box sx={{ marginRight: "25px" }}>
                            <Typography
                              variant="caption"
                              color="text.secondary"
                            >
                              {conta.motivoEncerramento
                                ? "Total Pendente"
                                : "Total a Receber"}
                            </Typography>
                            <Typography color="error.main">
                              {totalPorPagar.toLocaleString("pt-BR")}$00
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    {conta.status === "INTERROMPIDO" && (
                      <Alert
                        severity="info"
                        sx={{ mb: 2 }}
                        icon={
                          conta.motivoEncerramento?.descricao ? (
                            <Tooltip
                              title={conta.motivoEncerramento?.descricao}
                            >
                              <InfoIcon sx={{ cursor: "help" }} />
                            </Tooltip>
                          ) : (
                            <InfoIcon />
                          )
                        }
                      >
                        <Typography variant="body2">
                          <strong>Motivo da Interrupção:</strong>{" "}
                          {conta.motivoEncerramento?.tipo}
                          <br />
                          <div style={{ marginTop: "7px" }}>
                            <strong>Data:</strong>{" "}
                            {moment(conta.motivoEncerramento?.data).format(
                              "DD-MM-YYYY"
                            )}{" "}
                            <strong style={{ marginLeft: "15px" }}>
                              Responsável:{" "}
                            </strong>
                            {conta.motivoEncerramento?.responsavel?.name}
                          </div>
                        </Typography>
                      </Alert>
                    )}

                    {/* Your existing DataGrid for this conta's parcelas */}
                    <div
                      style={{
                        height: Math.min(165 + conta.nrParcelas * 45, 380),
                        width: "100%",
                      }}
                    >
                      <DataGrid
                        rows={rows.filter((row) => row.contaId === conta._id)}
                        columns={actionColumn}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
              );
            })}
        {/* Entradas */}
        {entradas && entradas.length > 0 && auth.perm.includes("r-ent") && (
          <Accordion
            key={"entradas1"}
            defaultExpanded={true}
            sx={{
              mb: 2,
              "&:before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{
                    fontSize: "2rem",
                    "&:hover": {
                      transform: "scale(1.2)",
                      color: "primary.main",
                      filter: "brightness(1.2)",
                      transition: "all 0.2s ease-in-out",
                    },
                  }}
                />
              }
              sx={{
                height: "55px",
                backgroundColor: "#eafdff",
                borderLeft: "4px solid #00BCD4",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <span className="debitos__accordion__title">Entradas</span>
                  {/* -------------------------------------------------------- */}
                </Box>
                <Box sx={{ display: "flex", gap: 3 }}>
                  {mostrarRecebidos && (
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        Total Recebido
                      </Typography>
                      <Typography color="success.main">
                        {entradas
                          .reduce(
                            (sum, e) => sum + (e.entradaRecebida ? e.valor : 0),
                            0
                          )
                          .toLocaleString("pt-BR")}
                        $00
                      </Typography>
                    </Box>
                  )}
                  <Box sx={{ marginRight: "25px" }}>
                    <Typography variant="caption" color="text.secondary">
                      Total a Receber
                    </Typography>
                    <Typography color="error.main">
                      {entradas
                        .reduce(
                          (sum, e) => sum + (e.entradaRecebida ? 0 : e.valor),
                          0
                        )
                        .toLocaleString("pt-BR")}
                      $00
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              {/* Your existing DataGrid for this conta's parcelas */}
              <div
                style={{
                  height: Math.min(165 + entradas.length * 45, 380),
                  width: "100%",
                }}
              >
                <DataGrid
                  rows={rows.filter((row) => row.tipo === "entrada")}
                  columns={actionColumn}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                />
              </div>
            </AccordionDetails>
          </Accordion>
        )}

        {openModal === "receber" && (
          <ModalReceber
            open={openModal === "receber"}
            handleCloseModalReceber={handleCloseModal}
            nomeCliente={nomeCliente}
            valorAPagar={parseFloat(valor.slice(0, -3).replace(".", ""))}
            contaCorr={contaCorrente}
            idParcela={idParcela}
            sendRequest={sendRequest}
            updateContaCorrente={updateContaCorrente}
          />
        )}

        {openModal === "procedimentos" && (
          <ModalProcedimentos
            open={openModal === "procedimentos"}
            handleCloseModalProcedimentos={handleCloseModal}
            modalProcedimentosData={modalData}
          />
        )}

        {openModal === "verReceita" && (
          <ModalVerReceita
            open={openModal === "verReceita"}
            handleCloseModalVerReceita={handleCloseModal}
            dadosDaReceita={modalData}
            nomeCliente={nomeCliente}
          />
        )}

        {openModal === "editarDesconto" && (
          <ModalEditarDesconto
            open={openModal === "editarDesconto"}
            handleCloseModalEditarDesconto={handleCloseModal}
            modalData={modalData}
            updateContaCorrente={updateContaCorrente}
            contaCorrente={contaCorrente}
            auth={auth}
          />
        )}

        {openModal === "cancelarPendencias" && (
          <ModalMotivoCancelamentoPendencias
            open={openModal === "cancelarPendencias"}
            handleCloseModalCancelarPendencias={handleCloseModal}
            modalData={modalData}
            updateContaCorrente={updateContaCorrente}
            contaCorrente={contaCorrente}
            auth={auth}
            sendRequest={sendRequest}
            clinicaId={clinica.clinica._id}
          />
        )}

        {dadosModalMotivoCancelamento.open && (
          <ModalMotivoCancelamento
            open={dadosModalMotivoCancelamento.open}
            handleCloseModal={handleCloseModal}
            modalData={dadosModalMotivoCancelamento}
            handleCancelarRecebimento={handleCancelarRecebimento}
          />
        )}

        {openModal === "verEntrada" && (
          <ModalVerEntrada
            openModalVerEntrada={openModal === "verEntrada"}
            handleCloseModalVerEntrada={handleCloseModal}
            dadosModalVerEntrada={modalData}
          />
        )}
        {modalData?.open === "motivoEvento" && (
          <ModalMotivoEventoDebitos
            open={modalData?.open === "motivoEvento"}
            handleCloseModal={handleCloseModal}
            modalData={modalData}
            handleCancelarRecebimento={handleCancelarRecebimento}
          />
        )}
        {modalData?.open === "editarEntrada" && (
          <ModalEditarEntradaDebito
            openModalEditarEntrada={modalData?.open === "editarEntrada"}
            sendRequest={sendRequest}
            handleCloseModalEditarEntrada={handleCloseModal}
            clinicaId={clinica.clinica._id}
            auth={auth}
            updateEntradas={updateEntradas}
            entrada={modalData.entrada}
            emitirDocEletronicoReembolso={emitirDocEletronicoReembolso}
            cliente={clienteData}
          />
        )}
        {modalData?.open === "receberEntrada" && (
          <ModalReceberEntradaDebito
            openModalReceberEntrada={modalData?.open === "receberEntrada"}
            sendRequest={sendRequest}
            handleCloseModalReceberEntrada={handleCloseModal}
            auth={auth}
            updateEntradas={updateEntradas}
            entrada={modalData.entrada}
            cliente={clienteData}
            clinicaId={clinica.clinica._id}
            emitirDocEletronicoReembolso={emitirDocEletronicoReembolso}
          />
        )}

        {modalData?.open === "motivoCancelamentoEntradas" && (
          <ModalMotivoCancelamentoEntradasDebitos
            open={modalData.open === "motivoCancelamentoEntradas"}
            handleCloseModal={handleCloseModal}
            modalData={modalData}
            handleCancelarRecebimentoEntrada={handleCancelarRecebimentoEntrada}
            emitirDocumentoFiscalParaEntradas={
              emitirDocumentoFiscalParaEntradas
            }
          />
        )}

        {modalData?.open === "entrada" && (
          <ModalEntradaDebito
            openModalEntrada={modalData.open === "entrada"}
            sendRequest={sendRequest}
            handleCloseModalEntrada={handleCloseModal}
            clinicaId={clinica.clinica._id}
            auth={auth}
            updateEntradas={updateEntradas}
            emitirDocEletronicoReembolso={emitirDocEletronicoReembolso}
            cliente={clienteData}
          />
        )}
      </div>
    </>
  );
}

export default Debitos;
