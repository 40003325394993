import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

// import "./ModalAdicionarEvolucao.scss";
import toast from "react-hot-toast";
import { useLoading } from "../../../../../../shared/context/LoadingContext";

function ModalEditarConsulta({
  handleCloseModal,
  open,
  dados,
  auth,
  sendRequest,
  handleModalSubmitSuccess,
}) {
  const { startLoading, stopLoading } = useLoading();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [relatorio, setRelatorio] = useState(dados.relatorio);

  const submitHandler = async () => {
    startLoading();
    setIsSubmitting(true);
    try {
      const formData = new FormData();
      formData.append("relatorio", relatorio ? relatorio : "");

      formData.append("utilizador", auth.userId);
      let consultaRetornada;
      try {
        consultaRetornada = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/consultas/atualizarrelatorio/${dados._id}`,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
      } catch (err) {
        console.error("err", err);
      }

      toast.success("Consulta atualizada com sucesso!");
      setIsSubmitting(false);
      handleModalSubmitSuccess(consultaRetornada.consulta);
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="fluxo-atendimento__modal__box" id="dividas__modal">
        <h1 className="fluxo-atendimento__modal__titulo">
          Relatório da consulta - {dados.cliente.nome}
        </h1>

        <div className="adicionarEvolucaoContainer">
          <textarea
            className="executarProcedimento__container__textarea"
            placeholder="Escreva aqui o relatório da consulta."
            cols="30"
            rows="5"
            value={relatorio}
            onChange={(e) => setRelatorio(e.target.value)}
          />
        </div>

        <div
          style={{
            gridColumn: "1/-1",
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "25px",
          }}
        >
          <span
            disabled={isSubmitting}
            className="blue-button"
            onClick={submitHandler}
            style={{ marginRight: "10px" }}
          >
            {isSubmitting ? "Guardando..." : "Guardar"}
          </span>

          <span className="cancel-btn" onClick={handleCloseModal}>
            Fechar
          </span>
        </div>
      </Box>
    </Modal>
  );
}

export default ModalEditarConsulta;
