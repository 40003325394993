import React, { useContext, useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../../../shared/context/auth-context";
import ModalNotificar from "../../../../../shared/components/modalMedicos/ModalNotificar";
import UpcomingAppointmentsComponent from "../UpcomingAppointmentsComponent";
import toast from "react-hot-toast";
import { useWebSocketSubscription } from "../../../../../shared/context/websocket-context";

import "./InfoGeral.scss";
import { useLoading } from "../../../../../shared/context/LoadingContext";

function InfoGeral({ clienteId, clinicaId, sendRequest, userData }) {
  const { startLoading, stopLoading } = useLoading();
  const [showAnamnese, setShowAnamnese] = useState(false);
  const [emUrgencia, setEmUrgencia] = useState(false);
  const [nrOrcamentosPlanos, setNrOrcamentosPlanos] = useState({
    orcamentosCriados: 0,
    planosConcluidos: 0,
  });
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const [modalMedicosProps, setModalNotificarProps] = useState({
    show: false,
    medicos: [],
    handleModalNotificarClose: null,
    handleModalNotificarSubmit: null,
  });

  const avaliarButtonClickHandler = () => {
    setModalNotificarProps({
      show: true,
      medicos: auth.usuariosAssociados,
      handleModalNotificarClose: handleModalNotificarClose,
      handleModalNotificarSubmit: handleModalNotificarSubmit,
    });
  };

  const handleModalNotificarClose = () => {
    setModalNotificarProps((current) => {
      return {
        ...current,
        show: false,
      };
    });
  };

  const handleModalNotificarSubmit = async (tipoNotificacao, medico) => {
    startLoading();
    setModalNotificarProps((current) => {
      return {
        ...current,
        show: false,
      };
    });
    try {
      const notificationFormData = new FormData();
      notificationFormData.append("cliente", clienteId);
      notificationFormData.append("clinica", clinicaId);

      let notificationUsers = [];

      if (auth.role === "Secretária(o)") {
        const assistentes = auth.assistentesDoMedico
          ? auth.assistentesDoMedico.find((m) => m.medicoId === medico)
              .assistentes
          : [];
        notificationUsers = [medico, ...assistentes];
      }

      notificationFormData.append("user", JSON.stringify(notificationUsers));
      notificationFormData.append("tipo", `espera ${tipoNotificacao}`);
      notificationFormData.append(
        "mensagem",
        `${auth.nome} notifica que ${
          userData.nome
        } está à espera para efetuar ${
          tipoNotificacao === "procedimento" ? "um" : "uma"
        } ${tipoNotificacao}`
      );

      await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/notificacoes`,
        "POST",
        notificationFormData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      toast.success("Médico notificado da avaliação!");
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  useWebSocketSubscription(
    async (parsedSocketData) => {
      if (
        parsedSocketData.tabela === "urgencias" &&
        parsedSocketData.tipoDeOperacao === "update"
      ) {
        setEmUrgencia(false);
      }
    },
    [auth.userId, auth.token, auth.role],
    "InfoGeral"
  );

  useEffect(() => {
    const fetchUrgencias = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/urgencias/cliente/${clienteId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        if (responseData.urgencia.length > 0) setEmUrgencia(true);
        else setEmUrgencia(false);
      } catch (err) {}
    };
    fetchUrgencias();

    const fetchNrOrcamentosCriadosPlanosConcluidos = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/planostratamento/orcamentosplanos/${clienteId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        setNrOrcamentosPlanos(responseData);
      } catch (err) {}
    };
    fetchNrOrcamentosCriadosPlanosConcluidos();
  }, [auth.token, clienteId, sendRequest]);

  const marcacaoUrgenciaHandler = () => {
    startLoading();
    try {
      if (!emUrgencia) {
        const marcarUrgencia = async () => {
          try {
            const formData = new FormData();
            formData.append("atendido", false);
            formData.append("clienteId", clienteId);
            formData.append("clinicaId", clinicaId);

            try {
              await sendRequest(
                `${process.env.REACT_APP_BACKEND_LINK}/api/urgencias`,
                "POST",
                formData,
                {
                  Authorization: "Bearer " + auth.token,
                }
              );
            } catch (err) {
              console.error("err", err);
            }
            setEmUrgencia(true);

            toast.success("Marcação de Urgência efetuada com sucesso!");
          } catch (err) {
            console.error(err);
          }
        };

        marcarUrgencia();
      } else {
        const deleteUrgencia = async () => {
          try {
            await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/urgencias/${clienteId}`,
              "DELETE",
              {},
              {
                Authorization: "Bearer " + auth.token,
              }
            );
            setEmUrgencia(false);

            toast.success("Marcação de Urgência cancelada!");
          } catch (err) {
            console.error("err", err);
          }
        };
        deleteUrgencia();
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  return (
    <>
      {modalMedicosProps.show && <ModalNotificar {...modalMedicosProps} />}
      <div className="singleContainer__info-geral">
        <div className="singleContainer__users-face caixa">
          {auth.perm.includes("u-cli") && (
            <span
              className="cliente__edit-btn absolute-positioned-edit-btn"
              onClick={() => navigate(`../../${userData.id}/edit`)}
            >
              Editar
            </span>
          )}
          <img
            src={
              userData.foto
                ? `${process.env.REACT_APP_BACKEND_LINK}/${userData.foto}`
                : "/images/fallBackPicture.png"
            }
            alt=""
            className="profileImg"
          />
          <span className="profileName">{userData.nome}</span>

          <span className="profileEmail">
            {userData.email ? userData.email : ""}
          </span>

          <div className="profileOther">
            <div className="profileOther1">
              <span className="number">
                {nrOrcamentosPlanos.orcamentosCriados}
              </span>
              <span className="text">Orçamentos criados</span>
            </div>
            <div className="profileOther2">
              <span className="number">
                {nrOrcamentosPlanos.planosConcluidos}
              </span>
              <span className="text">Planos concluidos</span>
            </div>
          </div>

          {auth.perm.includes("c-notiUA") && auth.role === "Secretária(o)" && (
            <div className="single-big-btns">
              <span
                className="marcacao-avaliacao-btn single-big-btn"
                onClick={avaliarButtonClickHandler}
              >
                Notificar
              </span>

              <span
                className={`single-big-btn ${
                  emUrgencia ? "emUrgencia-btn" : "marcacao-urgencia-btn"
                }`}
                onClick={marcacaoUrgenciaHandler}
              >
                {emUrgencia ? "Cancelar Urgência" : "Marcar Urgência"}
              </span>
            </div>
          )}

          {(auth.role === "Médico/Administrador" ||
            auth.role === "Dentista") && (
            <div className="single-anamnese--div">
              <span
                className="single-anamnese--btn"
                onClick={() => setShowAnamnese((current) => !current)}
              >
                {showAnamnese ? "Ver Geral" : "Ver Anamnese"}
              </span>
            </div>
          )}
        </div>
        {!showAnamnese && (
          <div className="singleContainer__users-info caixa">
            <div className="singleContainer__users-info--division">
              <div className="userInfo-box">
                <span className="userInfo-box_title">Género</span>
                <span className="userInfo-box_description">
                  {userData.genero}
                </span>
              </div>

              <div className="userInfo-box">
                <span className="userInfo-box_title">data nascimento</span>
                <span className="userInfo-box_description">
                  {userData.data_nasc}
                </span>
              </div>

              <div className="userInfo-box">
                <span className="userInfo-box_title">Contacto</span>
                <span className="userInfo-box_description">
                  {userData.contacto}
                </span>
              </div>
            </div>

            <div className="singleContainer__users-info--division">
              <div className="userInfo-box">
                <span className="userInfo-box_title">endereço</span>
                <span className="userInfo-box_description">
                  {userData.endereco}
                </span>
              </div>

              <div className="userInfo-box">
                <span className="userInfo-box_title">BI</span>
                <span className="userInfo-box_description">
                  {userData.identificacao}
                </span>
              </div>

              <div className="userInfo-box">
                <span className="userInfo-box_title">Nif</span>
                <span className="userInfo-box_description">{userData.nif}</span>
              </div>
            </div>

            <div className="singleContainer__users-info--division">
              <div className="userInfo-box">
                <span className="userInfo-box_title">Enviar Sms?</span>
                <span className="userInfo-box_description">
                  {userData.sms ? "Permite" : "Não permite"}
                </span>
              </div>

              <div className="userInfo-box">
                <span className="userInfo-box_title">Enviar Email?</span>
                <span className="userInfo-box_description">
                  {userData.com_email ? "Permite" : "Não permite"}
                </span>
              </div>

              <div className="userInfo-box">
                <span className="userInfo-box_title">whatsapp?</span>
                <span className="userInfo-box_description">
                  {userData.com_whatsapp ? "Permite" : "Não permite"}
                </span>
              </div>
            </div>
          </div>
        )}

        {showAnamnese && (
          <div className="singleContainer__users-anamnese-info caixa">
            {!userData.anamnese &&
              (auth.role === "Médico/Administrador" ||
                auth.role === "Dentista") && (
                <span
                  className="single-btn-newAnamnese"
                  onClick={() =>
                    navigate(`../../../anamnese/${userData.id}/new`)
                  }
                >
                  Criar Anamnese
                </span>
              )}
            {userData.anamnese &&
              (auth.role === "Médico/Administrador" ||
                auth.role === "Dentista") && (
                <>
                  <span
                    className="anamnese__edit-btn absolute-positioned-edit-btn"
                    onClick={() =>
                      navigate(
                        `../../../anamnese/${userData.anamnese.id}-${
                          userData.id
                        }-${userData.nome.split(" ")[0]}-${
                          userData.nome.split(" ")[1]
                        }/edit`
                      )
                    }
                  >
                    Editar
                  </span>

                  <span className="anamnese__texto">
                    {userData.anamnese.estadoGeralSaude &&
                      userData.anamnese.estadoGeralSaude + "."}
                    {`${
                      userData.anamnese.sobCuidadosMedicos
                        ? " Está sob cuidados médicos."
                        : " Não está sob cuidados médicos."
                    }`}
                    {userData.anamnese.gravidez ? " Grávida" : ""}
                    {`${
                      userData.anamnese.foiHospitalizado
                        ? userData.genero === "feminino"
                          ? " Já foi hospitalizada."
                          : " Já foi hospitalizado."
                        : userData.genero === "feminino"
                        ? " Nunca foi hospitalizada."
                        : " Nunca foi hospitalizado."
                    }`}
                    {`${
                      userData.anamnese.alergias
                        ? ` Sofre de alergias: ${userData.anamnese.alergias}.`
                        : "Não sofre de alergias"
                    }`}
                    {`${
                      userData.anamnese.doencas
                        ? ` Já teve doenças graves: ${userData.anamnese.doencas}.`
                        : " Nunca teve doenças graves."
                    }`}
                    {`${
                      userData.anamnese.medicamentos
                        ? ` Toma os seguintes medicamentos: ${userData.anamnese.medicamentos}.`
                        : " Não toma medicamentos."
                    }`}
                  </span>
                </>
              )}
          </div>
        )}
      </div>

      {auth.perm.includes("r-cli") && (
        <div className="single__container__proximos-agendamentos caixa">
          <h2 className="single__container__proximos-agendamentos__titulo">
            Próximos Agendamentos
          </h2>
          <UpcomingAppointmentsComponent
            clienteId={clienteId}
            sendRequest={sendRequest}
          />
        </div>
      )}
    </>
  );
}

export default InfoGeral;
