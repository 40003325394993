import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import FullCalendar from "@fullcalendar/react";
// import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import ptLocale from "@fullcalendar/core/locales/pt";

import { AuthContext } from "../../../../shared/context/auth-context";
import { ClinicaContext } from "../../../../shared/context/clinica-context";
import { useHttpClient } from "../../../../shared/hooks/http-hook";

import AppointmentForm from "./componentsFullCallendar/AppointmentForm";
import Tooltip from "./componentsFullCallendar/Tooltip";
import ModalInfo from "../../../../shared/components/UIElements/ModalInfo";

import moment from "moment";
import "./Datatable.scss";

import { useLoading } from "../../../../shared/context/LoadingContext";
import toast from "react-hot-toast";

function Datatable({ medicos }) {
  const { startLoading, stopLoading } = useLoading();

  const [clients, setClients] = useState([]);
  const [clientesProcedimento, setClientesProcedimento] = useState([]);
  const [events, setEvents] = useState([]);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [tooltipEvent, setTooltipEvent] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [selectedDate, setSelectedDate] = useState(null);
  const [agenda, setAgenda] = useState(null);
  const [agendamentosEmTransicao, setAgendamentosEmTransicao] = useState([]);
  const [compromissos, setCompromissos] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [allCategoriasTratamento, setAllCategoriasTratamento] = useState([]);
  const [procedimentosPorMarcar, setProcedimentosPorMarcar] = useState([]);
  const [duracaoConsulta, setDuracaoConsulta] = useState(30);
  const [horarioAtendimento, setHorarioAtendimento] = useState({
    start: "08:00:00",
    end: "20:00:00",
  });
  const [modalProps, setModalProps] = useState({
    show: false,
    icon: "",
    title: "",
    message: "",
    form: false,
  });

  const clickRef = useRef(null);
  const tooltipRef = useRef(null);

  const { sendRequest } = useHttpClient();

  const auth = useContext(AuthContext);
  const clinica = useContext(ClinicaContext);
  const medicoId = useParams().medicoId;

  useEffect(() => {
    setEvents([]);
    const fetchData = async () => {
      try {
        //Duracao de consulta e horario de atendimento
        if (medicos) {
          let temp = medicos.filter((m) => m.id === medicoId);

          if (temp[0].duracaoConsulta)
            setDuracaoConsulta(temp[0].duracaoConsulta);

          if (temp[0].horarioAtendimento)
            setHorarioAtendimento(temp[0].horarioAtendimento);
        } else {
          const response = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/users/${medicoId}`,
            "GET",
            null,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
          if (response.user.horarioAtendimento)
            setHorarioAtendimento(response.user.horarioAtendimento);
          if (response.user.duracaoConsulta)
            setDuracaoConsulta(response.user.duracaoConsulta);
        }

        startLoading();
        const formData = new FormData();
        formData.append("medicoId", medicoId);

        const [
          proceduresResponse,
          consultasResponse,
          agendaResponse,
          compromissosResponse,
          agendamentosEmTransicaoResponse,
          clientesResponse,
          categoriasTratamentoResponse,
        ] = await Promise.all([
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/planostratamento/procedimentos/agenda/${clinica.clinica._id}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/consultas/agenda/${clinica.clinica._id}`,
            "PATCH",
            formData,
            { Authorization: "Bearer " + auth.token }
          ),
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/agenda/${medicoId}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/compromissos/${medicoId}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/agendamentosemtransicao/${medicoId}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/clientes/agendaavaliacao/${clinica.clinica._id}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/categoriastratamento/clinica/${clinica.clinica._id}`,
            "GET",
            null,
            {
              Authorization: "Bearer " + auth.token,
            }
          ),
        ]);

        const agendaMarcacoes = agendaResponse.agenda.marcacoes || [];
        const compromissosMarcacoes =
          compromissosResponse.compromisso.marcacoes || [];
        const consultasMarcacoes = consultasResponse.consultas || [];
        const agendamentosEmTransicaoMarcacoes =
          agendamentosEmTransicaoResponse.agenda.marcacoes || [];
        setAgenda(agendaResponse.agenda);
        setCompromissos(compromissosResponse.compromisso);
        setAgendamentosEmTransicao(agendamentosEmTransicaoResponse.agenda);
        setClients(clientesResponse.clientes);
        setAllCategoriasTratamento(
          categoriasTratamentoResponse.categoriasTratamento.map((c) => ({
            id: c._id,
            label: c.categoria,
          }))
        );

        const formattedEvents = [
          ...formatAppointments(agendaMarcacoes),
          ...formatCompromissos(compromissosMarcacoes),
          ...formatTransitionAppointments(agendamentosEmTransicaoMarcacoes),
          ...formatConsultas(consultasMarcacoes),
        ];

        const tempClientes = [];
        const tempData = [];

        //Procedimentos por marcar
        try {
          proceduresResponse.planosTratamento.forEach((pl) => {
            if (pl.status === "INTERROMPIDO") return;
            pl.procedimentos.forEach((pr) => {
              tempData.push({
                id: crypto.randomUUID(),
                id_plano: pl.id,
                cliente: {
                  id: pl.cliente._id,
                  label: pl.cliente.nome,
                },
                startDate: pr.data_inicio_execucao
                  ? new Date(pr.data_inicio_execucao)
                  : null,
                endDate: pr.data_fim_execucao
                  ? new Date(pr.data_fim_execucao)
                  : null,
                title:
                  pl.cliente.nome + " - " + pr.tratamento.categoria.categoria,
                procedimento: {
                  id: pr.tratamento.categoria._id,
                  label: pr.tratamento.categoria.categoria,
                },
                filaEspera: false,
                atendido: false,
                faltou: false,
                emTransicao: false,
              });
            });

            if (pl.procedimentos.length > 0) {
              // We only check if the client ID doesn't exist in our array yet
              if (
                tempClientes.filter((c) => c.id === pl.cliente._id).length === 0
              ) {
                const existemProcedimentosDoClientePorMarcar =
                  pl.procedimentos.filter(
                    (pr) => !pr.data_inicio_execucao
                  ).length;

                if (existemProcedimentosDoClientePorMarcar) {
                  tempClientes.push({
                    id: pl.cliente._id,
                    label: pl.cliente.nome,
                  });
                }
              }
            }
          });
        } catch (err) {
          console.error("err", err);
        }

        setProcedimentosPorMarcar(tempData);
        setClientesProcedimento(tempClientes);
        setEvents(formattedEvents);
      } catch (err) {
        console.error(err);
      } finally {
        stopLoading();
      }
    };

    fetchData();
  }, [sendRequest, auth.token, clinica.clinica._id, medicos, medicoId]);

  const formatAppointments = (appointments) => {
    return appointments.map((appointment) => ({
      id: appointment._id,
      title: ` ${appointment.cliente.nome.split(" ")[0]} ${
        appointment.cliente.nome.split(" ")[
          appointment.cliente.nome.split(" ").length - 1
        ]
      } - ${
        appointment.avaliacao
          ? "Avaliação"
          : appointment.categoriaTratamento?.categoria
      }`,
      start: new Date(appointment.data_inicio_execucao),
      end: new Date(appointment.data_fim_execucao),

      backgroundColor: appointment.atendido
        ? "#F2FAF5"
        : appointment.faltou
        ? "#FFF3F6"
        : appointment.avaliacao
        ? "#fde6c5"
        : "#ecf6ff",
      borderColor: appointment.atendido
        ? "#39B93A"
        : appointment.faltou
        ? "#D96C60"
        : appointment.avaliacao
        ? "#F57C00"
        : "#1976D2",
      textColor: appointment.atendido
        ? "#39B93A"
        : appointment.faltou
        ? "#D96C60"
        : appointment.avaliacao
        ? " #F57C00"
        : "#1976D2",
      extendedProps: {
        clientId: appointment.cliente._id,
        procedureId: appointment.categoriaTratamento?._id,
        isEvaluation: appointment.avaliacao,
        isAttended: appointment.atendido,
        isMissed: appointment.faltou,
        inTransition: false,
        isCompromisso: false,
        planoId: appointment.planoTratamento,
        agendadoPor: appointment.agendadoPor?.name,
      },
    }));
  };

  const formatCompromissos = (compromissos) => {
    return compromissos.map((compromisso) => ({
      id: compromisso._id,
      title: " " + compromisso.compromisso,
      start: new Date(compromisso.data_inicio_execucao),
      end: new Date(compromisso.data_fim_execucao),
      backgroundColor: "#F5F5F5",
      borderColor: "#616161",
      textColor: "#616161",
      extendedProps: {
        isCompromisso: true,
        agendadoPor: compromisso.agendadoPor?.name,
      },
    }));
  };

  const formatConsultas = (conslts) => {
    return conslts.map((consulta) => ({
      id: consulta._id,
      title: ` ${consulta.cliente.nome.split(" ")[0]} ${
        consulta.cliente.nome.split(" ")[
          consulta.cliente.nome.split(" ").length - 1
        ]
      } - Consulta`,
      start: new Date(consulta.data_inicio),
      end: new Date(consulta.data_fim),
      backgroundColor: consulta.executada
        ? "#F2FAF5"
        : consulta.faltou
        ? "#FFF3F6"
        : "#D7CCC8",
      borderColor: consulta.executada
        ? "#39B93A"
        : consulta.faltou
        ? "#D96C60"
        : "#5D4037",
      textColor: consulta.executada
        ? "#39B93A"
        : consulta.faltou
        ? "#D96C60"
        : "#5D4037",
      extendedProps: {
        isConsulta: true,
        clientId: consulta.cliente._id,
        isAttended: consulta.executada,
        isMissed: consulta.faltou,
        agendadoPor: consulta.agendadoPor?.name,
      },
    }));
  };

  const formatTransitionAppointments = (appointments) => {
    return appointments.map((appointment) => ({
      id: appointment._id,
      title: ` ${appointment.cliente.nome.split(" ")[0]} ${
        appointment.cliente.nome.split(" ")[
          appointment.cliente.nome.split(" ").length - 1
        ]
      } - ${appointment.categoriaTratamento.categoria}`,
      start: new Date(appointment.data_inicio_execucao),
      end: new Date(appointment.data_fim_execucao),
      backgroundColor: appointment.atendido
        ? "#F2FAF5"
        : appointment.faltou
        ? "#FFF3F6"
        : "#ebe5f5",
      borderColor: appointment.atendido
        ? "#39B93A"
        : appointment.faltou
        ? "#D96C60"
        : "#512DA8",
      textColor: appointment.atendido
        ? "#39B93A"
        : appointment.faltou
        ? "#D96C60"
        : "#512DA8",
      extendedProps: {
        clientId: appointment.cliente._id,
        procedureId: appointment.categoriaTratamento._id,
        inTransition: true,
        isAttended: appointment.atendido,
        isMissed: appointment.faltou,
        agendadoPor: appointment.agendadoPor?.name,
      },
    }));
  };

  const isEventEditable = (event) => {
    return !(
      event.extendedProps.isEvaluation ||
      event.extendedProps.inTransition ||
      event.extendedProps.isAttended ||
      event.extendedProps.isMissed ||
      event.extendedProps.isCompromisso ||
      event.extendedProps.isConsulta
    );
  };

  const canAlterarData = (event) => {
    return !(event.extendedProps.isAttended || event.extendedProps.isMissed);
  };

  const handleEventDrop = async (dropInfo) => {
    const { event } = dropInfo;
    if (!canAlterarData(event)) {
      dropInfo.revert();
      return;
    }

    const tipoAgendamento = event.extendedProps.isConsulta
      ? "consultas"
      : event.extendedProps.inTransition
      ? "agendamentosemtransicao"
      : event.extendedProps.isCompromisso
      ? "compromissos"
      : "agenda";

    try {
      const formData = new FormData();
      formData.append("data_inicio_execucao", event.start);
      formData.append("data_fim_execucao", event.end);
      formData.append("utilizador", auth.userId);
      if (!event.extendedProps.isConsulta) {
        formData.append(
          "agendaId",
          event.extendedProps.inTransition
            ? agendamentosEmTransicao._id
            : event.extendedProps.isCompromisso
            ? compromissos._id
            : agenda._id
        );
      }

      startLoading();
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/${tipoAgendamento}/alterardata/${event.id}`,
        "PATCH",
        formData,
        { Authorization: "Bearer " + auth.token }
      );
      stopLoading();

      // Update local state
      setEvents((prevEvents) =>
        prevEvents.map((e) =>
          e.id === event.id
            ? {
                ...e,
                extendedProps: { ...e.extendedProps, agendadoPor: auth.nome },
                start: event.start,
                end: event.end,
              }
            : e
        )
      );
    } catch (err) {
      stopLoading();
      console.error("err", err);
      dropInfo.revert();
    }
  };

  const handleDateClick = (arg) => {
    if (clickRef.current && arg.date.getTime() === clickRef.current.getTime()) {
      // Double click detected
      setSelectedDate(arg.date);
      setIsFormOpen(true);
      setSelectedEvent(null); // Clear any previously selected event
      clickRef.current = null;
    } else {
      // First click
      clickRef.current = arg.date;
      setTimeout(() => {
        clickRef.current = null;
      }, 300);
    }
  };

  const handleEventClick = (info) => {
    if (
      clickRef.current &&
      info.event.start.getTime() === clickRef.current.getTime()
    ) {
      // Double click detected on an event
      if (isEventEditable(info.event)) {
        setSelectedDate(info.event.start);
        setSelectedEvent(info.event);
        setIsFormOpen(true);
      }
      clickRef.current = null;
    } else {
      // First click - show tooltip
      const rect = info.el.getBoundingClientRect();
      setTooltipPosition({
        x: rect.left + window.scrollX,
        y: rect.bottom + window.scrollY,
      });
      setTooltipEvent(info.event);

      // Set up for potential double click
      clickRef.current = info.event.start;
      setTimeout(() => {
        clickRef.current = null;
      }, 300);
    }
  };

  const handleCloseTooltip = () => {
    setTooltipEvent(null);
  };

  const handleCloseForm = () => {
    setIsFormOpen(false);
    setSelectedDate(null);
  };

  const handleDeleteEvent = async (event) => {
    if (event.extendedProps.isConsulta) {
      try {
        startLoading();
        const consultaEncontrada = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/consultas/${event.id}`,
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );
        if (consultaEncontrada.consulta.entrada.entradaRecebida) {
          toast.error(
            "A consulta não pode ser eliminada pois já foi recebida!"
          );
          return;
        }

        const formData = new FormData();
        formData.append("utilizador", auth.userId);

        await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/consultas/eliminar/${event.id}`,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        toast.success(
          "Eliminação da consulta e da entrada efetuados com sucesso"
        );

        setEvents((prevEvents) => prevEvents.filter((e) => e.id !== event.id));
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
        handleCloseTooltip();
        handleModalCancel();
      }
    } else {
      try {
        startLoading();
        let path = "agenda";
        let agendaId = agenda._id;

        if (event.extendedProps.inTransition) {
          path = "agendamentosemtransicao";
          agendaId = agendamentosEmTransicao._id;
        } else if (event.extendedProps.isCompromisso) {
          path = "compromissos";
          agendaId = compromissos._id;
        }

        const formData = new FormData();
        formData.append("agendaId", agendaId);
        formData.append("utilizador", auth.userId);
        if (!event.extendedProps.isCompromisso) {
          formData.append("nomeCliente", event.title.split(" - ")[0]);
          formData.append("procedimento", event.title.split(" - ")[1]);
        } else {
          formData.append("compromisso", event.title.trim());
        }
        formData.append(
          "dataInicial",
          moment(event.start).format("DD/MM/YYYY HH:mm")
        );
        formData.append(
          "dataFinal",
          moment(event.end).format("DD/MM/YYYY HH:mm")
        );

        await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/${path}/eliminarmarcacao/${event.id}`,
          "PATCH",
          formData,
          { Authorization: "Bearer " + auth.token }
        );

        setEvents((prevEvents) => prevEvents.filter((e) => e.id !== event.id));
        handleCloseTooltip();
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    }
    handleModalCancel();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        handleCloseTooltip();
      }
    };

    if (tooltipEvent) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [tooltipEvent]);

  const updateEvents = (event, eventId) => {
    if (!eventId) {
      setEvents((prevEvents) => [...prevEvents, event]);
    } else {
      setEvents((prevEvents) =>
        prevEvents.map((ev) =>
          ev.id === eventId ? { id: ev.id, ...event } : ev
        )
      );
    }
    handleCloseForm();
  };

  const handleApagarAgendamento = async (event) => {
    setModalProps({
      show: true,
      icon: "info",
      title: `Apagar Agendamento!`,
      message: `Tem a certeza que pretende apagar o Agendamento?`,
      form: true,
      onCancel: handleModalCancel,
      onConfirm: handleDeleteEvent.bind(null, event),
    });
  };

  const handleModalCancel = () => {
    setModalProps((currentModalProps) => {
      return { ...currentModalProps, show: false };
    });
  };

  return (
    <div className="datatable-container" style={{ backgroundColor: "white" }}>
      <ModalInfo {...modalProps} />
      {events && (
        <FullCalendar
          plugins={[timeGridPlugin, interactionPlugin]}
          // plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="timeGridWeek"
          locale={ptLocale}
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "timeGridWeek,timeGridDay",
            // right: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          buttonText={{
            today: "Esta Semana",
            // month: "Mês",
            week: "Semana",
            day: "Dia",
          }}
          height="auto"
          slotDuration={`00:${duracaoConsulta}:00`}
          slotLabelInterval={`00:${duracaoConsulta}`}
          slotLabelFormat={{
            hour: "numeric",
            minute: "2-digit",
            omitZeroMinute: false,
            meridiem: "short",
          }}
          slotMinTime={horarioAtendimento.start}
          slotMaxTime={horarioAtendimento.end}
          allDaySlot={false}
          events={events}
          dateClick={handleDateClick}
          eventClick={handleEventClick}
          eventContent={renderEventContent}
          hiddenDays={[0]}
          firstDay={1}
          editable={true}
          eventDrop={handleEventDrop}
          eventResize={handleEventDrop}
          eventStartEditable={isEventEditable}
          eventDurationEditable={isEventEditable}
        />
      )}
      {tooltipEvent && (
        <div
          ref={tooltipRef}
          style={{
            position: "absolute",
            left: `${tooltipPosition.x}px`,
            top: `${tooltipPosition.y}px`,
          }}
        >
          <Tooltip
            event={tooltipEvent}
            handleApagarAgendamento={handleApagarAgendamento}
            onClose={handleCloseTooltip}
            auth={auth}
          />
        </div>
      )}
      {agenda && (
        <AppointmentForm
          isOpen={isFormOpen}
          onClose={handleCloseForm}
          date={selectedDate}
          event={selectedEvent}
          clients={clients}
          clientesProcedimento={clientesProcedimento}
          allCategoriasTratamento={allCategoriasTratamento}
          procedimentosPorMarcar={procedimentosPorMarcar}
          auth={auth}
          sendRequest={sendRequest}
          clinica={clinica}
          agendaId={agenda._id}
          updateEvents={updateEvents}
          medicoId={medicoId}
          duracaoConsulta={duracaoConsulta}
          startLoading={startLoading}
          stopLoading={stopLoading}
        />
      )}
    </div>
  );
}

function renderEventContent(eventInfo) {
  return (
    <>
      <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i>
    </>
  );
}

export default Datatable;
