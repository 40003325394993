import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../../../../shared/context/auth-context";

import OrcamentosComponent from "../OrcamentosComponent/OrcamentosComponent";
import AvaliacoesComponent from "../AvaliacoesComponent";
import PlanosTratamentoComponent from "../PlanosTratamentoComponent/PlanosTratamentoComponent";
import "./FluxoAtendimento.scss";
import ConsultasComponent from "../ConsultasComponent/ConsultasComponent";

function FluxoAtendimento({ clienteId, sendRequest, dadosOrcamento }) {
  const [showTabs, setShowTabs] = useState("");
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const temporary = useParams().clienteId;
  const abaAberta = temporary.split("-")[1];

  useEffect(() => {
    if (abaAberta) {
      if (abaAberta === "avaliacao" && auth.perm.includes("r-aval"))
        setShowTabs("avaliacao");
      else if (abaAberta === "planotratamento" && auth.perm.includes("r-plt"))
        setShowTabs("planotratamento");
      else if (abaAberta === "orcamento" && auth.perm.includes("r-orc"))
        setShowTabs("orcamento");
      else if (abaAberta === "consulta" && auth.perm.includes("r-consult"))
        setShowTabs("consulta");
    } else setShowTabs("avaliacao");
  }, [abaAberta]);

  return (
    <div className="singleContainer__fluxo-atendimento caixa">
      <div className="singleContainer__fluxo-atendimento-tabs">
        {auth.perm.includes("r-aval") && (
          <span
            className={`tab-button ${
              showTabs === "avaliacao" && "tab-button--active"
            }`}
            onClick={() => setShowTabs("avaliacao")}
          >
            Avaliações
          </span>
        )}
        {auth.perm.includes("r-orc") && (
          <span
            className={`tab-button ${
              showTabs === "orcamento" && "tab-button--active"
            }`}
            onClick={() => setShowTabs("orcamento")}
          >
            Orçamentos
          </span>
        )}
        {auth.perm.includes("r-plt") && (
          <span
            className={`tab-button ${
              showTabs === "planotratamento" && "tab-button--active"
            }`}
            onClick={() => setShowTabs("planotratamento")}
          >
            Planos Tratamento
          </span>
        )}

        {auth.perm.includes("r-consult") && (
          <span
            className={`tab-button ${
              showTabs === "consulta" && "tab-button--active"
            }`}
            onClick={() => setShowTabs("consulta")}
          >
            Consultas
          </span>
        )}
      </div>

      <div className="singleContainer__fluxo-atendimento-tabs--Container">
        <div className="tabs-container-top">
          {showTabs === "avaliacao" &&
            // !avaliacoesEmAndamentoOuCriadas &&
            auth.perm.includes("c-aval") &&
            (auth.role === "Dentista" ||
              auth.role === "Médico/Administrador" ||
              auth.role === "Secretária(o)" ||
              auth.role === "Assistente") && (
              <span
                className="tabs-container-top-button"
                onClick={() => navigate(`../../../avaliacao/new/${clienteId}`)}
              >
                Criar Avaliação
              </span>
            )}
        </div>

        <div className="tabs-container-content">
          {showTabs === "avaliacao" && auth.perm.includes("r-aval") && (
            <AvaliacoesComponent
              clienteId={clienteId}
              sendRequest={sendRequest}
              token={auth.token}
            />
          )}
          {showTabs === "planotratamento" && auth.perm.includes("r-plt") && (
            <PlanosTratamentoComponent
              clienteId={clienteId}
              sendRequest={sendRequest}
              token={auth.token}
            />
          )}
          {showTabs === "orcamento" && auth.perm.includes("r-orc") && (
            <OrcamentosComponent
              dadosOrcamento={dadosOrcamento}
              clienteId={clienteId}
              sendRequest={sendRequest}
              token={auth.token}
            />
          )}
          {showTabs === "consulta" && auth.perm.includes("r-consult") && (
            <ConsultasComponent
              clienteId={clienteId}
              sendRequest={sendRequest}
              token={auth.token}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default FluxoAtendimento;
