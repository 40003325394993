import { useState, useEffect, useContext, useRef } from "react";
import moment from "moment";
//Tabs components
import TransferenciaTab from "./ModalReceberTabs/TransferenciaTab";
import DinheiroTab from "./ModalReceberTabs/DinheiroTab";
//MUI
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
//icons
import PaidIcon from "@mui/icons-material/Paid"; //cabecalho
import CreditCardIcon from "@mui/icons-material/CreditCard"; //Credit card
import LocalAtmIcon from "@mui/icons-material/LocalAtm"; //Nota
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth"; //Calendar
import AccountBalanceIcon from "@mui/icons-material/AccountBalance"; //Banco
import RequestPageIcon from "@mui/icons-material/RequestPage";

import { AuthContext } from "../../../../../../shared/context/auth-context";
import { useHttpClient } from "../../../../../../shared/hooks/http-hook";

import "./ModalReceber.scss";
import toast from "react-hot-toast";
import CreditoTab from "./ModalReceberTabs/CreditoTab";
import ChequeTab from "./ModalReceberTabs/ChequeTab";
import { ClinicaContext } from "../../../../../../shared/context/clinica-context";
import { useLoading } from "../../../../../../shared/context/LoadingContext";

import InputText from "../../../../../../shared/components/inputs/InputText/InputText";

function ModalReceber({
  open,
  handleCloseModalReceber,
  nomeCliente,
  valorAPagar,
  idParcela,
  contaCorr,
  updateContaCorrente,
}) {
  const { startLoading, stopLoading } = useLoading();
  const [openModal, setOpenModal] = useState(open);
  const [showTab, setShowTab] = useState("Dinheiro");
  const [idDaParcela, setIdDaParcela] = useState(idParcela);
  const [contaCorrente, setContaCorrente] = useState(contaCorr);
  const [conta, setConta] = useState();
  const [totalPorReceber, setTotalPorReceber] = useState();
  const { sendRequest } = useHttpClient();
  const [caixas, setCaixas] = useState();
  const [parcela, setParcela] = useState();
  const [descricao, setDescricao] = useState("Tratamento dentário");

  const auth = useContext(AuthContext);
  const clinica = useContext(ClinicaContext);

  const checkboxRef = useRef(null);
  useEffect(() => {
    setOpenModal(open);
    setContaCorrente(contaCorrente);
    setIdDaParcela(idParcela);
    let contaFiltrada;
    let parcelaFiltrada;

    contaCorr.contas.forEach((c) => {
      c.parcelas.forEach((p) => {
        if (p._id === idParcela) {
          parcelaFiltrada = p;
          contaFiltrada = c;
        }
      });
    });

    setParcela(parcelaFiltrada);
    setConta(contaFiltrada);

    setTotalPorReceber(
      contaFiltrada.parcelas.reduce(
        (total, item) =>
          !item.parcelaPaga ? total + item.quantiaPorPagar : total,
        0
      )
    );
  }, [open]);

  useEffect(() => {
    const fetchCaixas = async () => {
      startLoading();
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/caixas/clinica/${clinica.clinica._id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setCaixas(
          responseData.caixas.map((cai) => {
            return { id: cai.id, value: cai.caixa };
          })
        );
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    };
    fetchCaixas();
  }, [sendRequest, clinica.clinica._id, auth.token]);

  const receberHandler = async (dados, emitirDocumento) => {
    startLoading();
    let responseCheckPagamento = false;

    try {
      const formData = new FormData();
      formData.append("idParcela", parcela._id);
      formData.append("clinicaId", clinica.clinica._id);

      responseCheckPagamento = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/contasCorrentes/verificarrecebimento/${contaCorrente._id}`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );
    } catch (err) {
      console.error("err", err);
    }
    if (!responseCheckPagamento.parcelaPaga) {
      try {
        const contaFiltrada = { ...conta };
        const parcelaIndex = contaFiltrada.parcelas.findIndex(
          (parcela) => parcela._id === idDaParcela
        );

        if (parcelaIndex !== -1) {
          // Store original values before any modifications
          const parcela = contaFiltrada.parcelas[parcelaIndex];

          if (parcela.entrada) {
            handleEntradaParcelPayment(
              contaFiltrada,
              parcela,
              dados,
              parcelaIndex
            );
          } else {
            handleRegularParcelPayment(
              contaFiltrada,
              parcela,
              dados,
              parcelaIndex
            );
          }
        }

        contaFiltrada.parcelas.forEach((p) => {
          if (p._id === idDaParcela) {
            p.descricao = descricao ? descricao : "Tratamento dentário";
          }
        });

        let nrRecibo = 1;
        if (emitirDocumento === "emitirRecibo") {
          try {
            const nrDocumentos = await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/numeracaodocumentos/${clinica.clinica._id}`,
              "GET",
              null,
              {
                Authorization: "Bearer " + auth.token,
              }
            );

            nrRecibo = nrDocumentos.numeracaoDocumento.numeracoes.recibo + 1;
          } catch (err) {
            console.error("err", err);
          }
          contaFiltrada.parcelas.forEach((p) => {
            if (p._id === idDaParcela) {
              p.nrRecibo = nrRecibo;
            }
          });

          //Atualizar o numero de recibo
          const formData8 = new FormData();
          formData8.append("valor", nrRecibo);
          formData8.append("tipoDocumento", "recibo");
          try {
            await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/numeracaodocumentos/${clinica.clinica._id}`,
              "PATCH",
              formData8,
              {
                Authorization: "Bearer " + auth.token,
              }
            );
          } catch (err) {
            console.error("err", err);
          }
        }

        const nrParcelasPagas = contaFiltrada.parcelas.filter(
          (p) => p.quantiaPaga > 0
        ).length;

        if (nrParcelasPagas === contaFiltrada.parcelas.length) {
          contaFiltrada.contaLiquidada = true;
        }

        const contas = contaCorrente.contas.filter(
          (conta) => conta._id !== contaFiltrada._id
        );

        contas.push(contaFiltrada);

        const novaContaCorrente = contaCorrente;
        delete novaContaCorrente["contas"];
        novaContaCorrente.contas = contas;

        const parcelaFiltrada = contaFiltrada.parcelas.find(
          (p) => p._id === idDaParcela
        );

        const alteracoes = {
          "Número da parcela": {
            anterior: "",
            novo: parcelaFiltrada.nrParcela,
          },
          Valor: {
            anterior: "",
            novo: parcelaFiltrada.quantiaPaga,
          },
          "Número de orçamento": {
            anterior: contaFiltrada.orcamento.codigo,
            novo: contaFiltrada.orcamento.codigo,
          },
        };

        if (parcelaFiltrada.nrRecibo) {
          alteracoes["Recibo interno emitido"] = {
            anterior: "",
            novo: `Recibo nr ${parcelaFiltrada.nrRecibo}`,
          };
        }

        const formData3 = new FormData();
        formData3.append("contas", JSON.stringify(contas));
        formData3.append("operacao", "RECEBIMENTO_PARCELA");
        formData3.append("utilizador", auth.userId);
        formData3.append("alteracoes", JSON.stringify(alteracoes));
        formData3.append("nomeCliente", nomeCliente);

        let contaCorrenteParaState;
        try {
          contaCorrenteParaState = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/contasCorrentes/${contaCorrente._id}`,
            "PATCH",
            formData3,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
        }

        //Atualizar o plano de tratamento para que este não possa ser apagado
        //Caso essa for a primeria parcela a ser paga. Se não, isso quer dizer que
        //O campo canDelete ja esta a true e nao necessita ser atualizado
        if (nrParcelasPagas === 1) {
          let planoTratamento;
          try {
            planoTratamento = await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/planostratamento/orcamento/${contaFiltrada.orcamento._id}`,
              "GET",
              null,
              {
                Authorization: "Bearer " + auth.token,
              }
            );
          } catch (err) {
            console.error("err", err);
          }

          //Caso tivermos varios planos de tratamento com o mesmo orcamento, porque isso pode acontecer
          //Quando apagamos um plano de tratamento, filtramos apenas o ativo

          const planoTratamentoFiltrado =
            planoTratamento.planosTratamento.filter(
              (plano) => plano.ativo === true
            )[0];

          const formData = new FormData();
          formData.append("canDelete", false);
          try {
            await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/planostratamento/candelete/${planoTratamentoFiltrado._id}`,
              "PATCH",
              formData,
              {
                Authorization: "Bearer " + auth.token,
              }
            );
          } catch (err) {
            console.error("err", err);
          }
        }

        /////////////////////////////////////////////////////
        toast.success(
          `Pagamento efetuado com sucesso!${
            contaFiltrada.contaLiquidada ? " Conta Liquidada!" : ""
          }`
        );

        if (emitirDocumento === "emitirFRE") {
          updateContaCorrente(
            contaCorrenteParaState.contaCorrente,
            {
              IdcontaCorrente: contaCorrente._id,
              Idconta: contaFiltrada._id,
              IdParcela: idDaParcela,
            },
            "FRE"
          );
        } else {
          updateContaCorrente(contaCorrenteParaState.contaCorrente);
          //Atualizar a state para fechar a modal receber apos execucao de updateContaCorrente que tenta abrir a outra modal
          //Causa problemas pois handleCloseModalReceber no final fecha a modal q tentamos abrir em updateContaCorrente
          handleCloseModalReceber();
        }
      } catch (err) {
        console.error(err);
      }
    } else {
      stopLoading();
      toast.error(
        "A parcela que pretende receber já se encontra recebida. Por favor faça refresh da página."
      );
      handleCloseModalReceber();
    }
  };

  const handleEntradaParcelPayment = (
    contaFiltrada,
    parcela,
    dados,
    parcelaIndex
  ) => {
    const valorPago = dados.quantiaPaga;
    const valorOriginal = parcela.quantiaPorPagar;

    if (valorPago >= valorOriginal) {
      // Pagamento total ou em excesso
      let totalPago = valorOriginal;
      let parcelasParaFundir = [parcela];
      let i = parcelaIndex + 1;

      while (totalPago < valorPago && i < contaFiltrada.parcelas.length) {
        const proximaParcela = contaFiltrada.parcelas[i];
        if (
          !proximaParcela.parcelaPaga &&
          totalPago + proximaParcela.quantiaPorPagar <= valorPago
        ) {
          totalPago += proximaParcela.quantiaPorPagar;
          parcelasParaFundir.push(proximaParcela);
          i++;
        } else {
          break;
        }
      }

      const novaParcelaFundida = {
        _id: parcela._id, // Mantém o ID da parcela original
        id: parcela.id,
        dataLimitePagamento: parcela.dataLimitePagamento,
        parcelaPaga: true,
        quantiaPaga: valorPago,
        quantiaPorPagar: 0,
        entrada: parcela.entrada,
        metodoPagamento: dados.metodoPagamento,
        caixa: dados.caixa.id ? dados.caixa.id : dados.caixa,
        dataPagamento: dados.dataPagamento,
        observacao: dados.observacao,
        cancelamentosDocumentosEletronicos: [],
        recebidoPor: {
          data: new Date(),
          user: auth.userId,
        },
        oculto: checkboxRef.current.checked,
      };

      contaFiltrada.parcelas.splice(
        parcelaIndex,
        parcelasParaFundir.length,
        novaParcelaFundida
      );

      if (valorPago > totalPago) {
        const restante = valorPago - totalPago;
        while (i < contaFiltrada.parcelas.length && restante > 0) {
          const proximaParcela = contaFiltrada.parcelas[i];
          if (!proximaParcela.parcelaPaga) {
            if (restante >= proximaParcela.quantiaPorPagar) {
              novaParcelaFundida.quantiaPaga += proximaParcela.quantiaPorPagar;
              contaFiltrada.parcelas.splice(i, 1);
            } else {
              proximaParcela.quantiaPorPagar -= restante;
              break;
            }
          } else {
            i++;
          }
        }
      }
    } else {
      // Pagamento parcial
      const novaParcela = {
        quantiaPaga: 0,
        quantiaPorPagar: valorOriginal - valorPago,
        dataLimitePagamento: parcela.dataLimitePagamento,
        parcelaPaga: false,
        cancelamentosDocumentosEletronicos: [],
      };

      parcela.quantiaPaga = valorPago;
      parcela.quantiaPorPagar = 0;
      parcela.parcelaPaga = true;
      parcela.metodoPagamento = dados.metodoPagamento;
      parcela.caixa = dados.caixa.id ? dados.caixa.id : dados.caixa;
      parcela.dataPagamento = dados.dataPagamento;
      parcela.observacao = dados.observacao;
      parcela.recebidoPor = { data: new Date(), user: auth.userId };
      parcela.oculto = checkboxRef.current.checked;

      contaFiltrada.parcelas.splice(parcelaIndex + 1, 0, novaParcela);
    }

    renumberParcelas(contaFiltrada);
  };

  const handleRegularParcelPayment = (
    contaFiltrada,
    parcela,
    dados,
    parcelaIndex
  ) => {
    if (dados.quantiaPaga === parcela.quantiaPorPagar) {
      updateParcela(parcela, dados);
    } else if (dados.quantiaPaga < parcela.quantiaPorPagar) {
      const valorRestante = parcela.quantiaPorPagar - dados.quantiaPaga;

      // Atualizar a parcela original
      updateParcela(parcela, {
        ...dados,
        quantiaPaga: dados.quantiaPaga,
        quantiaPorPagar: 0,
        parcelaPaga: true,
      });

      // Criar nova parcela para o valor restante
      const novaParcela = {
        dataLimitePagamento: parcela.dataLimitePagamento,
        parcelaPaga: false,
        quantiaPaga: 0,
        quantiaPorPagar: valorRestante,
      };

      contaFiltrada.parcelas.splice(parcelaIndex + 1, 0, novaParcela);
      contaFiltrada.nrParcelas++;

      // Renumerar as parcelas
      renumberParcelas(contaFiltrada);
    } else if (dados.quantiaPaga > parcela.quantiaPorPagar) {
      const parcelasNaoPagas = contaFiltrada.parcelas.filter(
        (parcela) => !parcela.parcelaPaga
      );

      let combinacoesPossiveis = encontrarCombinacoes(
        parcelasNaoPagas,
        dados.quantiaPaga
      );

      if (combinacoesPossiveis.length === 0) {
        combinacoesPossiveis = encontrarMelhorCombinacao(
          parcelasNaoPagas,
          dados.quantiaPaga
        );
      }

      if (combinacoesPossiveis.length > 0) {
        const parcelasParaEliminar = combinacoesPossiveis[0];

        // Calcular o total a ser pago das parcelas selecionadas
        const totalParaPagar = parcelasParaEliminar.reduce(
          (total, parcela) => total + parcela.quantiaPorPagar,
          0
        );

        // Criar uma nova parcela que merge todas as parcelas pagas
        const novaParcela = {
          _id: parcela._id, // Adicione esta linha
          id: parcela.id,
          dataLimitePagamento: parcela.dataLimitePagamento,
          parcelaPaga: true,
          quantiaPaga: dados.quantiaPaga,
          quantiaPorPagar: 0,
          metodoPagamento: dados.metodoPagamento,
          caixa: dados.caixa.id ? dados.caixa.id : dados.caixa,
          dataPagamento: dados.dataPagamento,
          observacao: dados.observacao,
          recebidoPor: [
            {
              data: new Date(),
              user: auth.userId,
            },
          ],
          oculto: checkboxRef.current.checked,
        };

        // Remover as parcelas que estão sendo merged
        const indexParcelasParaEliminar = parcelasParaEliminar.map((parcela) =>
          contaFiltrada.parcelas.findIndex((p) => p._id === parcela._id)
        );
        indexParcelasParaEliminar.sort((a, b) => b - a);
        indexParcelasParaEliminar.forEach((index) => {
          contaFiltrada.parcelas.splice(index, 1);
        });

        // Inserir a nova parcela merged
        contaFiltrada.parcelas.splice(parcelaIndex, 0, novaParcela);

        // Calcular o valor restante após pagar as parcelas merged
        let restante = dados.quantiaPaga - totalParaPagar;

        // Se houver valor restante, ajustar a próxima parcela
        if (restante > 0 && parcelaIndex + 1 < contaFiltrada.parcelas.length) {
          const proximaParcela = contaFiltrada.parcelas[parcelaIndex + 1];
          if (restante < proximaParcela.quantiaPorPagar) {
            proximaParcela.quantiaPorPagar -= restante;
            // Não alteramos a quantiaPaga da próxima parcela
          } else {
            // Se o restante cobrir toda a próxima parcela, removemos ela também
            contaFiltrada.parcelas.splice(parcelaIndex + 1, 1);
            restante -= proximaParcela.quantiaPorPagar;
            novaParcela.quantiaPaga += proximaParcela.quantiaPorPagar;
          }
        }

        // Renumerar parcelas
        renumberParcelas(contaFiltrada);

        contaFiltrada.nrParcelas = contaFiltrada.parcelas.length;
      } else {
        updateParcela(parcela, dados);

        const restante = dados.quantiaPaga - parcela.quantiaPorPagar;

        if (restante > 0 && contaFiltrada.parcelas.length > 0) {
          const parcelaRestante = contaFiltrada.parcelas.find(
            (parcela) => parcela.quantiaPorPagar > 0
          );

          if (parcelaRestante) {
            updateParcela(parcelaRestante, {
              ...dados,
              quantiaPaga: restante,
            });
          }
        }
      }
    }
  };

  const updateParcela = (parcela, dados) => {
    parcela.quantiaPaga = dados.quantiaPaga;
    parcela.quantiaPorPagar = dados.quantiaPorPagar;
    parcela.metodoPagamento = dados.metodoPagamento;
    parcela.caixa = dados.caixa.id ? dados.caixa.id : dados.caixa;
    parcela.dataPagamento = dados.dataPagamento;
    parcela.parcelaPaga = parcela.quantiaPorPagar === 0;
    parcela.observacao = dados.observacao;
    parcela.recebidoPor = {
      data: new Date(),
      user: auth.userId,
    };
    parcela.oculto = checkboxRef.current.checked;
    parcela.recebimentoCanceladoPor = null;
  };

  function encontrarCombinacoes(parcelas, quantiaPaga) {
    const combinacoes = [];
    encontrarCombinacoesRecursivo(parcelas, quantiaPaga, [], 0, combinacoes);
    return combinacoes;
  }

  function encontrarCombinacoesRecursivo(
    parcelas,
    quantiaPaga,
    combinacaoAtual,
    index,
    combinacoes
  ) {
    const somaQuantiaPorPagar = combinacaoAtual.reduce(
      (total, parcela) => total + parcela.quantiaPorPagar,
      0
    );

    if (somaQuantiaPorPagar === quantiaPaga) {
      combinacoes.push(combinacaoAtual);
      return;
    }

    if (index === parcelas.length || somaQuantiaPorPagar > quantiaPaga) {
      return;
    }

    const parcela = parcelas[index];

    // Include the current parcela in the combination
    encontrarCombinacoesRecursivo(
      parcelas,
      quantiaPaga,
      [...combinacaoAtual, parcela],
      index + 1,
      combinacoes
    );

    // Exclude the current parcela from the combination
    encontrarCombinacoesRecursivo(
      parcelas,
      quantiaPaga,
      combinacaoAtual,
      index + 1,
      combinacoes
    );
  }

  function encontrarMelhorCombinacao(parcelas, valorAlvo) {
    const n = parcelas.length;
    const dp = Array(n + 1)
      .fill(null)
      .map(() => Array(valorAlvo + 1).fill(false));
    dp[0][0] = true;

    for (let i = 1; i <= n; i++) {
      for (let j = 0; j <= valorAlvo; j++) {
        if (dp[i - 1][j]) {
          dp[i][j] = true;
        } else if (j >= parcelas[i - 1].quantiaPorPagar) {
          dp[i][j] = dp[i - 1][j - parcelas[i - 1].quantiaPorPagar];
        }
      }
    }

    let melhorCombinacao = [];
    let melhorDiferenca = Infinity;
    let melhorSoma = 0;

    for (let j = valorAlvo; j >= 0; j--) {
      if (dp[n][j]) {
        const diferenca = Math.abs(valorAlvo - j);
        if (diferenca < melhorDiferenca) {
          melhorDiferenca = diferenca;
          melhorSoma = j;
        }
      }
    }

    let i = n;
    let j = melhorSoma;
    while (i > 0 && j > 0) {
      if (dp[i - 1][j]) {
        i--;
      } else {
        melhorCombinacao.push(parcelas[i - 1]);
        j -= parcelas[i - 1].quantiaPorPagar;
        i--;
      }
    }

    return [melhorCombinacao];
  }

  //Funcao para rearanjar a numeracao das parcelas ignorando a parcela de entrada caso ela exista
  const renumberParcelas = (contaFiltrada) => {
    let regularParcelaCount = 0;
    const regularParcelas = contaFiltrada.parcelas.filter((p) => !p.entrada);

    contaFiltrada.parcelas.forEach((p) => {
      if (!p.entrada) {
        regularParcelaCount++;
        p.nrParcela = `${regularParcelaCount}/${regularParcelas.length}`;
      } else {
        delete p.nrParcela; // Ensure entrada parcels don't have nrParcela
      }
    });

    contaFiltrada.nrParcelas = regularParcelas.length;
  };

  const descricaoChangeHandler = (value) => {
    setDescricao(value);
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleCloseModalReceber}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="fluxo-atendimento__modal__box aprovar-orcamento__modal"
          id="modal__cinquentaPorCento"
        >
          <h1 className="fluxo-atendimento__modal__titulo">
            Receber receita de {nomeCliente}
          </h1>
          <div className="modal-receber__cabecalho">
            <div className="modal-receber__cabecalho__data-divida">
              <div className="modal-receber__cabecalho__data">
                <CalendarMonthIcon className="modal-receber__cabecalho__icon" />{" "}
                {moment().format("DD-MM-YYYY")}
              </div>
              <div className="modal-receber__cabecalho__divida">
                <PaidIcon className="modal-receber__cabecalho__icon" />
                {valorAPagar}$00
              </div>
            </div>
            <span className="modal-receber__cabecalho__meio-pagamento">
              Meio de pagamento
            </span>
          </div>
          <div className="modal-receber__tabs">
            <div
              className={`modal-receber__tabs--tab ${
                showTab === "Dinheiro" && "modal-receber__tabs--tab--active"
              }`}
              onClick={() => setShowTab("Dinheiro")}
            >
              <LocalAtmIcon />
              Dinheiro
            </div>
            <div
              className={`modal-receber__tabs--tab ${
                showTab === "Credito" && "modal-receber__tabs--tab--active"
              }`}
              onClick={() => setShowTab("Credito")}
            >
              <CreditCardIcon />
              Crédito
            </div>
            <div
              className={`modal-receber__tabs--tab ${
                showTab === "Internet banking" &&
                "modal-receber__tabs--tab--active"
              }`}
              onClick={() => setShowTab("Internet banking")}
            >
              <AccountBalanceIcon />
              Transferência
            </div>
            <div
              className={`modal-receber__tabs--tab ${
                showTab === "Cheque" && "modal-receber__tabs--tab--active"
              }`}
              onClick={() => setShowTab("Cheque")}
            >
              <RequestPageIcon />
              Cheque
            </div>
          </div>

          <div className="checkboxOcultoContainer">
            <span>Ocultar</span> <input type="checkbox" ref={checkboxRef} />
          </div>

          <div style={{ marginTop: "20px" }}>
            <InputText
              initialValue={descricao}
              label="Descrição"
              handleChange={descricaoChangeHandler}
            />
          </div>

          {showTab === "Dinheiro" && caixas && parcela && (
            <DinheiroTab
              handleCloseModalReceber={handleCloseModalReceber}
              valor={valorAPagar}
              receberHandler={receberHandler}
              totalPorReceber={totalPorReceber}
              caixas={caixas}
              orcamento={conta.orcamento}
            />
          )}

          {showTab === "Internet banking" && caixas && parcela && (
            <TransferenciaTab
              handleCloseModalReceber={handleCloseModalReceber}
              valor={valorAPagar}
              receberHandler={receberHandler}
              totalPorReceber={totalPorReceber}
              caixas={caixas}
              parcela={parcela}
            />
          )}

          {showTab === "Credito" && caixas && parcela && (
            <CreditoTab
              valor={valorAPagar}
              handleCloseModalReceber={handleCloseModalReceber}
              receberHandler={receberHandler}
              totalPorReceber={totalPorReceber}
              caixas={caixas}
              parcela={parcela}
            />
          )}

          {showTab === "Cheque" && caixas && parcela && (
            <ChequeTab
              valor={valorAPagar}
              handleCloseModalReceber={handleCloseModalReceber}
              receberHandler={receberHandler}
              totalPorReceber={totalPorReceber}
              caixas={caixas}
              parcela={parcela}
            />
          )}
        </Box>
      </Modal>
    </>
  );
}

export default ModalReceber;
